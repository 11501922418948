/** @file Comportamientos de la vista historial de cargas de Registros DT **/

import onmount from 'onmount';

onmount('#index-datatable-history-massive-contracts', async function () {
  const { setFilters } = await import('../../helpers/filters');

  /*
  * Implementación de los filtros en historial de contratos
  */
  setFilters('#datatable-massive-historical-contracts', [
    '#history_filters_company_id',
    '#history_filters_division_id',
    '#history_filters_start_upload_date',
    '#history_filters_end_upload_date',
    '#history_filters_role_id',
    '#history_filters_status',
    '#history_filters_shipment_type',
  ], 'history_filters');
});
