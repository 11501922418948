/** @file
 * Este archivo contiene el código necesario para el manejo de switches de sesión.
 * Los switches de sesión son elementos de la interfaz que permiten al usuario activar o desactivar
 * ciertas funcionalidades de la aplicación. Estos switches son guardados en la sesión del usuario
 * y el cambio es persistente mientras la sesión esté activa.
 */

import onmount from 'onmount';

let chip = '';
let chipText = '';
const mediaQueryDesktop = window.matchMedia('(min-width: 960px)');

const SESSION_SWITCHES = [
  '#modo_dios_boton',
  '#ocultar_datos_sensibles',
];

/**
 * @description Obtiene el estado inicial de los switches de sesión
 * @returns {Object} - Estado inicial de los switches de sesión
 */
function initialStateSwitches() {
  const switches = {};
  SESSION_SWITCHES.forEach((switchId) => {
    switches[switchId] = $(switchId).is(':checked');
  });
  return switches;
}

/**
 * @description Activa el botón de guardar en función del estado de los switches
 * @returns {void}
 */
function activateSessionSaveButton() {
  const switchStates = initialStateSwitches();
  const saveButton = $('#footer_godmode_button');

  SESSION_SWITCHES.forEach(switchId => {
    const switchElement = $(switchId);

    switchElement.on('click', function () {
      // Recalculamos el estado de todos los switches, some devuelve true si al menos uno es true
      const isStateChanged = SESSION_SWITCHES.some(id => $(id).is(':checked') !== switchStates[id]);

      // Habilitamos o deshabilitamos el botón en función del cambio
      saveButton.prop('disabled', !isStateChanged);
    });
  });
}

/**
 * @description Inicializa el comportamiento de los switches mediante eventos
 */
function initializeSessionSwitches() {
  const guardarButton = $('#footer_godmode_button');
  activateSessionSaveButton();

  const switchDataSensitive = $('#ocultar_datos_sensibles');
  const isActiveDataSensitive = $('body').data('sensitive-information-masker');

  switchDataSensitive.prop('checked', Boolean(isActiveDataSensitive));

  // Verifica la existencia de switches antes de asignar eventos
  SESSION_SWITCHES.forEach((switchId) => {
    const switchElement = $(switchId);
    if (switchElement.length) {
      switchElement.on('click', function () {
        guardarButton.prop('disabled', false); // Habilitar botón en cualquier cambio
      });
    }
  });

  guardarButton.on('click', function () {
    // Obtener el estado actual solo cuando el botón se presiona
    sendSwitchesState(initialStateSwitches());
  });
}

/**
 * @description Envía el estado actual de los switches al back, ahi los guardamos en la sesión.
 * @param {Object} switchesState - Estado actual de los switches.
 */
function sendSwitchesState(switchesState) {
  const saveButton = $('#footer_godmode_button');
  const unprefixedSwitches = Object.keys(switchesState).reduce((acc, key) => {
    const newKey = key.replace(/^#/, '');
    acc[newKey] = switchesState[key];
    return acc;
  }, {});

  $.ajax({
    url: '/session_switches',
    method: 'POST',
    data: { switches: unprefixedSwitches },
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
  }).done();

  saveButton.prop('disabled', true);
}

/**
 * @description Inicializa los switches si existen en el DOM.
 */
onmount('#footer_godmode_button', function () {
  initializeSessionSwitches();
});

onmount('#session_chip > .chip', function () {
  chip = this;
  chipText = chip.innerText;

  mediaQueryDesktop.addEventListener('change', replaceChipText);
}, function () {
  mediaQueryDesktop.removeEventListener('change', replaceChipText);
});

function replaceChipText(event) {
  if (event.matches) {
    chip.innerText = chipText;

    return;
  }

  chip.innerText = chipText[0].toUpperCase();
}
