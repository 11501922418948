/** @file
 * Este archivo contiene el código JavaScript que se encarga de enviar la información de ordenamiento de los campos de un formulario al servidor.
 * además de normar la usabilidad de los campos ordenables.
 */
import onmount from 'onmount';

function isMobile() {
  return window.innerWidth <= 960;
}

/**
* @description Obtiene el orden de los fields en el formulario
* @param {Object} sortableForm - formulario que contiene los campos a ordenar
* @returns {String} order - orden de los campos separados por coma
*/
function currentOrderItems(sortableForm) {
  const items = sortableForm.querySelectorAll('.sortable-item');
  const order = Array.from(items)
    .map((item) => item.querySelector('input[type="checkbox"]').getAttribute('field_id'))
    .join(',');
  return order;
}

/**
* @description Genera el evento de amplitude para el ordenamiento de los campos
* @param {Object} sortableForm - formulario que contiene los campos a ordenar
* @returns {void}
*/
function amplitudeTrackingEvent(sortableForm, prevOrder, afterOrder) {
  const applyButton = sortableForm.closest('.dropdown-menu').querySelector('#column-visibility-dropdown__submit');
  const dropdown = document.querySelector('.column-visibility');
  const tableName = dropdown.closest('form').getAttribute('amplitude-table-source');

  $(applyButton).off('click.amplitudeTracking');

  $(applyButton).on('click.amplitudeTracking', function () {
    $(this).trigger('amplitudeTracking', {
      message: 'Reorder_Columns',
      data: {
        'table_source': tableName,
        'table_prev_order': prevOrder,
        'table_after_order': afterOrder,
      },
    });
  });
}

/**
* @description Actualiza el orden de los campos en el input hidden
* @param {Object} sortableForm - formulario que contiene los campos a ordenar
* @param {Object} fieldsOrderInput - input hidden que contendrá el orden de los campos
* @returns {void}
*/
function updateOrder({ sortableForm, fieldsOrderInput }) {
  const order = currentOrderItems(sortableForm);
  fieldsOrderInput.value = order;
  fieldsOrderInput.dispatchEvent(new Event('change'));
  return order;
}

/**
 * @description Inicializa la librería Sortable para ordenar los campos de un formulario
 * @param {Object} sortableForm - formulario que contiene los campos a ordenar
 * @param {Object} fieldsOrderInput - input hidden que contendrá el orden de los campos
 * @param {function} onMove - función que permite excluir elementos movibles como los campos de solo lectura
 * @param {function} onEnd - función que se ejecuta al realizar el movimiento de un campo
 * @returns {void}
 */
async function initializeSortable({ sortableForm, fieldsOrderInput }) {
  const handleSelector = isMobile() ? '.cursor-move' : '.sortable-item';
  const prevOrder = currentOrderItems(sortableForm);

  const { default: Sortable } = await import('sortablejs');
  new Sortable(sortableForm, {
    animation: 150,
    handle: handleSelector,
    filter: '.readonly',
    swapThreshold: 1,
    onMove: function (evt) {
      return !evt.related.classList.contains('readonly');
    },
    onEnd: function () {
      const afterOrder = updateOrder({ sortableForm: sortableForm, fieldsOrderInput });
      amplitudeTrackingEvent(sortableForm, prevOrder, afterOrder);
      enableApplyButtonOnSort(sortableForm);
    },
  });
  updateOrder({ sortableForm: sortableForm, fieldsOrderInput });
}

/**
 * @description Habilita el botón de aplicar cambios en Column Visibility Dropdown
 * @param {HTMLElement} sortableForm - formulario que contiene los campos a ordenar
 * @returns {void}
 */
function enableApplyButtonOnSort(sortableForm) {
  const applyButton = sortableForm.closest('.dropdown-menu').querySelector('#column-visibility-dropdown__submit');

  if (applyButton) {
    applyButton.disabled = false;
  }
}

/**
* @description Configura el ordenamiento de los campos de un formulario en caso de redimensionar la ventana
* @param {HTMLElement} sortableForm - formulario que contiene los campos a ordenar
* @param {HTMLElement} fieldsOrderInput - input hiddeen que contendrá el orden de los campos
* @returns {void}
*/
function setupColumnsOrdering({ sortableForm, fieldsOrderInput }) {
  const handleResize = () => {
    if (isMobile()) {
      initializeSortable({ sortableForm, fieldsOrderInput });
    }
  };

  window.addEventListener('resize', handleResize);

  window.addEventListener('beforeunload', () => {
    window.removeEventListener('resize', handleResize);
  });
}

/**
 * @description inicializa el ordenamiento de los campos de un formulario
 * @param {HTMLElement} sortableForm - formulario que contiene los campos a ordenar
 * @param {HTMLElement} fieldsOrderInput - input hiddeen que contendrá el orden de los campos
 * @returns {void}
 */
onmount('.sortable-form', function () {
  const self = this;
  const form = self.closest('form');
  const fieldsOrderInput = form.querySelector('input[name="fields_order"]');

  initializeSortable({ sortableForm: self, fieldsOrderInput: fieldsOrderInput });
  setupColumnsOrdering({ sortableForm: self, fieldsOrderInput });
});
