/** @file
 *
 * Archivo para agrupar todos los archivos de javascript de Tour Guiado, y así
 * evitar tener que importarlos uno por uno en el archivo application.js.
 *
 */

import Cookies from 'js-cookie';
import Shepherd from 'shepherd.js';
import * as FloatingUIDOM from '@floating-ui/dom';

class Abraham {
  tours = {};
  incompleteTours = [];

  startTour = (tourName) => {
    if (!Shepherd.activeTour) {
      this.tours[tourName].start();
    }
  };

  startNextIncompleteTour = () => {
    if (this.incompleteTours.length) {
      this.tours[this.incompleteTours[0]].checkAndStart();
    }
  };

  createTour = ({ tourName, tourOptions, steps }) => {
    const {
      trigger,
      enableAmplitude,
      authenticityToken,
      controllerName,
      actionName,
      abrahamCookiePrefix,
      abrahamDomain,
      customFunctionOnCancel,
      translation,
      tourCompleted,
      display,
    } = tourOptions;

    let screenWidth = window.innerWidth >= 960 ? 'desktop' : 'mobile';
    this.tours[tourName] = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shepherd-theme-custom',
        scrollTo: { behavior: 'smooth', block: 'center' },
        cancelIcon: {
          enabled: true,
        },
        floatingUIOptions: {
          middleware: [
            FloatingUIDOM.offset(30),
          ],
        },
        modalOverlayOpeningPadding: 16,
        modalOverlayOpeningRadius: 8,
        canClickTarget: false,
      },
    });
    const tour = this.tours[tourName];

    if (trigger !== 'manual') {
      tour.on('complete', function () {
        if(enableAmplitude) {
          document.dispatchEvent(new CustomEvent('tourComplete', { detail: { tour_name: tourName } }));
        }

        return fetch('/abraham_histories/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            authenticity_token: authenticityToken,
            controller_name: controllerName,
            action_name: actionName,
            tour_name: tourName,
            record_type: 'tour',
          }),
        });
      });

      tour.on('cancel', function () {
        tour.complete();
        if(customFunctionOnCancel) customFunctionOnCancel();
      });

      tour.on('start', function () {
        if(enableAmplitude) {
          document.dispatchEvent(new CustomEvent('tourStart', { detail: { tour_name: tourName } }));
        }
      });
    }

    const setLater = () => {
      Cookies.set(`${abrahamCookiePrefix}-${tourName}`, 'later', { domain: abrahamDomain,  expires: 15 });
      tour.hide();
    };

    const stepsSize = steps.length;

    const createButtons = (step, index) => {
      const { nextButtonAction, clickInOtherElementBeforeNext, backButtonAction, clickInOtherElementBeforeBack } = step;
      step.buttons = [];
      if(index === stepsSize - 1) {
        step.buttons.push({
          text: translation?.buttons?.exit,
          action() {
            if(nextButtonAction) nextButtonAction.call(this);
            this.complete();
          },
          classes: 'btn-secondary',
        });
      }
      else {
        step.buttons.push({
          text: translation?.buttons?.next,
          action() {
            if(nextButtonAction) {
              nextButtonAction.call(this);
            }
            else if (clickInOtherElementBeforeNext) {
              this.hide();
              setTimeout(() => {
                document.querySelector(clickInOtherElementBeforeNext)?.click();
                this.next();
              }, 250);
            }
            else {
              this.next();
            }
          },
          classes: 'btn-secondary',
        });
        step.buttons.push({
          text: translation?.buttons?.[index === 0 ? 'later' : 'back'],
          action: index === 0 ? setLater : function () {
            if(backButtonAction) {
              backButtonAction.call(this);
            }
            else if (clickInOtherElementBeforeBack) {
              this.hide();
              setTimeout(() => {
                document.querySelector(clickInOtherElementBeforeBack)?.click();
                this.back();
              }, 250);
            }
            else {
              this.back();
            }
          },
          classes: 'btn-primary ghost',
        });
      }
    };

    const createBeforeShowPromise = (step) => {
      if (step.beforeShowPromise) return false;

      const { beforeShowClick, beforeShowRemoveClass, hideByLocationSearchParams } = step;

      step.beforeShowPromise = function () {
        if(beforeShowClick) {
          document.querySelector(beforeShowClick)?.click();
        }
        if(beforeShowRemoveClass) {
          beforeShowRemoveClass.forEach(item => {
            Object.entries(item).forEach(([element, className]) => {
              document.querySelector(element)?.classList.remove(className);
            });
          });
        }
        if(hideByLocationSearchParams) {
          return new Promise(function (resolve, reject) {
            const locationSearchParams = new URLSearchParams(window.location.search);
            const isBlock = hideByLocationSearchParams.map(item => {
              let itemFound = false;
              Object.entries(item).forEach(([parameter, value]) => {
                itemFound = locationSearchParams.get(parameter) === value;
              });
              return itemFound;
            }).filter(Boolean).length === hideByLocationSearchParams.length;

            if(isBlock) {
              reject();
            }
            else {
              resolve();
            }
          });
        }
      };
    };

    steps.forEach((step, index) => {
      createButtons(step, index);
      createBeforeShowPromise(step);

      if(step.attachTo) {
        step.showOn = function () {
          return !!document.querySelector(step.attachTo.element);
        };
      }

      tour.addStep(step);
    });

    if (trigger !== 'manual') {
      tour.checkAndStart = function (start) {
        return function () {
          // Don't start the tour if the user dismissed it once this session
          let tourMayStart = !Cookies.get(`${abrahamCookiePrefix}-${tourName}`, { domain: abrahamDomain });

          if (steps?.[0]?.attachTo?.element) {
            // Don't start the tour if the first step's element is missing
            tourMayStart = tourMayStart && document.querySelector(steps[0].attachTo.element);
          }

          if (tourMayStart && !Shepherd.activeTour) {
            start();
          }
        };
      }(tour.start);

      if (!tourCompleted) {
        if (display) {
          if (screenWidth === display) {
            this.incompleteTours.push(tourName);
            window.addEventListener('resize', () => {
              const prevScreenWidth = screenWidth;
              screenWidth = window.innerWidth >= 960 ? 'desktop' : 'mobile';
              if(prevScreenWidth !== screenWidth) {
                location.reload();
              }
            });
          }
        }
        else {
          this.incompleteTours.push(tourName);
        }
      }
    }
  };
}

const abraham = new Abraham();
window.Abraham = abraham;

document.addEventListener('DOMContentLoaded', abraham.startNextIncompleteTour);
document.addEventListener('turbolinks:load', abraham.startNextIncompleteTour);

document.addEventListener('turbolinks:before-cache', function () {
  document.querySelectorAll('.shepherd-element').forEach(function (el) { el.remove(); });
  var activeTourScript = document.querySelector('#tour-guiado');
  if (activeTourScript) {
    activeTourScript.remove();
  }
  abraham.tours = {};
  abraham.incompleteTours = [];
});

window.Abraham = window.Abraham || abraham;
window.FloatingUIDOM = window.FloatingUIDOM || FloatingUIDOM;

document.dispatchEvent(new CustomEvent('AbrahamLoaded'));

export default abraham;
