/**
 * @file Maneja solicitudes de valoración de la app para integración con la aplicación móvil
 */
document.addEventListener('DOMContentLoaded', function () {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const appReview = params.get('app-review') === 'true';

  if (appReview) {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      // Espera a que el usuario confirme que la acción fue exitosa
      setTimeout(() => {
        window.ReactNativeWebView.postMessage('inAppReview');
      }, 3000);
    }
  }
});
