/** @file TODO: documentar */
import onmount from 'onmount';

const doMarkAmplitude = (element, message, data) => {
  element.trigger('amplitudeTracking',
    {
      message: message,
      data: data,
    });
};

const selectFilterAll = (targets, data, selector) => {
  targets.hide().each(function () {
    var child = $(this).find(data.targetText);
    if (child.text() === selector.find('option:selected').text()) {
      $(this).show();
    }
    else if (selector.val() === '') {
      $(this).show();
    }
  });
};

const createAmplitudeMarker = (data, selector) => {
  const event = data.amplitudeEvent;
  const filterName = selector.val();
  data.amplitudeData['filter_name'] = filterName ? filterName : 'All';
  doMarkAmplitude(selector, event, data.amplitudeData);
};

onmount('#marketplace-solutions-section', function () {
  $(this).find('.nav-item a').on('click', () => {
    $(this).find('.nav-item a .badge').toggleClass('text-info');
    $(this).find('.nav-item a .badge').toggleClass('text-disabled');
  });
});

onmount('#marketplace-integrations-section', function () {
  $(this).find('.nav-item a').on('click', () => {
    $(this).find('.nav-item a .badge').toggleClass('text-info');
    $(this).find('.nav-item a .badge').toggleClass('text-disabled');
  });

  $('.select_word_filter').on('change', function () {
    const data = $(this).data();
    const targets = $(data.targetContainer).find(data.targetNode);
    selectFilterAll(targets, data, $(this));
    if (data.amplitudeEvent) {
      createAmplitudeMarker(data, $(this));
    }
  });
});

$(document).on('click', '.bundle_starter_popover', function (event) {
  event.preventDefault();
  const targetDiv = document.querySelector(`div[data-id="${$(this).attr('data-id')}"]`);
  if (targetDiv) {
    targetDiv.scrollIntoView({ behavior: 'smooth' });
  }
});
