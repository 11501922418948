/** @file
* Archivo para generar actualizaciones en user_device
*/

// actualiza el token del dispositivo del usuario
/* eslint-disable no-unused-vars */
window.updateFirebaseToken = function (currentToken, newToken) {
  var csrfToken = $('meta[name="csrf-token"]').attr('content');

  $.ajax({
    url: '/user_device',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    data: JSON.stringify({
      current_token: currentToken,
      new_token: newToken,
    }),
  });
};
