/* eslint-disable brace-style */
/**
 * @file
 * Permite establecer o quitar el atributo `required` de un input dinámicamente basado en el valor de otro input.
 *
 * - El comportamiento está basado en los atributos `data-required-id` y `data-required-value`.
 *   - `data-required-id`: El ID del input objetivo que se modificará.
 *   - `data-required-value`: El valor que activa o desactiva el atributo `required`.
 *
 * Uso:
 * 1. Agrega `data-required-id` y `data-required-value` a cualquier campo de input o select. Estos atributos
 *    deben contener el ID del input objetivo y el valor esperado para activar el `required`.
 * 2. El campo asociado se volverá requerido o no basado en el valor definido en `data-required-value`.
 *
 * Ejemplo de HTML:
 * <input type='checkbox' id='with_settlement' data-required-id='wage' data-required-value='true' />
 * <input type='number' id='wage' />
 */
import onmount from 'onmount';

onmount('input[data-required-id], select[data-required-id]', function () {
  const field = this;
  const targetFieldId = field.getAttribute('data-required-id');
  const requiredValue = field.getAttribute('data-required-value');
  const requiredStep = field.getAttribute('data-required-step');
  let targetField = document.getElementById(targetFieldId);

  // Método que actualiza el atributo `required` del input objetivo y la clase `required` en el label
  const updateRequiredStatus = () => {
    if (!targetField) return;

    const targetLabel = document.querySelector(`label[for="${targetFieldId}"]`);
    let isRequired = false;

    // Para checkbox, comparamos con su estado (true o false)
    // Para otros tipos de input, comparamos con su valor
    if (field.type === 'checkbox') {
      isRequired = field.checked === (requiredValue === 'true');
    } else {
      isRequired = field.value === requiredValue;
    }

    // Establecemos o removemos el atributo `required` en el campo objetivo
    if (isRequired) {
      targetField.setAttribute('required', 'required');
      targetField.classList.add('required');
      if (targetLabel) {
        targetLabel.classList.add('required');
        if (!targetLabel.textContent.includes('*')) {
          targetLabel.textContent += ' *';
        }
      }
      if (requiredStep) {
        targetField.setAttribute('step', requiredStep);
      }
    } else {
      targetField.removeAttribute('required');
      targetField.classList.remove('required');
      if (targetLabel) {
        targetLabel.classList.remove('required');
        targetLabel.textContent = targetLabel.textContent.replace(/\s?\*$/, '');
        if (requiredStep && targetField.hasAttribute('step')) {
          targetField.removeAttribute('step');
        }
      }
      clearInput(targetField);
    }
  };

  // Llamada inicial para establecer el estado correcto al cargar la página
  updateRequiredStatus();

  // Escuchar eventos para cambios dinámicos en el campo origen
  field.addEventListener('change', updateRequiredStatus);
  field.addEventListener('input', updateRequiredStatus);

  // Observer para detectar si el `targetField` o el `targetLabel` son reemplazados por intercooler
  // y recalcula el estado si fue modificado
  const observer = new MutationObserver((mutations) => {
    mutations.forEach(() => {
      targetField = document.getElementById(targetFieldId);
      if (targetField) {
        updateRequiredStatus();
      }
    });
  });

  // Iniciar la observación en el contenedor del campo target
  const form = field.closest('form');
  if (form) {
    observer.observe(form, {
      childList: true,  // Detectar si se añaden o eliminan nodos hijos
      subtree: true,    // Incluir todos los descendientes (para cubrir el targetField)
    });
  }
});

/**
 * Limpia los mensajes de error o estados de validación de un input específico.
 *
 * Encuentra el grupo de formulario asociado al input y llama a `clearError` para eliminar los posibles errores
 * de validación visuales asociados a ese grupo de formulario.
 *
 * @param {HTMLInputElement} input - El campo a limpiar.
 */
function clearInput(input) {
  const formGroup = getFormGroup(input);
  clearError(formGroup);
}

/**
 * Busca el contenedor del grupo de formulario ('.form-group') más cercano al input dado.
 *
 * @param {HTMLInputElement} input - El campo a buscar.
 * @returns {HTMLElement|null} El contenedor del grupo de formulario más cercano.
 */
function getFormGroup(input) {
  return input.closest('.form-group');
}

/**
 * Elimina el estado de error de un grupo de formulario.
 * @param {HTMLElement} formGroup - El grupo de formulario del que se eliminará el error.
 */
function clearError(formGroup) {
  formGroup.classList.remove('has-error');
  const helpBlock = formGroup.querySelector('.help-block');
  if (helpBlock) {
    formGroup.removeChild(helpBlock);
  }
}
