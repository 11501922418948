/** @file Aplica el contador async a los links del sidebar */
import onmount from 'onmount';

/*
 * Aplica o valor del count tag cargado de forma asincrona al elemento de destino
 */

onmount('.async-chip', function () {
  const element = $(this);

  element.on('after.success.ic', () => {
    const count = element.data('chip-content');
    if (count > 0) {
      const text = count > 99 ? '99+' : count;
      element.find('.chip.badge').text(text).removeClass('d-none');
    }
  });
});
