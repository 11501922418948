/* eslint-disable buk/no-static-import */
/**
 * @file funciones para el componente loader con status progresivo
*/

import onmount from 'onmount';
import { getRandomId } from './helpers/id-helper';

const activeLoaderInterval = new Map();

onmount('.container-loader', mountLoaderMessages, unmountLoaderMessages);

function mountLoaderMessages() {
  const loader = this;
  const messages = loader.querySelectorAll('.loader-messages__item');

  if (messages.length < 2) return;

  const messageContainer = loader.querySelector('.loader-messages');

  if(!messageContainer.id) {
    messageContainer.id = getRandomId();
  }

  let counter = 0;

  const loaderInterval = setInterval(() => {
    // Verifica si el loader esta oculto para evitar
    // que el setInterval corra el segundo plano
    if (isLoaderHidden(loader)) {
      clearInterval(loaderInterval);
      activeLoaderInterval.delete(messageContainer.id);
      return;
    }

    const currentIndex = counter % messages.length;
    const currentMessage = messages[currentIndex];
    const previousMessage = messages[(currentIndex - 1 + messages.length) % messages.length];

    currentMessage.classList.add('loader-messages__item--visible');
    previousMessage.classList.remove('loader-messages__item--visible');

    counter++;
  }, 5000);

  activeLoaderInterval.set(messageContainer.id, loaderInterval);
}

function unmountLoaderMessages() {
  const loader = this;
  const messageContainer = loader.querySelector('.loader-messages');

  // Si no existe el elemento no se necesita limpiar el intervalo
  if(!messageContainer || !messageContainer?.id) return;

  const loaderInterval = activeLoaderInterval.get(messageContainer.id);

  if (!loaderInterval) return;

  clearInterval(loaderInterval);
  activeLoaderInterval.delete(messageContainer.id);
}

function isLoaderHidden(loader) {
  const isHiddenByClass = loader.classList.contains('d-none');
  const isHiddenByStyle = loader.style.display === 'none';

  return isHiddenByClass || isHiddenByStyle;
}
