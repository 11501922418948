/** @file TODO: documentar */
import onmount from 'onmount';
import { warning } from '../../../../../../../../app/assets/javascripts/lib/buk/alert.js';

onmount('form#utilidades-form', function () {

  // Icono del boton agregar no esta centrado
  const iconAdd = document.getElementsByClassName(' fas fa-plus left-icon')[0];
  iconAdd.setAttribute('class', 'fas fa-plus');

  let rowNumber = 1;
  let nextId = 1;
  const buttonAdd = $('#agregar-empresa');
  const objTo = document.getElementById('utilidades-input');
  const objFirst = document.getElementById('utilidades-input').firstElementChild;
  const objTemplate = objFirst.cloneNode(true);
  const numberEmpresas = objTo.classList.value;
  const removeButton = `<a class="btn btn-destructive remove_fields existing"` +
                      `data-wrapper-class="card-inputs" href="#">` +
                      `<i class=" fas fa-trash " aria-hidden="true"></i></a>`;

  // Función que agrega otro row para ingresar informacion de otra empresa
  // Verifica si el numero de rows es mayor que el numero de empresas que tiene el tenant,
  // de ser asi, se desactiva el boton de agregar.
  // Clona el primer row del formulario y se le agrega el boton de eliminar(removeButton).
  function addFormInput() {
    rowNumber += 1;
    nextId += 1;
    if (rowNumber === parseInt(numberEmpresas)) {
      buttonAdd.attr('disabled', true);
    }
    const cloneObject = objTemplate.cloneNode(true);
    const select = cloneObject.querySelector('select');
    $(select).attr('id', `select-input-${nextId}`);
    cloneObject.setAttribute('id', `utilidades-input-${nextId}`);
    objTo.appendChild(cloneObject);
    cloneObject
      .getElementsByClassName('d-flex justify-content-end')[0].insertAdjacentHTML('afterbegin', removeButton);
    $.onmount('select');
  }

  //Boton que agrega otro row para ingresar informacion de otra empresa
  buttonAdd.on('click', () => {
    addFormInput();
  });

  // Elimina el div que contiene al row correspondiente al boton de eliminar
  // Actualiza el numero de rows restando 1 al eliminar
  $(this).on('click', '.btn.btn-destructive.remove_fields.existing', (element) => {
    rowNumber -= 1;
    if (rowNumber < parseInt(numberEmpresas)) {
      buttonAdd.attr('disabled', false);
    }
    element.currentTarget.closest('.card-inputs').remove();
  });

  //Por si se vuelve la elegir la misma empresa en otro input, debe lanzar una alerta
  $(this).on('change', () => {
    const nameEmpresas = [];

    Array.from(document.getElementsByClassName('form-control empresas')).forEach(i => {
      if (i.value !== '') {
        if (nameEmpresas.includes(i.value)) {
          const nameEmpresa = i.options[i.options.selectedIndex].innerHTML;
          warning(`La empresa ${nameEmpresa} ya esta ingresada en otro campo`);
        }
        else {
          nameEmpresas.push(i.value);
        }
      }
    });
  });
});
