/**
 * @file Implementación personalizada del launcher de Zendesk que reemplaza el launcher predeterminado
 *
 * Este launcher personalizado proporciona las siguientes características:
 * - Arrastrar y soltar (drag & drop)
 * - Contador de mensajes no leídos
 * - Animaciones suaves con hover
 * - Integración con la API de Zendesk Messenger (objeto window.zE)
 *
 * La inicialización del launcher espera a que Zendesk esté completamente cargado
 * a través del evento personalizado 'zendesk:ready' que se dispara desde zendesk.js
 */

let isWidgetOpen = false;

function initZendeskLauncher() {
  // Volvemos abrir, si el widget estaba abierto antes
  if (isWidgetOpen && window.zE) {
    window.zE('messenger', 'open');
  }
  // Si el widget no está habilitado, no se inicializa el launcher
  if (document.body.dataset.enableZendesk !== 'true') return;
  const launcher = document.querySelector('[data-zendesk-launcher]');
  if (!launcher) return;
  const counter = launcher.querySelector('[data-zendesk-counter]');
  if (!counter) return;

  // Variables para el control del drag and drop
  let isDragging = false;
  let wasDragged = false;
  let initialX;
  let initialY;
  let xOffset = 0;
  let yOffset = 0;
  let startX = 0;
  let startY = 0;

  /**
   * Inicia el proceso de arrastre
   * - Deshabilita la transición para un movimiento más fluido
   * - Maneja tanto eventos táctiles como de mouse
   * - Establece la posición inicial para el cálculo del desplazamiento
   */
  function dragStart(e) {
    wasDragged = false;
    launcher.style.transition = 'none';

    startX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
    startY = e.type === 'touchstart' ? e.touches[0].clientY : e.clientY;
    initialX = startX - xOffset;
    initialY = startY - yOffset;

    if (e.target === launcher) {
      isDragging = true;
      launcher.style.cursor = 'grabbing';
    }
  }

  /**
   * Finaliza el proceso de arrastre
   * - Restaura la transición para animaciones suaves
   * - Previene el evento de clic si se realizó un arrastre
   */
  function dragEnd(e) {
    if (!isDragging) return;

    isDragging = false;
    launcher.style.cursor = 'grab';
    launcher.style.transition = 'all 0.2s ease-in-out';

    if (wasDragged) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  /**
   * Maneja el movimiento durante el arrastre
   * - Calcula la nueva posición basada en el movimiento del mouse/touch
   * - Actualiza la posición del launcher usando transform para mejor rendimiento
   */
  function drag(e) {
    if (!isDragging) return;

    e.preventDefault();

    const moveX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
    const moveY = e.type === 'touchmove' ? e.touches[0].clientY : e.clientY;

    xOffset = moveX - initialX;
    yOffset = moveY - initialY;

    const difference = 10;
    wasDragged =  (moveX > startX + difference || moveX < startX - difference) ||
                  (moveY > startY + difference || moveY < startY - difference);

    launcher.style.transform = `translate(${xOffset}px, ${yOffset}px)`;
  }

  launcher.addEventListener('touchstart', dragStart, { passive: false });
  launcher.addEventListener('touchend', dragEnd);
  launcher.addEventListener('touchmove', drag, { passive: false });
  launcher.addEventListener('mousedown', dragStart);
  document.addEventListener('mousemove', drag, { passive: false });
  document.addEventListener('mouseup', dragEnd);

  /**
   * Maneja los clics en el launcher
   * - Evita abrir/cerrar el widget si se realizó un arrastre
   * - Alterna el estado del widget usando la API de Zendesk
   */
  launcher.addEventListener('click', (e) => {
    if (wasDragged) {
      e.preventDefault();
      e.stopPropagation();
      wasDragged = false;
      return;
    }

    if (isWidgetOpen) {
      window.zE('messenger', 'close');
    }
    else {
      window.zE('messenger', 'open');
    }
  });

  /**
   * Actualiza el contador de mensajes no leídos
   * - Muestra el contador solo cuando hay mensajes no leídos
   * - Limita la visualización a "99+" para números grandes
   */
  function updateUnreadCounter(count) {
    if (count > 0) {
      counter.textContent = count > 99 ? '99+' : count;
      counter.classList.add('zendesk-launcher__counter--visible');
    }
    else {
      counter.textContent = '';
      counter.classList.remove('zendesk-launcher__counter--visible');
    }
  }

  /**
   * Suscripción a eventos de Zendesk
  */

  // Escucha cambios en mensajes no leídos
  window.zE('messenger:on', 'unreadMessages', updateUnreadCounter);

  // Maneja el estado del widget y el contador
  window.zE('messenger:on', 'open', () => {
    isWidgetOpen = true;
    // Oculta el contador cuando el widget está abierto
    counter.classList.remove('zendesk-launcher__counter--visible');
  });

  window.zE('messenger:on', 'close', () => {
    isWidgetOpen = false;
  });

  // Resetea la posición al cargar la página o navegar
  launcher.style.transform = 'translate3d(0px, 0px, 0)';
  xOffset = 0;
  yOffset = 0;
}

/**
 * Inicializa el launcher cuando Zendesk está listo
 * Este evento es disparado desde zendesk.js después de que el widget
 * se ha cargado y autenticado completamente
 */
document.addEventListener('zendesk:ready', initZendeskLauncher);
