/** @file TODO: documentar */
import onmount from 'onmount';
import Turbolinks from 'turbolinks';
import ModalBuilder from '@assets/javascripts/lib/modal-builder';
import { info, warning } from '@assets/javascripts/lib/buk/alert';
import load from '@assets/javascripts/lib/buk/load';

// Mostrar los campos según el tipo de pregunta
$(document).on('change', '.open_question_type_select', function () {
  const valueSelected = $(this).val();
  const surveyClimate = $(this).data('survey-clima');

  const simpleTypeValue = $('#type_hidden').val();

  if (valueSelected === 'checkbox' || valueSelected === 'radio' || valueSelected === 'numeric') {
    $('#categorie_text').show();
    $('#question_rapidfire_category_id').prop('required', true);
    $('#simple_row_type_lineal').hide();
    $('#simple_row_type_lineal :input').attr('disabled', true);
    $('#simple_row_type_nps').hide();
    $('#simple_row_type_nps :input').attr('disabled', true);
    $('#question_answer_options').prop('required', true);
    if (simpleTypeValue !== 'checkbox' && simpleTypeValue !== 'radio' && simpleTypeValue !== 'drag') {
      $('#question_answer_options').val('');
    }
    $('#simple_row_type_drag_or_checkbox').show();
    $('.question_answer_options_score').show();
    if (surveyClimate) {
      $('.add-answer-button').hide();
    }
    else {
      $('.add-answer-button').show();
    }
    $('.input_answer_option').attr('disabled', false);
    if (valueSelected === 'checkbox') {
      $('.example_check').show();
      $('.example_radio').hide();
    }
    else {
      $('.example_radio').show();
      $('.example_check').hide();
    }
  }
  if (valueSelected === 'numeric') {
    $('#simple_row_type_lineal').removeClass('hide');
    $('#simple_row_type_lineal').show();
    $('#simple_row_type_lineal :input').attr('disabled', false);
    $('#simple_row_type_nps').hide();
    $('#simple_row_type_nps :input').attr('disabled', true);
    $('#question_answer_options').prop('required', false);
    $('#simple_row_type_drag_or_checkbox').hide();
    $('.add-answer-button').hide();
    $('.input_answer_option').attr('disabled', true);
  }
  if (valueSelected === 'nps') {
    $('#categorie_text').val('');
    $('#categorie_text').hide();
    $('#question_rapidfire_category_id').prop('required', false);
    $('#simple_row_type_nps').removeClass('hide');
    $('#simple_row_type_nps').show();
    $('#simple_row_type_nps :input').attr('disabled', false);
    $('#simple_row_type_lineal').hide();
    $('#simple_row_type_lineal :input').attr('disabled', true);
    $('#question_answer_options').prop('required', false);
    $('#simple_row_type_drag_or_checkbox').hide();
    $('.add-answer-button').hide();
    $('.input_answer_option').attr('disabled', true);
  }
  if (valueSelected === 'drag') {
    $('#categorie_text').val('');
    $('#categorie_text').hide();
    $('#question_rapidfire_category_id').prop('required', false);
    $('#simple_row_type_lineal').hide();
    $('#simple_row_type_lineal :input').attr('disabled', true);
    $('#simple_row_type_nps').hide();
    $('#simple_row_type_nps :input').attr('disabled', true);
    $('#question_answer_options').prop('required', true);
    if (simpleTypeValue !== 'checkbox' && simpleTypeValue !== 'radio' && simpleTypeValue !== 'drag') {
      $('#question_answer_options').val('');
    }
    $('#simple_row_type_drag_or_checkbox').show();
    $('.question_answer_options_score').hide();
    if (surveyClimate) {
      $('.add-answer-button').hide();
    }
    else {
      $('.add-answer-button').show();
    }
    $('.input_answer_option').attr('disabled', false);
    $('.example_radio').hide();
    $('.example_check').hide();
  }
  if (valueSelected === 'short' || valueSelected === 'long') {
    $('#categorie_text').val('');
    $('#categorie_text').hide();
    $('#question_rapidfire_category_id').prop('required', false);
    $('#simple_row_type_lineal').hide();
    $('#simple_row_type_lineal :input').attr('disabled', true);
    $('#simple_row_type_nps').hide();
    $('#simple_row_type_nps :input').attr('disabled', true);
    $('#simple_row_type_drag_or_checkbox').hide();
    $('.add-answer-button').hide();
    $('.input_answer_option').attr('disabled', true);
    $('#question_answer_options').prop('required', false);
  }
});

$(document).on('click', '.add_answer_option', function () {
  const valueSelected = $('.open_question_type_select').val();
  switch (valueSelected) {
    case 'checkbox':
      $('.example_check').show();
      $('.example_radio').hide();
      break;
    case 'radio':
      $('.example_radio').show();
      $('.example_check').hide();
      break;
    case 'drag':
    default:
      $('.example_radio').hide();
      $('.example_check').hide();
      $('.question_answer_options_score').hide();
      break;
  }
});

// Actualizar la url de vista previa al cambiar el nombre de la encuesta
onmount('.talent_climate_process_name', function () {
  $(this).find('#talent_climate_process_name').on('change', function () {
    const name = $('#talent_climate_process_name');
    const preview = $('#preview-btn');
    const parsedURL = new URL(preview.attr('href'), window.location.origin);
    parsedURL.searchParams.set('temp_name', name.val());
    const url = parsedURL.pathname + parsedURL.search;
    $('#preview-btn').attr('href', url);
  });
});

onmount('.remove-selected', function () {
  $(this).addClass('disabled');
  $(this).parent().addClass('disabled');
  $(this).attr('data-url-disabled', $('.remove-selected').attr('data-url'));
  $(this).removeAttr('data-url');
  $(this).attr('data-confirm-disabled', $('.remove-selected').attr('data-confirm'));
  $(this).removeAttr('data-confirm');
});

onmount('#questions_table', function () {
  $(this).on('checkboxCount', (ev) => {
    $('.remove-selected').html('Borrar seleccionadas (' + ev.count + ')');
    if (ev.count > 0) {
      $('.remove-selected').removeClass('disabled');
      $('.remove-selected').parent().removeClass('disabled');
      $('.remove-selected').attr('data-url', $('.remove-selected').attr('data-url-disabled'));
      $('.remove-selected').removeAttr('data-url-disabled');
      $('.remove-selected').attr('data-confirm', $('.remove-selected').attr('data-confirm-disabled'));
      $('.remove-selected').removeAttr('data-confirm-disabled');
    }
    else {
      $('.remove-selected').addClass('disabled');
      $('.remove-selected').parent().addClass('disabled');
      $('.remove-selected').attr('data-url-disabled', $('.remove-selected').attr('data-url'));
      $('.remove-selected').removeAttr('data-url');
      $('.remove-selected').attr('data-confirm-disabled', $('.remove-selected').attr('data-confirm'));
      $('.remove-selected').removeAttr('data-confirm');
    }
  });
});

function getQuestionIds() {
  const ids = [];
  $('.seleted-questions').each(function () {
    if (this.checked) {
      ids.push($(this).data('id'));
    }
  });
  return ids;
}
function post(url, questionIds) {
  $.ajax({
    url: url,
    dataType: 'script',
    method: 'post',
    data: {
      mass_questions: {
        question_ids: questionIds,
      },
    },
  }).done(function (data) { data; });
}

onmount('.remove-selected', function () {
  $('.remove-selected').on('confirm:complete', function (e) {
    e.preventDefault();
    const [answer] = e.detail;
    if (answer) {
      const questionIds = getQuestionIds();
      post($('.remove-selected').data('url'), questionIds);
    }
  });
});

onmount('.confidentiality-level', function () {
  function get(url, areaIds, title) {
    $.ajax({
      url: url,
      dataType: 'html',
      method: 'get',
      data: {
        area_ids: areaIds,
      },
    }).done(function (data) {
      const modalBuilder = new ModalBuilder();
      modalBuilder.setTitle(title);
      modalBuilder.setBody(data).show();
    });
  }

  function getAreaIds() {
    const ids = [];
    let participants = 0;
    $('.areas:checked').each(function () {
      ids.push($(this).data('id'));
      participants += $(this).data('participants');
    });
    return participants >= $('.confidentiality-level').val() ? ids : [];
  }

  const groupAreasLink = $('#group_areas_link');
  groupAreasLink.on('click', function (e) {
    e.preventDefault();
    const areaIds = getAreaIds();
    if (areaIds.length >= 2) {
      get(groupAreasLink.data('url'), areaIds, groupAreasLink.data('title'));
    }
    else {
      const infoAlertMessage = $('.confidentiality-level').data('infoAlertMessage');
      info(infoAlertMessage.replace('{confidentialityLevel}', $('.confidentiality-level').val()));
    }
  });
});

onmount('#numeric-climate', function () {

  const selects = $('#numeric-climate select');
  const max = selects.last();

  max.on('change', function () {
    const totalDivs = $('#numeric-climate div.form-group').slice(2).length;
    if (Number(max.val()) > totalDivs) addInput(totalDivs);
    if (Number(max.val()) < totalDivs) removeInput(totalDivs);
  });

  function addInput(totalDivs) {
    const limit = Number(max.val()) - totalDivs;
    const divs = $('#numeric-climate div.form-group').slice(2);
    divs.last().find('input').removeAttr('required');
    for (let i = 0; i < limit; i ++) {
      const labelName = (i + 1 === limit) ? `Valor ${(totalDivs + 1 + i)} *` : `Valor ${(totalDivs + 1 + i)}`;
      var newInput = `<div class="form-group string required question_answer_options_name">
      <label class="control-label string required" for="question_answer_options_name">${labelName}</label>
      <div class="input-group">
      <input class="form-control string required" placeholder="Nombre Opcional" name="question[answer_options][name][]"
      aria-required="true" type="text" id="question_answer_options_name"></div></div>`;
      $('#numeric-climate').append(newInput);
    }
    removeValues();
  }

  function removeInput(totalDivs) {
    const limit = totalDivs - Number(max.val());
    for (let i = 0; i < limit; i ++) {
      $('#numeric-climate div.form-group').slice(2).last().remove();
    }
    removeValues();
  }

  function removeValues() {
    const divs = $('#numeric-climate div.form-group').slice(2);
    divs.each(function (index) {
      $(this).find('input').attr({ value: '', placeholder: 'Nombre Opcional' });
      $(this).find('label').text(`Valor ${index + 1}`);
      if (index === 0 || index === (divs.length - 1)) {
        $(this).find('input').attr({ required: 'required', placeholder: 'Nombre Obligatorio' });
        $(this).find('label').text(`Valor ${index + 1} *`);
      }
    });
  }

});

onmount('[data-climate-back]', function () {
  const btnBack = $(this);
  let changed = false;
  $('#sentiments_form').on('change', '.special-radio-button', function () {
    if (!changed) {
      changed = true;
      btnBack.removeAttr('data-confirm-swal-disable');
    }
  });
});

onmount('#climate-process-participants', async function () {
  const participantsBox = $(this);
  const table = participantsBox.find('#employee-climate-groups-table');
  const confidentialityLevel = participantsBox.attr('data-level');
  const warningMessage = participantsBox.attr('data-text');
  const moveParticipantsButton = participantsBox.find('#move-participants-group-button');
  const activateParticipantButton = participantsBox.find('#activate-participants-button');
  const desactivateParticipantButton = participantsBox.find('#deactivate-participants-button');
  const regenerateTokenParticipantButton = participantsBox.find('#regenerate-token-participants-button');

  table.on('click change', '.participant-group-checkbox', function () {
    const selectedParticipants = table.find('input[type=checkbox]:checked');
    moveParticipantsButton.toggleClass('disabled', selectedParticipants.length === 0);
    activateParticipantButton.toggleClass('disabled', selectedParticipants.length === 0);
    desactivateParticipantButton.toggleClass('disabled', selectedParticipants.length === 0);
    regenerateTokenParticipantButton.toggleClass('disabled', selectedParticipants.length === 0);
  });

  participantsBox.on('click', '.massive-action:not(.disabled)', function (event) {
    event.preventDefault();

    const action = $(this).attr('action');
    const ids = table.find('.participant-group-checkbox:checked:not([data-select-all])').map(function () {
      return $(this).attr('data-id');
    }).get();
    const climateProcessId = participantsBox.attr('data-climate-process-id');

    if (action === 'activate_selected' || action === 'deactivate_selected' || action === 'regenerate_tokens_selected') {
      const requestUrl = `/talent/climate_processes/${climateProcessId}/${action}`;

      $.ajax({
        url: requestUrl,
        type: 'PUT',
        data: { selected_ids: ids },
      });
    }
    else if (action === 'move_participants' || action === 'create_group') {
      let modalUrl;
      if (action === 'move_participants') {
        modalUrl = `/talent/climate_processes/${climateProcessId}/climate_groups/change_employee_group_modal`;
      }
      else if (action === 'create_group' && ids.length >= confidentialityLevel) {
        modalUrl = `/talent/climate_processes/${climateProcessId}/climate_groups/new`;
      }
      else {
        warning(warningMessage.replace('{confidentialityLevel}', confidentialityLevel));
        return false;
      }

      const modalBuilder = new ModalBuilder();
      modalBuilder.setFooter('').setSize('md').show().loadFromRemote(modalUrl, { employee_climate_ids: ids },
        null, 'get');
      modalBuilder.getModal().on('modal:reload', () => modalBuilder.loadFromRemote(modalUrl,
        { employee_climate_ids: ids }));
    }
    return false;
  });
});

onmount('#climate-config-form-top-btns', function () {
  const topBtns = $(this);
  const previewBtn = topBtns.find('#preview-btn');
  const href = previewBtn.attr('href').replace('allow_comments=true', '').replace('allow_comments=false', '');
  const allowComments = topBtns.find('#input-climate-allow-comments');
  allowComments.on('change', function () {
    if (allowComments.is(':checked')) {
      previewBtn.attr('href', `${href}allow_comments=true`);
    }
    else {
      previewBtn.attr('href', `${href}allow_comments=false`);
    }
  });
});

onmount('.async-filter-trigger', function () {
  const activeTitle = $(this).attr('ic-target').split('-tab-content')[0].replace('#', '');

  //Debido a que esta función está pensada solo para las pestañas de una encuesta de clima finalizada,
  //no es necesario el comportamiento para pestañas que no comparten el filtro con otras pestañas.
  if (activeTitle === 'show_tabs' || activeTitle === 'advanced_graph') return;

  const tabTitles = JSON.parse($(this).attr('data-text'));
  const loadingContent = `
    <div class="text-center">
      <div class="buk-loader">
      </div>
    </div>
  `;
  $(this).on('click', () => {
    tabTitles.forEach(title => {
      if(title === activeTitle) return;
      const asyncContainer = $(`#${title}-tab-content`);
      const baseUrl = asyncContainer.attr('data-render-async').split('?')[0];
      const formValues = $(`#new_talent_climate_filter_form_${activeTitle}`)
        .serializeArray().map(formElement => (
          `${formElement.name}=${formElement.name === 'tab' ? title : formElement.value}`
        )).join('&');
      asyncContainer.attr('data-render-async', `${baseUrl}?${formValues}`);
      if(asyncContainer.children().length !== 1) {
        asyncContainer.html(loadingContent);
        load(asyncContainer[0], asyncContainer.attr('data-render-async'));
      }
    });
  });
});

onmount('#container-compare-climates-summary', function () {
  const switchForm = $('#switch-compare-climates-summary');
  const selectFormContainer = $('#select-compare-climates-summary');
  const selectForm = $('#select-compare-climates-summary select');

  let checked = selectFormContainer.attr('style') !== 'display: none';
  switchForm.on('click', () => {
    if(checked) {
      selectFormContainer.hide();
    }
    else {
      selectFormContainer.show();
    }
    if(checked && selectForm.val() !== '') {
      selectForm.val('').change();
    }
    checked = !checked;
  });
});

onmount('#progress-graph-new', function () {
  const html = $(this).html();
  const target = $('#progress-graph-container');
  target.html(html);
  $(this).html('');
});

onmount('.limit-by-group', function () {
  const checkbox = $(this).find('input[type="checkbox"]');
  const destroyField = $(this).find('input[data-destroy-input="true"]');
  const selector = $(this).find('div.climate-group-select');

  checkbox.on('change', () => {
    if(checkbox.is(':checked')) {
      selector.find('select').removeAttr('disabled');
      selector.show('slow');
      destroyField.val(false);
    }
    else {
      selector.find('select').attr('disabled', true);
      selector.hide('slow');
      destroyField.val(true);
    }
  });
});

onmount('#container-categories-form', function () {
  const validInput = (value) => {
    let inputs = $(this).find(':input.climate-category-name-field');
    inputs = inputs.filter((_k, { value: val }) => val.trim().toLowerCase() === value.trim().toLowerCase());
    return inputs.length === 1;
  };

  const addOrRemoveInputError = (element, input, value) => {
    const isValid = validInput(value);
    const msg = $(this).attr('data-text');
    element.find('span[class="help-block"]').remove();
    input.attr('error', !isValid);
    if (isValid) {
      element.removeClass('has-error');
    }
    else {
      element.addClass('has-error');
      element.append(`<span class="help-block">${msg}</span>`);
    }
  };

  const addValidationEvent = field => {
    $(field).on('change', function (e) {
      const parent = field.parent('div');
      const { value } = e.target;
      addOrRemoveInputError(parent, field, value);
    });
  };

  const inputs = $(this).find('.nested-fields');
  inputs.each((_i, field) => {
    addValidationEvent($(field).find("input[name*='name']"));
    const destroyButton = $(field).find('a.remove_fields.existing');
    destroyButton.on('click', () => {
      $(field).find("input[name*='to_destroy']").val(true);
    });
    if ($(field).find("input[name*='to_destroy']").val()) {
      $(field).css('display', 'none');
      $(field).find("input[name*='_destroy']").val(true);
    }
  });

  // agrega o elimina un card de categoría dependiendo la acción ejecutada en el select2
  const categoriesSelector = $(this).find('#rapidfire_survey_clima_categories');
  const defaultInput = $('#default-category-input');
  categoriesSelector.on('select2:select', function (event) {
    event.preventDefault();
    const { element, text } = event.params.data;
    let { id } = event.params.data;
    id = id.split(' ').join('-');
    const clone = defaultInput.children().clone();
    const timestamps = Date.now();
    clone.attr('id', id);
    clone.find('input').each(function () {
      let field = $(this).attr('name').split(/\[([a-z_]+)\]/);
      field = field[field.length - 2];
      const inputName = `rapidfire_survey_clima[categories_attributes][${timestamps}][${field}]`;
      $(this).attr('id', `rapidfire_survey_clima_categories_attributes_${timestamps}_${field}`);
      $(this).attr('name', inputName);
      if (field === 'name') {
        $(this).val(text);
        addValidationEvent($(this));
      }
    });
    clone.find('a.btn.remove_fields').on('click', function () {
      const elementId = element.attributes['data-select2-id'].value;
      categoriesSelector.find(`option[data-select2-id=${elementId}]`).prop('selected', false).trigger('change');
    });
    $('#survey-categories-inputs').prepend(clone);
  });
  categoriesSelector.on('select2:unselect', function (event) {
    event.preventDefault();
    const { id } = event.params.data;
    $(`#${id.split(' ').join('-')}.nested-fields`).remove();
  });

  $(this).on('cocoon:after-insert', function (_e, insertedItem, _originalEvent) {
    addValidationEvent($(insertedItem).find("input[name*='name']"));
  });
});

// Muestra helper text que indica que la categoría seleccionada no cuenta en el cálculo del resultado
onmount('#new_question_form', function () {
  const errorMessage = $(this).attr('data-text');

  const helperBlock = (field) => {
    const selected = field.find('option:selected');
    const formGroup = field.parent().parent();
    if (selected.data('not_calculate')) {
      formGroup.append(`<span class="help-block">${errorMessage}</span>`);
    }
    else {
      formGroup.find('span[class="help-block"]').remove();
    }
  };

  const categoryField = $(this).find("select[name='question[rapidfire_category_id]']");
  helperBlock(categoryField); // Esto para el caso de cuando se requiera editar una pregunta
  categoryField.on('change', () => {
    helperBlock(categoryField);
  });
});

/**
 * Retorna los labels de las preguntas obligatorias
 */
export function requiredQ(form) {
  return form.find('div:not([class])').find('label.required');
}

onmount('#edit_attempt', function () {
  const form = $(this);
  const requiredQuestions = requiredQ(form);
  const requiredAnswers = form.find('[required="required"]');
  for (let i = 0; i < requiredAnswers.length; i++) {
    requiredAnswers[i].required = false;
  }
  $(document).on('change', '.autosave input, .autosave textarea, .autosave select', function () {
    const self = this;
    const questionId = self.name.match(/attempt\[(\d*)\].*/)?.[1];
    const field = form.find(`[for^="attempt_${questionId}_answer_text"]`)[0];
    if (field) {
      field.classList.remove('text-red');
    }
  });
  form.submit(function () {
    let noAnswered = 0;
    for (let i = 0; i < requiredQuestions.length; i++) {
      requiredQuestions[i].classList.remove('text-red');
    }
    for (let i = 0; i < requiredQuestions.length; i++) {
      const questionId = requiredQuestions[i].htmlFor.match(/attempt_(\d*)_.*/)?.[1];
      if ($(`.${questionId}`).is(':hidden')) { continue; } // Si la pregunta no está visible, no contabilizarla como requerida

      const answers = $(`[name^="attempt[${questionId}]"]`);
      if (answers[0].type === 'text' || answers[0].type === 'textarea') {
        if (answers[0].value.length === 0) {
          requiredQuestions[i].classList.add('text-red');
          noAnswered += 1;
        }
      }
      else if (answers[0].type === 'radio' || answers[0].type === 'checkbox') {
        let isChecked = false;
        for (let j = 0; j < answers.length; j++) {
          isChecked ||= answers[j].checked;
        }
        if (!isChecked) {
          requiredQuestions[i].classList.add('text-red');
          noAnswered += 1;
        }
      }
    }
    if (noAnswered > 0) {
      const incompleteFormAlert = $('#incomplete-form-alert');
      const emptyInputsCount = $('#empty-inputs-count');

      incompleteFormAlert.removeClass('hidden');
      emptyInputsCount.html(noAnswered);

      if (form.parents('.content-wrapper').length === 0) {
        $(document).scrollTop(0);
      }
      else {
        form.parents('.content-wrapper').animate({ scrollTop: 0 }, 500);
      }
      form.parents('.box-body').animate({ scrollTop: 0 }, 500);
      return false;
    }
  });
});

// Recarga la página cuando se suben participantes a una encuesta de clima masivamente
onmount('[data-import-action="massive_load_climate_participants"]', function () {
  $(this).on('fileuploaddone-success', function () {
    Turbolinks.visit($(location).attr('href'));
  });
});

// Recarga la página cuando se suben participantes masivamente a una encuesta de clima en configuración
onmount('[data-import-action="massive_load_climate_groups"]', function () {
  const interval = setInterval(checkForSuccess, 2000);
  function checkForSuccess() {
    if ($('.job-status__step--success').length) {
      clearInterval(interval);
      setTimeout(function () {
        const currentURL = $(location).attr('href');
        const newURL = currentURL.endsWith('/edit') ? currentURL : currentURL.concat('/edit');
        Turbolinks.visit(newURL);
      }, 1000);
    }
  }
});

// Recarga la página cuando se agregan participantes manualmente en una encuesta de clima en configuración
onmount('#active-add-employees .edit_talent_climate_process', function () {
  $(this).bind('ajax:complete', function () {
    const currentURL = $(location).attr('href');
    Turbolinks.visit(currentURL);
  });
});

onmount('.open_question_type_select', function () {
  const tooltip = $('.control-label.text.optional').children('span');
  let selected = $('.open_question_type_select').find(':selected').val();
  if (selected !== 'radio') { tooltip.hide();}
  $(this).on('change', function () {
    selected = $('.open_question_type_select').find(':selected').val();
    if (selected === 'radio') {
      tooltip.show();
    }
    else {
      tooltip.hide();
    }
  });
});
