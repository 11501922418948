/** @file
 * Archivo de marcas de amplitud para los custom report
 */
import onmount from 'onmount';

const subCategoriesMap = {
  'vacation': 'Vacaciones',
  'vacation_balance': 'Vacaciones',
  'seleccion': 'Selección',
  'postulacion': 'Selección',
  'workflow_search_process': 'Solicitudes',
};

// Método con el formato de la marca de Amplirude de reportes
function customReportDataTemplate() {
  return {
    'source': '',
    'export_new': '',
    'export_category': '',
    'export_subcategory': '',
    'export_data_category': '',
  };
}

// Método para limpiar nombres de iconos de chips
function cleanChipText(text) {
  return text.replace(/(?:text_fields|numbers|date_range|toggle_on|close)/g, '').trim();
}

// Método para obtener las categorías desde los chips
function getCategoriesFromContainer(selector) {
  const categories = new Set();
  $(selector).each(function () {
    const text = $(this).text().replace(/\s+/g, ' ').trim();
    const category = cleanChipText(text).split('-')[0].trim();
    categories.add(category);
  });
  return categories;
}

// Método para construir la marca de amplitud export_data_category
function exportDataCategoryForAmplitud() {
  const columnCategories = getCategoriesFromContainer('.drop-container li .chip');
  const groupByCategories = getCategoriesFromContainer('.group-by-drop-container li .chip');
  return Array.from(new Set([...columnCategories, ...groupByCategories]));
}

// Método para obtener los chips desde un contenedor
function getChipsFromContainer(selector) {
  const chipsList = [];
  $(selector).each(function () {
    var text = $(this).text().replace(/\s+/g, ' ').trim();
    chipsList.push(cleanChipText(text)); // Agrega el texto al array
  });
  return chipsList;
}

// Método para obtener el nombre de la vista desde donde se ejecuta una marca
function getViewName() {
  return window.location.pathname.includes('edit') ? 'Edit' : 'New';
}

// Marca amplitude para evento Descargar desde vistas new y edit
onmount('[data-custom-report-template-new-download="true"]', async function () {
  const customReport = $(this);
  customReport.on('click', () => {
    const customReportData = customReportDataTemplate();
    customReportData['source'] = 'Menu';
    customReportData['source_download'] = 'Direct';
    customReportData['export_new'] = getViewName();
    customReportData['export_category'] = subCategoriesMap[customReport.data('type')] ?
      subCategoriesMap[customReport.data('type')] : customReport.data('category');
    customReport['export_subcategory'] = subCategoriesMap[customReport.data('type')] ?
      customReport.data('category') : '';
    customReportData['export_data_category'] = exportDataCategoryForAmplitud();
    const chipsArray = getChipsFromContainer('.drop-container li .chip');
    customReportData['export_parameters'] = chipsArray;
    if ($('.group-by-drop-container').length) {
      const groupByChipsArray = getChipsFromContainer('.group-by-drop-container li .chip');
      customReportData['exports_groupby_parameters'] = groupByChipsArray;
    }
    const amplitudeData = { message: 'Download_Export', data: customReportData };
    customReport.trigger('amplitudeTracking', amplitudeData);
  });
});

// Marca amplitude para evento Guardar desde vistas new y edit
onmount('[data-custom-report-template-save="true"]', async function () {
  const customReport = $(this);
  customReport.on('click', () => {
    const customReportData = customReportDataTemplate();
    customReportData['source'] = 'Menu';
    customReportData['export_new'] = getViewName();
    customReportData['export_category'] = subCategoriesMap[customReport.data('type')] ?
      subCategoriesMap[customReport.data('type')] : customReport.data('category'),
    customReportData['export_subcategory'] = subCategoriesMap[customReport.data('type')] ?
      customReport.data('category') : '';
    const chipsArray = getChipsFromContainer('.group-by-drop-container li .chip');
    customReportData['export_parameters'] = chipsArray;
    const amplitudeData = { message: 'Save_Export', data: customReportData };
    customReport.trigger('amplitudeTracking', amplitudeData);
  });
});

// Marca amplitud para evento Preview desde vistas new y edit
onmount('#preview-button', async function () {
  const previewButton = $(this);
  previewButton.on('click', () => {
    const customReportData = customReportDataTemplate();
    customReportData['source'] = 'Menu';
    customReportData['export_new'] = getViewName();
    // Obtengo los atributos tipo de reportes desde botón de descarga
    const downloadButton = $('[data-custom-report-template-save="true"]');
    customReportData['export_category'] = subCategoriesMap[downloadButton.data('type')] ?
      subCategoriesMap[downloadButton.data('type')] : downloadButton.data('category'),
    customReportData['export_subcategory'] = subCategoriesMap[downloadButton.data('type')] ?
      downloadButton.data('category') : '';
    // Obtengo chips desde columnas
    const chipsArray = getChipsFromContainer('.drop-container li .chip');
    customReportData['export_parameters'] = chipsArray;
    // Obtengo chips desde agrupar por
    if ($('.group-by-drop-container').length) {
      const groupByChipsArray = getChipsFromContainer('.group-by-drop-container li .chip');
      customReportData['exports_groupby_parameters'] = groupByChipsArray;
    }
    // Obtengo categorías de chips
    customReportData['export_data_category'] = exportDataCategoryForAmplitud();
    const amplitudeData = { message: 'Preview_Export', data: customReportData };
    previewButton.trigger('amplitudeTracking', amplitudeData);
  });
});
