/**
 * @file funciones para la configuracion del font-size en tablas fiji
*/

import onmount from 'onmount';

onmount('.column-visibility', mountVisibilityDropdownMessages);


function mountVisibilityDropdownMessages() {
  const body = document.querySelector('body');
  const isBukFontSizeBiggerEnabled = body.dataset.ajusteTipografia === 'true';
  if (!isBukFontSizeBiggerEnabled) return;

  const dropdownTrigger = this;

  const dropdown = dropdownTrigger.querySelector('.column-visibility-dropdown');
  const sizeSwitch = dropdown.querySelector('.table-font-size__switch input[type="checkbox"]');
  const isSwitchEnabled = localStorage.getItem('fontSizeBiggerEnabled') === 'true';
  sizeSwitch.checked = isSwitchEnabled;

  if (isSwitchEnabled) {
    body.classList.add('fiji-font-size--bigger');
  }

  sizeSwitch.addEventListener('input', () => {
    if(sizeSwitch.checked) {
      body.classList.add('fiji-font-size--bigger');
      localStorage.setItem('fontSizeBiggerEnabled', true);
      return;
    }

    body.classList.remove('fiji-font-size--bigger');
    localStorage.removeItem('fontSizeBiggerEnabled');
  });
}
