/** @file Comportamientos del formulario de historial de cambios */
import onmount, { $ } from 'onmount';
import { once } from 'lodash-es';
import { warning } from '../../../../../../../app/assets/javascripts/lib/buk/notice.js';

const loadDatePicker = once(async function loadDatePicker() {
  await import('../../../../../../../app/assets/javascripts/vendor/datepicker.js');
});

const showConfirmMessage = async () => {
  const { default: Swal } = await import('sweetalert2/dist/sweetalert2.js');
  const translationData = $('#translations_sweet_alert').data().sweetAlert;
  const title = translationData.title;
  const text = translationData.text;
  const confirmButtonText = translationData.confirm_button_text;
  const cancelButtonText = translationData.cancel_button_text;
  const icon = 'warning';
  const result = await Swal.fire({
    title,
    html: text,
    icon,
    confirmButtonText,
    cancelButtonText,
    showCloseButton: true,
    showCancelButton: true,
    closeButtonHtml: '<span class="material-icons">close</span>',
    reverseButtons: true,
    keydownListenerCapture: true,
  });
  if (result.isConfirmed) {
    $('#request_historic_versions_modal').modal('show');
  }
};

//
// Define la lógica de las versiones que no están en la BD si no que están en s3
// 1. Si se selecciona un rango de fechas donde no se encuentren versiones en la BD
//    se le preguntará al cliente si quiere traerlas de s3
// 2. Tambien setea validaciones, como que el end_date no pueda ser menor que el input de start_date
//
onmount('[data-historical-versions-on-s3]', async function () {
  if ($(this).data().historicalVersionsOnS3 === false) {
    return;
  }
  await loadDatePicker();
  const { default: moment } = await import('moment');
  const isDateInRange = function (date, ranges) {
    return ranges.some(range => {
      const start = moment(range[0], 'YYYY-MM-DD');
      const end = moment(range[1], 'YYYY-MM-DD');
      return date.isSameOrAfter(start) && date.isSameOrBefore(end);
    });
  };
  const form = $(this);
  const storedVersionRanges = form.data('alreadyStoredVersionsRanges');
  const startPicker = form.find('#change_history_start_date');
  const endPicker = form.find('#change_history_end_date');
  const startPickerRequest = $('#request_historic_versions_start_date');
  const endPickerRequest = $('#request_historic_versions_end_date');
  startPicker.on('change', function () {
    const selectedStartDate = moment(startPicker.datepicker('getDate'));
    // Hacemos que el endpicker solo pueda seleccionar hasta la fecha de startDate
    endPicker.datepicker('setStartDate', startPicker.datepicker('getDate'));
    // Copiamos el valor para el request de versiones
    startPickerRequest.datepicker('setDate', startPicker.datepicker('getDate'));
    const insideRestoredData = isDateInRange(selectedStartDate, storedVersionRanges);
    if (!insideRestoredData) {
      showConfirmMessage();
    }
  });
  endPicker.on('change', function () {
    const selectedEndDate = moment(endPicker.datepicker('getDate'));
    endPickerRequest.datepicker('setDate', endPicker.datepicker('getDate'));
    const insideRestoredData = isDateInRange(selectedEndDate, storedVersionRanges);
    if (!insideRestoredData) {
      showConfirmMessage();
    }
  });
});

//
// Modal donde se selecciona las fechas para restaurar versiones a la base de datos
//   1. Esta solicitud tiene un máximo de rango de 3 meses, considerando cada mes como 31 días.
//
onmount('.request_historic_versions', async function () {
  const { default: moment } = await import('moment');
  const startPickerRequest = $('#request_historic_versions_start_date');
  const endPickerRequest = $('#request_historic_versions_end_date');
  const form = $(this);
  const alertText = $(this).data().alertExceededTime;
  form.submit(function (event) {
    event.preventDefault();
    const startDate = moment(startPickerRequest.datepicker('getDate'));
    const endDate = moment(endPickerRequest.datepicker('getDate'));
    const diffInMonths = Math.ceil(endDate.diff(startDate, 'days') / 31);
    if(diffInMonths <= 3) {
      this.submit();
    }
    else {
      warning(alertText, '', '#request_historic_versions_modal .modal-body');
    }
  });
});
