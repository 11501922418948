/**
 * @file Muestra un "Ver más" "Ver menos" en un contenido con overflow.
 *  El link de "Ver mas" y "Ver menos" no aparecen si el contenido no tiene overflow.
 *  Oculta el excedente de contenido por defecto.
 *  Ejemplo (transpolar a estándares de erb):
 *  <div data-show-hide-more-content>
 *    <div>
 *      Contenido con overflow hidden.
 *    </div>
 *    <a class="toogle-more-link"></a>
 *  </div>
 *
 */
import onmount from 'onmount';

let hideCollapsableContentPromises = [];

function checkContentVisibility(contentElement, txt) {
  const forceActivation = contentElement.data('force-collapse');

  // El maxHeigthCollapsed indica la altura máxima que debe tener el contenido cuando
  // está en estado colapsado
  let maxHeightCollapsed;
  const hasMaxHeightCollapsed = typeof contentElement.data('max-height-collapsed') === 'number';
  if (hasMaxHeightCollapsed) {
    maxHeightCollapsed = `${contentElement.data('max-height-collapsed')}px`;
  }

  if (hasMaxHeightCollapsed) {
    txt.css('maxHeight', maxHeightCollapsed);
  }

  const fullHeigth = txt.prop('scrollHeight');
  const currentHeigth = txt.height();

  // El fullHeigth dicta un tamaño superior mayor por redondeo.
  // Apartamos 2px para evitar cargar este componente para abrir 2px de contenido.
  const pxErrorRange = 2;

  // Verificamos si el contenido es mayor el ancho por defecto.
  if (fullHeigth > currentHeigth + pxErrorRange || forceActivation) {
    contentElement.addClass('content-collapse');

    contentElement.find('.toogle-more-link').on('click', function () {
      const toogleLink = $(this);
      const toogleParent = toogleLink.closest('[data-show-hide-more-content="true"]');
      const showMoreLabel = toogleParent.data('show-more-label');
      const showLessLabel = toogleParent.data('show-less-label');
      const toogleWrapper = toogleParent.find('.more-content-wrapper');
      const toogleMaxHeightCollapsed = `${toogleParent.data('max-height-collapsed')}px`;
      const toggleButtonLabel = toogleLink.find('.btn-label');
      const toogleExpanded = toogleParent.data('expanded');

      // Si expand == false, no se expandirá el contenido al presionar 'ver más'
      let expand = true;
      const hasExpand = typeof toogleParent.data('expand') === 'boolean';
      if (hasExpand) {
        expand = toogleParent.data('expand');
      }

      if (toogleExpanded === true) {
        toogleWrapper.css('minHeight', 'auto');
        toogleParent.removeClass('content-expanded');
        toogleParent.addClass('content-collapse');

        if (hasMaxHeightCollapsed) {
          toogleWrapper.css('maxHeight', toogleMaxHeightCollapsed);
        }

        if (toogleLink.text() === showLessLabel || toggleButtonLabel.text() === showLessLabel) {
          toogleLink.text(showMoreLabel);
        }

        toogleParent.data('expanded', false);
      }
      else {
        if (expand) {
          toogleParent.addClass('content-expanded');
          toogleParent.removeClass('content-collapse');

          if (hasMaxHeightCollapsed) {
            toogleWrapper.css('maxHeight', 'none');
          }

          if (toogleLink.text() === showMoreLabel || toggleButtonLabel.text() === showMoreLabel) {
            toogleLink.text(showLessLabel);
          }

          toogleParent.data('expanded', true);
        }
      }
    });
  }
}

function hideCollapsableContent(contentElement) {
  const txt = contentElement.find('.more-content-wrapper');
  return new Promise((resolve) => {
    txt.ready(function () { checkContentVisibility(contentElement, txt); resolve(); });

    // Las imágenes por ckeditor no tienen alto en el dom,
    // su tamaño se establece cuando la imagen es mostrada en el HTML
    txt.find('img').on('load', function () { checkContentVisibility(contentElement, txt); resolve(); });
  });
}

onmount('[data-show-hide-more-content="true"]', function () {
  if (!$(this).hasClass('content-collapse')) {
    hideCollapsableContentPromises.push(hideCollapsableContent($(this)));
  }
});

$(document).on('hideContentEvent', function () {
  $('[data-show-hide-more-content="true"]').each(function () {
    if (!$(this).hasClass('content-collapse')) {
      hideCollapsableContent($(this));
    }
  });
});

/**
 * Función para establecer el margen derecho del link de ver más en la tabla fiji
 * @param {Object} contentElement - Elemento que contiene el texto
 * @param {Object} showMoreLabel - Texto del link de ver más
 * @return {void}
 */

function setSpacingForMoreContent(contentElement, showMoreLabel) {
  const LABEL_OFFSET = 8;
  // contar caracteres para aplicar margen relativo
  const labelLength = showMoreLabel.length;
  const labelWidth = labelLength * LABEL_OFFSET;
  contentElement.css('margin-right', `${labelWidth}px`);
}

/**
  * Este script es para el componente Ver más de tabla fiji
  * Mueve relativo el link de ver más para mejorar la experiencia de usuario
  * @params {Object} $fijiTable - Tabla fiji
  * @params {Object} toggleMoreLink - Link de ver más
  * @params {Object} labelTranslations - Traducciones de los labels
  * @params {Object} contentWrapper - Contenedor del contenido
  * @params {Boolean} isCollapsed - Estado del contenido
  * @params {Object} showMoreContent - Contenido que se muestra/oculta al hacer click en ver más
  * return {void}
  **/
onmount('[data-fiji-table="true"]', async function () {
  await Promise.all(hideCollapsableContentPromises);
  hideCollapsableContentPromises = [];
  const $fijiTable = $(this);
  const toggleMoreLink = $fijiTable.find('.toogle-more-link');
  const toggleMoreItems = $fijiTable.find('.toogle-more.content-collapse');

  // Aplicar espaciado solo sobre los elementos que tienen la clase 'content-collapse'
  toggleMoreItems.each(function () {
    const $this = $(this);
    const showMoreLabel = $this.data('show-more-label');
    const contentElement = $this.find('.toogle-more-container');

    setSpacingForMoreContent(contentElement, showMoreLabel);
  });

  toggleMoreLink.on('click', function () {
    const $showMoreClicked = $(this);
    const contentWrapper = $showMoreClicked.closest('[data-show-hide-more-content="true"]');
    const showMoreContent = contentWrapper.find('.toogle-more-container');
    $showMoreClicked.toggleClass('expand-relative');

    showMoreContent.append($showMoreClicked);
  });
});

/**
  * En Fiji renderizamos el primer tab siempre, sin importar si al cargar la página
  * se quiere mostrar un tab distinto. Esto hace que si el usuario va al primer tab después de
  * cargar el tab correspondiente este no muestre el contenido colapsado como debería.
  * Para esto llamamos a la función hideCollapsableContent para ocultar el contenido que lo necesite.
  **/
onmount('div[data-fiji-tabs="true"]', function () {
  const fijiTabsContainer = $(this);
  const firstFijiTab = $(fijiTabsContainer.find("a[data-toggle='tab']")[0]);
  firstFijiTab.on('click', function () {
    const tabContent = fijiTabsContainer.find('.tab-content');
    const firstTabContent = $(tabContent.find('.tab-pane')[0]);
    firstTabContent.find('div[data-show-hide-more-content="true"]').each(function () {
      if (!$(this).hasClass('content-collapse') && !$(this).hasClass('content-expanded')) {
        hideCollapsableContent($(this));
      }
    });
  });
});
