/* eslint-disable buk/no-static-import */
/** @file configuraciones para el módulo de talento:
 * - Configuración: comportamientos para la configuración de evaluaciones
*/
import onmount from 'onmount';
import {
  warning
} from '../../../../../../../app/assets/javascripts/lib/buk/alert.js';
import { SIMPLE_EDITOR_OPTIONS } from '@assets/javascripts/pages/document_templates.js';
import queryString from 'query-string';
import { removeAlert, createAlertError, addInputSelect2Error, removeInputSelect2Error } from './utils.js';

/**
 * Controla la habilitación de los selectores de escalas de potencial en evaluaciones
*/
const disableSecondaryBossPotentialScales = () => {
  const formWithOutRemove = $('.nested-fields').not('.removed').has('.select-steps-active option:selected');
  const stepBossId = $('[data-kind="boss"]').data('id');
  const bossFormsWithoutRemove = formWithOutRemove.filter(function () {
    return ($(this).
      find('.select-steps-active option:selected').
      toArray().
      filter(opt => opt.value === `${stepBossId}`)
      .length > 0);
  });
  const otherFormsWithoutRemove = formWithOutRemove.filter(function () {
    return ($(this).
      find('.select-steps-active option:selected').
      toArray().
      filter(opt => opt.value === `${stepBossId}`).
      length === 0);
  });
  const mainBossForm = bossFormsWithoutRemove.first();
  const mainBossTab = $(`#${mainBossForm.data('id')}`);
  const mainBossSelect = mainBossTab.find('.potential_scale_select');
  mainBossSelect.prop('disabled', !mainBossTab.find('.potential-scale-section').is(':checked')).addClass('activable');
  bossFormsWithoutRemove.slice(1).each(function () {
    const formTabId = $(this).data('id');
    const tab = $(`#${formTabId}`);
    tab.find('.potential_scale_select').
      prop('disabled', true).
      removeClass('activable').
      val(mainBossSelect.val()).
      change();
  });
  otherFormsWithoutRemove.each(function () {
    const formTabId = $(this).data('id');
    const tab = $(`#${formTabId}`);
    tab.find('potential_scale_select').
      prop('disabled', !tab.find('.potential-scale-section').is(':checked')).
      addClass('activable');
  });
};

onmount('[data-evaluation-process-form]', async function (obj) {
  const { loadCKeditor, CKeditorVersion, loadCKeditor5 } = await import('@assets/javascripts/components/ckeditor/load');
  if (CKeditorVersion() === '4') {
    await loadCKeditor(this, obj);
  }
  const form = $(this);
  const steps = form.find('[data-step]');
  const sequentialChecbox = $('#evaluation_process_sequential');
  const btnSummary = $('[data-id=summary]');
  const listItemForm = $('[data-item-form=true]');
  const divAlertModalForm = $('[data-modal-errors=true]');
  const btnPublishOptions = $('[data-config-publish-options]');

  const countActiveEvaluation = () => $('.activate-draggable:checked').length;

  // guarda la opción seleccionada al abrir el modal de opciones de publicación
  btnPublishOptions.on('click', function () {
    const optionSelected = $('input[data-boss-publish]:checked').val();
    $(this).data('option-selected', optionSelected);
  });

  // al presionar el botón cancelar en el modal de opciones de publicación selecciona la opción que tenía antes de abrir el modal
  $('[data-btn-cancel-publish-options]').on('click', function () {
    const lastOption = btnPublishOptions.data('option-selected');
    $(`input[data-boss-publish][value=${lastOption}]`).prop('checked', lastOption);
  });

  $('input[type="number"][name$="[weight]"]').change(function () {
    const inputNumber = $(this);
    const value = parseInt(inputNumber.val());
    const maxValue = parseInt(inputNumber.attr('max'));
    const minValue = parseInt(inputNumber.attr('min'));
    inputNumber.val(Math.min(
      Math.max(value, isNaN(minValue) ? -Infinity : minValue),
      isNaN(maxValue) ? Infinity : maxValue
    ));
  });

  $('.activator').each(function () {
    const activator = $(this);
    const inputWeight = $(`#weight_${activator.attr('id')}`);
    const scale = $(`select[data-checkbox="${activator.attr('id')}"]`);
    const inputId = $(`#id_${activator.attr('id')}`);
    const goalsIntegrationActivator =
      $(`.activator-goals-tracking-integration[data-section="${activator.attr('id')}"]`);
    if (activator.prop('checked')) {
      inputWeight.attr('disabled', false);
      inputId.attr('disabled', false);
      scale.attr('disabled', false);
      goalsIntegrationActivator.attr('disabled', false);
      if (goalsIntegrationActivator.prop('checked')) {
        const scaleSelector =
          $(`.section_scale_select[data-section-id=${goalsIntegrationActivator.data('section-id')}]`);
        scaleSelector.prop('disabled', true);
        $(`#child_section_${goalsIntegrationActivator.data('section')} :checkbox`).attr('disabled', true);
        $(`#child_section_${goalsIntegrationActivator.data('section')} input:checkbox`).prop('checked', false);
        $(`#child_section_${goalsIntegrationActivator.data('section')} :input[type="number"]`).attr('disabled', true);
        $(`#child_section_${goalsIntegrationActivator.data('section')} :input[type="number"]`).val('');
      }
    }
    else {
      inputWeight.attr('disabled', true);
      inputId.attr('disabled', true);
      scale.attr('disabled', true);
      goalsIntegrationActivator.attr('disabled', true);
    }
  });

  $(document).on('change', '.activator', function () {
    const activator = $(this);
    const state = $(`#weight_${activator.attr('id')}`).attr('disabled') || activator.prop('checked');
    const inputWeight = $(`#weight_${activator.attr('id')}`);
    inputWeight.attr('disabled', !state);

    // Habilita checkbox de integración de objetivos
    const goalsIntegrationActivator =
      $(`.activator-goals-tracking-integration[data-section="${activator.attr('id')}"]`);
    goalsIntegrationActivator.attr('disabled', !state);

    if (!state) {
      inputWeight.val('');
      goalsIntegrationActivator.prop('checked', false);
    }
    $(`#id_${activator.attr('id')}`).attr('disabled', !state);
    $(`#name_${activator.attr('id')}`).attr('disabled', !state);
    if (activator.hasClass('parent-section')) {
      const divChild = $(`#child_section_${activator.attr('id')}`);
      const periodSelect = $(`#periodSelect_${activator.attr('id')}`);
      if (activator.prop('checked')) {
        divChild.removeClass('hide');
        $(`#child_section_${activator.attr('id')} :checkbox`).attr('disabled', false);
        divChild.find('input.activable').prop('disabled', false);
        periodSelect.prop('disabled', false);
        periodSelect.removeClass('text-basic-600');
        periodSelect.addClass('text-basic-900');
      }
      else {
        divChild.addClass('hide');
        $(`#child_section_${activator.attr('id')} :input[type="number"]`).attr('disabled', true);
        $(`#child_section_${activator.attr('id')} input:checkbox`).prop('checked', false);
        divChild.find('input.activable').prop('disabled', true);
        periodSelect.prop('disabled', true);
        periodSelect.removeClass('text-basic-900');
        periodSelect.addClass('text-basic-600');
      }
    }
  });

  const changeStatusListItemForm = () => {
    if (countActiveEvaluation() === 0) {
      listItemForm.removeClass('active');
      listItemForm.addClass('disabled');
      return;
    }
    listItemForm.removeClass('disabled');
  };

  const isMultipleFormsChecked = () => ($('input[type=radio][name=multiple_form]:checked').val() === 'true');

  const multipleForm = () => $('.nested-fields').not('.removed').find('.evaluation_form_name').length > 1;

  const stepAssignedToForm = (stepId) => {
    const formWithOutRemove = $('.nested-fields').not('.removed');
    const stepSelected = formWithOutRemove
      .find('.select-steps-active option:selected')
      .toArray()
      .map((item) => parseInt(item.value));
    return stepSelected.includes(stepId);
  };

  const validateStepAssignedToForm = (activableStepCheckbox) => {
    if (!isMultipleFormsChecked()) return;
    if (stepAssignedToForm(activableStepCheckbox.data('id'))) {
      activableStepCheckbox.prop('checked', true);
      warning(`Hay formularios que tienen esta evaluación asociada
      y se deben desvincular antes de poder desactivar el tipo de evaluación`);
      return true;
    }
  };

  const statusSectionPotential = (disabled) => {
    const formDefaultTab = $('a[data-form-default=true]').attr('href');
    const sectionsFormDefault = $(formDefaultTab);
    const potentialCheckbox = sectionsFormDefault.find('[data-section=potential]');
    potentialCheckbox.attr('disabled', disabled);
    const divChild = $(`#child_section_${potentialCheckbox.attr('id')}`);
    const scaleSelector = divChild.parent().parent().find('.section_scale_select');
    if (potentialCheckbox.prop('checked') && !potentialCheckbox.prop('disabled')) {
      divChild.removeClass('hide');
      scaleSelector.prop('disabled', false);
    }
    else {
      divChild.addClass('hide');
      scaleSelector.prop('disabled', true);
    }
  };

  steps.each(function (_i, step) {
    step = $(step);
    const kind = step.data('step');
    //formName es el nombre que Rails le entrega a los inputs al usar 'fields_for'
    const formName = step.data('name');

    const checkbox = step.find(`[name="${formName}[active]"]`);
    const stepInputs = step.find('input:visible:not([data-id])');
    const stepConfiguration = form.find(`[data-step-configuration="${kind}"]`);

    checkbox.change(function () {
      changeStatusListItemForm();
      if(this.checked) {
        stepConfiguration.show();
        stepInputs.attr('disabled', null);
        if (step.find('[data-kind]').data('kind') === 'boss' && !multipleForm()) {
          statusSectionPotential(!this.checked);
        }
      }
      else {
        if (validateStepAssignedToForm($(this))) return;
        stepConfiguration.hide();
        stepInputs.attr('disabled', 'disabled');
        if (step.find('[data-kind]').data('kind') === 'boss' && !multipleForm()) {
          statusSectionPotential(!this.checked);
        }
      }
    });
  });

  const moveItem = (item, to, position) => {
    to.append(item);
    item.find('.position').val(position);
  };

  const showStep = () => {
    if (countActiveEvaluation() === 0) return;
    for (let index = 1; index <= countActiveEvaluation(); index++) {
      $(`#step_${index}`).removeClass('hide');
    }
  };

  // Lo que hace esta parte es buscar los text area de la seccion de configuracion para eliminar sus ckeditor
  // y crearlos de nuevo ya que cuando estos se mueven en sortable.js quedan deshabilitados.
  $(".sortable[data-group='evaluations']").on('nestedFormSortableChangeElement', async function (_e) {
    const { RICH_EDITOR_OPTIONS } = await import('@assets/javascripts/components/ckeditor/custom_configs');
    const item = $(this);
    const textareas = $(item.find('textarea'));
    if (textareas) {
      textareas.each(async function () {
        const textAreaId = this.id;
        if (CKeditorVersion() === '4') {
          CKEDITOR.instances[textAreaId].destroy();
          CKEDITOR.replace(textAreaId, SIMPLE_EDITOR_OPTIONS());
        }
        else {
          await loadCKeditor5(this, _e, RICH_EDITOR_OPTIONS());
        }
      });
    }
  });

  const setDraggable = () => {
    $('.activate-draggable:checked').parents('.item-draggable').addClass('drag').removeClass('no-draggable');
  };

  if (sequentialChecbox.prop('checked')) {
    showStep();
    setDraggable();
  }

  sequentialChecbox.on('change', function () {
    const textWithoutAsign = $('#text_without_asign');
    if (sequentialChecbox.prop('checked')) {
      const sortableSteps = $('#sortable_steps');
      sortableSteps.toggleClass('sortable');
      textWithoutAsign.removeClass('hide');
      showStep();
      setDraggable();
    }
    else {
      textWithoutAsign.addClass('hide');
      $('.step').children('div').each(function () {
        moveItem($(this), $('.without-asign'), '0');
      });
      $('.drag').toggleClass('drag').toggleClass('no-draggable');
      $('.step').addClass('hide');
    }
  });

  $('.activate-draggable').on('change', function () {
    if (!sequentialChecbox.prop('checked')) return;
    const itemDraggable = $(this).closest('.item-draggable');
    if ($(this).prop('checked')) {
      $(`#step_${countActiveEvaluation()}`).removeClass('hide');
      itemDraggable.addClass('drag').removeClass('no-draggable');
    }
    else {
      const stepToRemove = $(`#step_${countActiveEvaluation() + 1}`);
      stepToRemove.addClass('hide');
      moveItem(itemDraggable, $('.without-asign'), '0');
      stepToRemove.children('div').each(function () {
        const step = $(`#step_${countActiveEvaluation()}`);
        moveItem($(this), step, step.data('position'));
      });
      itemDraggable.removeClass('drag').addClass('no-draggable');
    }
  });

  const getActiveSteps = () => $('.activate-draggable:checked');

  const removeOptionSelect = (select) => {
    select.find('option').remove();
  };

  const optionExist = (select, optionValue) => {
    const options = select.find('option').toArray().map((item) => parseInt(item.value));
    return options.includes(optionValue);
  };

  const removeEmptyQuestios = () => {
    const writableQuestions = $('.open-questions .nested-fields, .potential-questions .nested-fields');
    writableQuestions.each(function () {
      if ($(this).find('input[type="text"]').val().length === 0) {
        $(this).find('a.remove_fields').click();
      }
    });
  };

  const addActiveStepSelect = ({ select, event, removeAllFirst = true }) => {
    if (getActiveSteps().length === 0) {
      warning('Seleccione un tipo de evaluación');
      if (event) event.stopPropagation();
      return;
    }
    if (removeAllFirst) removeOptionSelect(select);
    getActiveSteps().each(function () {
      if (optionExist(select, parseInt($(this).data('id')))) return;
      select.append(`<option value="${$(this).data('id')}">${$(this).data('name')}</option>`);
    });
  };

  const changeStatusInputInside = (selector, state) => {
    $(`${selector}>:input`).prop('disabled', state);
  };

  const toggleHide = (checkbox, selector) => {
    const element = $(selector);
    if (checkbox.is(':checked')) {
      element.removeClass('hide');
      changeStatusInputInside(selector, false);
    }
    else {
      element.addClass('hide');
      changeStatusInputInside(selector, true);
    }
  };

  const randomInt = () => Math.floor(Math.random() * (10000 - 1) + 1);

  const addIds = (inputs) => {
    const randomId = randomInt();
    let actualId;
    inputs.each(function () {
      actualId = $(this).attr('id');
      if (actualId) $(this).attr('id', `${actualId}_${randomId}`);
    });
  };

  const changeTabMessageIds = (configurationSection) => {
    const randomId = randomInt();
    configurationSection.find('#tab-introduction-message').attr('id', `tab-introduction-message-${randomId}`);
    configurationSection.find('li a[href="#tab-introduction-message"]')
      .attr('href', `#tab-introduction-message-${randomId}`);
    configurationSection.find('#tab-end-message').attr('id', `tab-end-message-${randomId}`);
    configurationSection.find('li a[href="#tab-end-message"]').attr('href', `#tab-end-message-${randomId}`);
  };

  const updateQuestionTemplates = (insertedItem) => {
    ['open', 'potential'].forEach(function (type) {
      //Actualiza los id's del template para preguntas abiertas
      const questionWrapper = insertedItem.find(`.${type}-questions .child-section`);
      const actualId = questionWrapper.attr('id');
      const newOpenQuestionButton = questionWrapper.find('.add_fields');
      newOpenQuestionButton.data('association-insertion-node', `#${actualId} .${type}-questions-list`);

      let newQuestionTemplate = newOpenQuestionButton.data('association-insertion-template');
      //newQuestionTemplate = newQuestionTemplate.replace(new RegExp(`data-copy-from="([^"]*)"`, 'g'), 'data-copy-from="[]"');
      newQuestionTemplate = newQuestionTemplate.replace(
        new RegExp(`data-copy-from="([^"]*)"`, 'g'),
        `data-copy-from="#${actualId} [name=&quot;${type}_question_name&quot;]"`
      );
      newOpenQuestionButton.data('association-insertion-template', newQuestionTemplate);

      //Actualiza el selector del importador para importar preguntas de forma masiva
      const questionImportButton = questionWrapper.find(`[data-import="${type}-question"]`);
      const currentLink = questionImportButton.attr('href');
      const newLink = currentLink.replace(/([^=]*container=)(.*)/g, '$1' + encodeURIComponent(`#${actualId}`));

      questionImportButton.attr('href', newLink);
    });
  };

  $('#evaluation_forms').on('cocoon:before-insert', function (_e, insertedItem, originalEvent) {
    const newNameForm = $('#new_name_form');
    if (!newNameForm.val()) {
      warning('Por favor escriba un nombre para el formulario');
      originalEvent.preventDefault();
    }
    $('.evaluation_form_name').each(function () {
      if ($(this).val() === newNameForm.val()) {
        warning('Ya existe un formulario con ese nombre');
        originalEvent.preventDefault();
        return false;
      }
    });

    insertedItem.find('[data-persisted="false"]').each(function () {
      const input = $(this);
      const pathIc = input.attr('ic-post-to').split('?');
      const pathParams = pathIc[1];
      const paramsObject = queryString.parse(pathParams);
      const newFormId = input.attr('name').split('][')[1];

      const progressBarSelector = `.progress-bar-form-sections-${paramsObject.form}`;
      insertedItem.find(progressBarSelector).addClass(`progress-bar-form-sections-${newFormId}`);
      paramsObject.form = newFormId;
      input.attr('ic-target', `.progress-bar-form-sections-${newFormId}`);
      input.attr('ic-post-to', `${pathIc[0]}?${queryString.stringify(paramsObject)}`);
    });

  });

  $('#evaluation_forms').on('cocoon:after-insert', function (_e, insertedItem, _originalEvent) {
    const newNameForm = $('#new_name_form');
    const elementId = insertedItem.find('.evaluation_form_name')
      .attr('id')
      .replace('talent_evaluation_process_evaluation_forms_attributes_', '')
      .replace('_name', '');
    insertedItem.find('.evaluation_form_name').attr('value', newNameForm.val());
    insertedItem.find('.sections').addClass(elementId);
    insertedItem.find('.advanced-configurations').addClass(elementId);
    insertedItem.data('id', `tab-${elementId}`);
    newNameForm.val('');
    addActiveStepSelect({ select: insertedItem.find('.select-steps-active') });
    toggleHide($('#asign_role_familiy'), '.select-role-families');
    addIds(
      insertedItem.find('.sections input, .child-section').add(insertedItem.find('.sections select, .section-period'))
    );
    addIds(insertedItem.find('.advanced-configurations textarea'));
    addIds(insertedItem.find('.advanced-configurations input[type="checkbox"]'));
    changeTabMessageIds(insertedItem.find('.advanced-configurations'));
    updateQuestionTemplates(insertedItem);
  });

  $('#evaluation_forms').on('cocoon:after-remove', function (_e, removedItem, _originalEvent) {
    removedItem.addClass('removed');
    removedItem.find('[name$="[_destroy]"]').attr('value', 'true');
  });

  $('#action-config').on('click', function (e) {
    toggleHide($('#asign_role_familiy'), '.select-role-families');
    $('.select-steps-active').each(function () {
      addActiveStepSelect({ select: $(this), event: e, removeAllFirst: false });
    });
  });

  const changeStateDestroy = (state) => {
    $('.nested-fields').not('.default').each(function () {
      $(this).addClass('hide removed');
      $(this).find('[name$="[_destroy]"]').attr('value', state);
    });
  };

  $('input[type=radio][name=multiple_form]').change(function () {
    $('#config_forms').toggleClass('hide');
    if ($(this).val() === 'false') {
      divAlertModalForm.addClass('hide');
    }
    else {
      divAlertModalForm.removeClass('hide');
    }
  });

  $('#asign_role_familiy').change(function () {
    toggleHide($(this), '.select-role-families');
  });

  if (multipleForm()) {
    $('#multiple_form').prop('checked', true);
    divAlertModalForm.removeClass('hide');
  }
  else {
    $('#simple_form').prop('checked', true);
    divAlertModalForm.addClass('hide');
  }

  const tabExist = (selector) => $(`[href$='${selector}']`).length > 0;

  const updateTabName = (selector, name) => {
    $(`[href$='${selector}']`).text(name);
  };

  const removeTabs = (all = false) => {
    const formNames = [];
    $(`.nested-fields`).not('.removed').find('.evaluation_form_name').each(function () {
      formNames.push($(this).val().trim());
    });
    $('ul.nav-tabs li a[data-form-default=false]').each(function () {
      if (formNames.includes($(this).text().trim()) && !all) {
        return;
      }
      else {
        $(this).remove();// remove tab
        $($(this).attr('href')).remove(); // remove tab-content
      }
    });
  };

  const removeAllForm = () => {
    changeStateDestroy('true');
    removeTabs(true);
  };

  const generateTabs = (ulElement) => {
    $('#config-forms #nested-forms > .nested-fields').not('.removed').each(function () {
      const inputNameValue = $(this).find('.evaluation_form_name').val();
      if (tabExist(`#${$(this).data('id')}`)) {
        updateTabName(`#${$(this).data('id')}`, inputNameValue.trim());
        return;
      }
      const elementId = $(this).data('id').replace('tab-', '');

      ulElement.append($('<li></li>')
        .addClass('nav-item')
        .append($('<a></a>')
          .attr({
            href: `#tab-${elementId}`,
            role: 'tab',
            'data-toggle': 'tab',
            'data-form-default': false,
          })
          .addClass('nav-link')
          .html(inputNameValue.trim())));

      $(`.tab-content[data-id=${ulElement.data('id')}]`)
        .append($('<div></div>')
          .addClass('tab-pane')
          .attr({
            id: `tab-${elementId}`,
            'aria-labelledby': `tab-${elementId}`,
          }));

      const sections = $(`div.hide.sections.${elementId}`);
      $(`#tab-${elementId}`).append(sections);
      sections.removeClass('hide');

      const configuration = $(`div.advanced-configurations.${elementId}`);
      $(`#tab-${elementId}`).append(configuration);
      configuration.removeClass('hide');
      $(this).data('id', `tab-${elementId}`);
    });
  };

  const hasRoleFamily = () => $('#asign_role_familiy:checked').length > 0;

  // Funciones para mostrar y remover errores en inputs.
  const addInputError = (element, msg) => {
    if (element.hasClass('has-error')) return;
    element.addClass('has-error');
    element.append(`<span class="help-block">${msg}</span>`);
  };

  const removeInputError = (element) => {
    element.removeClass('has-error');
    element.find('span[class="help-block"]').remove();
  };

  //al menos un step asignado al formulario
  const atLeastOneStep = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    formWithOutRemove.find('.select-steps-active').each(function () {
      removeInputSelect2Error($(this));
      if ($(this).find('option:selected').length === 0) {
        addInputSelect2Error($(this), 'Seleccione al menos un tipo de evaluación');
        ok = false;
      }
    });
    if (!ok) errors.push('Se debe asignar al menos un tipo de evaluación por formulario');
    return ok;
  };

  //validar que todos los formularios tengan nombre
  const validateFormNames = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    formWithOutRemove.find('.evaluation_form_name').each(function () {
      removeInputError($(this).parent('div'));
      if ($(this).val().trim() === '') {
        addInputError($(this).parent('div'), 'Se debe asignar un nombre al formulario');
        ok = false;
      }
    });
    if (!ok) errors.push('Se debe asignar un nombre al formulario');
    return ok;
  };

  // no se pueden repetir los tipos de evaluación para los distintos formularios si no tiene familias de cargo seleccionadas
  const repeatedSteps = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    const message = 'No se puede asignar el mismo tipo de evaluación a más de un formulario';
    const stepSelected = formWithOutRemove.find('.select-steps-active option:selected').toArray()
      .map((item)=> parseInt(item.value));
    stepSelected.forEach(function (stepId) {
      const inputSelectStepSelected = formWithOutRemove.find(
        `.select-steps-active option:selected[value="${stepId}"]`
      ).parent();
      if (inputSelectStepSelected.length > 1) {
        ok = false;
        addInputSelect2Error(inputSelectStepSelected, message);
      }
    });
    if (!ok) errors.push(message);
    return ok;
  };

  const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);

  // no se pueden repetir los tipos de evaluación y familias de cargo para los distintos formularios
  const repeatedStepsWithRoleFamilies = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    const message = 'No se puede asignar el mismo tipo de evaluación y familia de cargo a más de un formulario';
    const roleFamiliesSelected = formWithOutRemove.find('[data-model=role-families] option:selected')
      .toArray().map((item)=> parseInt(item.value));
    removeInputSelect2Error($('[data-model=role-families]'));
    const roleFamilyEmpty = formWithOutRemove.find('[data-model=role-families]').filter(function () {
      return !this.value;
    });
    // usamos 0 para los select de familias de cargo que no tienen ninguna seleccionada
    if (roleFamilyEmpty.length > 1) roleFamiliesSelected.push(0);
    roleFamiliesSelected.forEach(function (roleFamilyId) {
      let inputSelectRoleFamilySelected = formWithOutRemove
        .find(`[data-model=role-families] option:selected[value="${roleFamilyId}"]`).parent();
      if (roleFamilyId === 0) inputSelectRoleFamilySelected = roleFamilyEmpty;
      if (inputSelectRoleFamilySelected.length > 1) {
        const optionStepSelected = inputSelectRoleFamilySelected.closest('.nested-fields').not('.removed')
          .find('.select-steps-active option:selected');
        const stepSeleted = optionStepSelected.toArray().map((item)=> parseInt(item.value));
        const duplicateSteps = findDuplicates(stepSeleted);
        if (duplicateSteps.length > 0) ok = false;
        duplicateSteps.forEach(function (stepId) {
          addInputSelect2Error(optionStepSelected.parent()
            .find(`option:selected[value="${stepId}"]`).parent(), message);
          addInputSelect2Error(optionStepSelected.parent().find(`option:selected[value="${stepId}"]`).parent()
            .closest('.nested-fields').find('[data-model=role-families]'), message);
        });
      }
    });
    if (!ok) errors.push(message);
    return ok;
  };

  const validateConfigForm = (eventSave) => {
    const errors = [];
    let atLeastOneStepResult = false;
    atLeastOneStepResult = atLeastOneStep(errors);
    validateFormNames(errors);
    if (hasRoleFamily()) {
      repeatedStepsWithRoleFamilies(errors, atLeastOneStepResult);
    }
    else {
      repeatedSteps(errors, atLeastOneStepResult);
    }
    if (errors.length === 0) {
      removeAlert(divAlertModalForm);
      return;
    }
    createAlertError(errors, divAlertModalForm);
    eventSave.stopPropagation();
  };

  /**
   * Método para agregar y/o borrar múltiples formularios
   */
  $('#save-forms').on('click', function (e) {
    const stepBoss = $('[data-kind=boss]').parent();
    const status = !stepBoss.find('[data-kind=boss]').is(':checked');
    (multipleForm()) ? statusSectionPotential(false) : statusSectionPotential(status);
    if (isMultipleFormsChecked()) {
      validateConfigForm(e);
      generateTabs($('.nav-tabs-custom[data-scrolling-tabs=true]').find('ul.nav.nav-tabs'));
      btnSummary.removeClass('hide');
    }
    else {
      removeAllForm();
      btnSummary.addClass('hide');
    }
    disableSecondaryBossPotentialScales();
    removeTabs();
    $.onmount();
  });

  $('[name="talent_evaluation_process[score_type]"]').on('change', function () {
    const original = $('#original');
    const other = $('#other');
    const maxValue = $('[data-max-score]');
    const value = maxValue.val();
    const otherValue = maxValue.data('other-value');

    if(original.hasClass('hide')) {
      other.addClass('hide');
      other.find(':input').prop('disabled', true);

      original.removeClass('hide');
      original.find(':input').prop('disabled', false);
    }
    else{
      original.addClass('hide');
      original.find(':input').prop('disabled', true);

      other.removeClass('hide');
      other.find(':input').prop('disabled', false);
    }

    maxValue.val(otherValue).data('other-value', value);
  });

  //logic button summary

  if (!isMultipleFormsChecked()) {
    btnSummary.addClass('hide');
  }

  const getFormbySteps = (stepName) => {
    const forms = [];
    $('.nested-fields').not('.removed').find('.select-steps-active option:selected').each(function () {
      if ($(this).text().trim() === stepName) {
        forms.push($(this).closest('.nested-fields').find('.evaluation_form_name').val());
      }
    });
    return forms;
  };

  const getRoleFamilies = (formName) => {
    if (!hasRoleFamily()) return [];
    let roleFamilies = [];
    $('.nested-fields').not('.removed').find('.evaluation_form_name').each(function () {
      if ($(this).val() === formName) {
        roleFamilies = $(this).closest('.nested-fields').not('.removed')
          .find('[data-model=role-families] option:selected').toArray().map((item)=> item.text);
      }
    });
    return roleFamilies.length > 0 ? roleFamilies : ['Todas'];
  };

  btnSummary.on('click', function (e) {
    const activeSteps = getActiveSteps();
    if (activeSteps.length === 0) {
      warning('No hay evaluaciones activas');
      e.stopPropagation();
      return;
    }
    const titleRoleFamilies = $('#title-role-families');
    if (hasRoleFamily()) {
      titleRoleFamilies.removeClass('hide');
    }
    else {
      titleRoleFamilies.addClass('hide');
    }

    const contentRowHtml = (col1, col2) => `<div class="row">
      <div class="col-md-6 ${hasRoleFamily() ? 'border-right' : ''}">${col1}</div>
      <div class="col-md-6">${col2.join(', ')}</div>
      </div>`;

    const generateContentByStep = (forms, step) => {
      const body = $(`.summary-body[data-id=${step.data('id')}]`);
      body.empty();
      forms.forEach(function (nameForm) {
        body.append(contentRowHtml(nameForm, getRoleFamilies(nameForm)));
      });
    };

    $('.evaluator-step-summary').addClass('hide');
    activeSteps.each(function () {
      $(`.evaluator-step-summary[data-id=${$(this).data('id')}]`).removeClass('hide');
      generateContentByStep(getFormbySteps($(this).data('name')), $(this));
    });
  });

  /**
   * Configurar preguntas enumeradas al crear/cargar preguntas libres / preguntas de potencial
   */
  function updateQuestionLabelNumbering(labels) {
    labels.each(function (i, label) {
      label.textContent = label.textContent.replace('placeholder_question_number', `${i + 1}`);
    });
  }

  onmount('.open-questions-list, .potential-questions-list', function () {
    $(this).on('cocoon:after-insert', function (_e, insertedItem, _originalEvent) {
      const labels = $(insertedItem).parent().find('label:visible');
      updateQuestionLabelNumbering(labels);
    });

    $(this).on('cocoon:after-remove', function (e, _insertedItem, _originalEvent) {
      const labels = $(e.target).find('label:visible');

      updateQuestionLabelNumbering(labels);
    });
  });

  onmount('[data-maximum-evaluators="true"]', function () {
    const regexNumberOrBlank = '^$|^[1-9]';
    const regexNumber = '^[1-9]';
    $(this).on('keyup', function () {
      const input = $(this);
      if (!input.val().match(regexNumberOrBlank)) {
        input.val(1);
      }
      else if (input.val() === '') {
        input.val('');
      }
    });
    $(this).on('change', function () {
      const input = $(this);
      if (!input.val().match(regexNumber)) {
        input.val(1);
      }
    });
  });

  const disableCheckbox = (checked, checkbox) => {
    if (checked) {
      checkbox.attr('disabled', false);
    }
    else {
      checkbox.attr('disabled', true);
      checkbox.prop('checked', false);
    }
  };

  /**
  * Deshabilita o habilita checkboxes según la selección de "Evaluación Descendente".
  */
  onmount('[data-name="Evaluación Descendente"]', function () {
    const calibrationCheckbox = $('#talent_evaluation_process_has_calibration_stage');
    const feedbackCheckbox = $('#talent_evaluation_process_has_feedback_stage');
    const checkbox = $(this);
    if (checkbox[0].dataset.evaluationProcessStatus === 'active') {
      return;
    }
    disableCheckbox(checkbox.prop('checked'), calibrationCheckbox);
    disableCheckbox(checkbox.prop('checked'), feedbackCheckbox);
    checkbox.on('change', function () {
      disableCheckbox(checkbox.prop('checked'), calibrationCheckbox);
      disableCheckbox(checkbox.prop('checked'), feedbackCheckbox);
    });
  });

  /**
  * Deshabilita o habilita la etapa de apelación según el estado de "Feedback".
  */
  onmount('#talent_evaluation_process_has_feedback_stage', function () {
    const appealCheckbox = $('#talent_evaluation_process_has_appeal_stage');
    const checkbox = $(this);
    if (checkbox[0].dataset.evaluationProcessStatus === 'active') {
      return;
    }
    disableCheckbox(checkbox.prop('checked'), appealCheckbox);
    checkbox.on('change', function () {
      disableCheckbox(checkbox.prop('checked'), appealCheckbox);
    });
  });

  /**
  * Controla la selección de escalas en un formulario.
  */
  onmount('select.section_scale_select', function () {
    const selectScale = $(this);
    const maxPercentageContainer = selectScale.parents(`:has(.max-percentage):first`).find('.max-percentage');
    const checkbox = selectScale.parents(`:has(.scale-section):first`).find('.scale-section');
    const wrapper = selectScale.parent();

    if  (checkbox.is(':checked') && selectScale.val() === '') {
      addInputError(wrapper, 'Se debe seleccionar una escala');

      $(checkbox).change(function () {
        removeInputError(wrapper);
      });

      $(selectScale).change(function () {
        removeInputError(wrapper);
      });
    }

    $(checkbox).change(function () {
      if(selectScale.hasClass('activable')) selectScale.attr('disabled', !checkbox.is(':checked'));
      maxPercentageContainer.find('input').attr('disabled', !checkbox.is(':checked'));
    });

    const addMaxPercentageInput = (select) => {
      const value = select.val();
      if (value === 'percentage') {
        maxPercentageContainer.find('span').show();
        maxPercentageContainer.find('input').show();
      }
      else {
        maxPercentageContainer.find('span').hide();
        maxPercentageContainer.find('input').hide();
      }
    };
    addMaxPercentageInput(selectScale);
    selectScale.on('change', function () {
      addMaxPercentageInput(selectScale);
    });
  });

  onmount('.form-group.has-error', function () {
    const parent = $(this);
    const config = { attributes: true };
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if(mutation.attributeName === 'disabled') {
          removeInputError(parent);
        }
      });
    });
    $(this).find('input.form-control').each(function () {
      observer.observe(this, config);
    }).change(function () {
      removeInputError(parent);
    });
  });

  form.submit(function () {
    removeEmptyQuestios();
  });

  onmount('[data-toogle-input]', function () {
    const self = $(this);
    const target = self.data('toogleInput');
    const elementTarget = self.parents(`:has(${target}):first`).find(target);
    self.on('click', function () {
      if (self.prop('checked')) {
        elementTarget.show('slow');
      }
      else {
        elementTarget.hide('slow');
      }
    });
  });

  /**
   * Validacion de total de Pesos de Competencias
   *  Verifica suma de pesos de categoría entre competencias transversales y específicas
   */
  onmount('[data-controller="capacitiy-weight-track"]', function () {
    const capacitiyWeightTrack = $(this);
    const section = capacitiyWeightTrack.parents('.child-section').first();

    const globalDiv = capacitiyWeightTrack.find('.row.global');
    const specificDiv = capacitiyWeightTrack.find('.row.specific');
    const globalWeight = globalDiv.find('.weight');
    const specificWeight = specificDiv.find('.weight');
    const errorDiv = capacitiyWeightTrack.find('.error');

    const globalCheckbox = section.find('.count-weight-global[type="checkbox"]:not([name*="no_global"])');
    const specificCheckbox = section.find('.count-weight-global[type="checkbox"][name*="no_global"]');
    const globalNumber = section.find('.count-weight-global[type="number"]:not([name*="no_global"])');
    const specificNumber = section.find('.count-weight-global[type="number"][name*="no_global"]');

    function isDisabledByRails() {
      return globalNumber.data('disabled-by-rails') === true;
    }

    function initializeState() {
      if (isDisabledByRails()) {
        capacitiyWeightTrack.css('display', 'none');
        globalDiv.css('display', 'none');
        specificDiv.css('display', 'none');
        errorDiv.css('display', 'none');
        globalNumber.prop('disabled', true);
        specificNumber.prop('disabled', true);
      }
    }

    function changeCapacitiyWeightTrack() {
      if (isDisabledByRails()) {
        capacitiyWeightTrack.css('display', 'none');
        globalDiv.css('display', 'none');
        specificDiv.css('display', 'none');
        errorDiv.css('display', 'none');
        globalNumber.prop('disabled', true);
        specificNumber.prop('disabled', true);
        return;
      }

      const showGlobal = globalCheckbox.is(':checked');
      const showSpecific = specificCheckbox.is(':checked');

      if (showGlobal || showSpecific) {
        capacitiyWeightTrack.css('display', '');
        let weightSum = 0;

        if (showGlobal) {
          weightSum = parseFloat(globalNumber.val() || 0);
          globalWeight.text(`${globalNumber.val() || '0'}%`);
          globalDiv.css('display', '');
          globalNumber.prop('disabled', isDisabledByRails());
        }
        else {
          globalDiv.css('display', 'none');
          globalNumber.prop('disabled', true);
        }

        if (showSpecific) {
          weightSum += parseFloat(specificNumber.val() || 0);
          specificWeight.text(`${specificNumber.val() || '0'}%`);
          specificDiv.css('display', '');
          specificNumber.prop('disabled', isDisabledByRails());
        }
        else {
          specificDiv.css('display', 'none');
          specificNumber.prop('disabled', true);
        }

        errorDiv.css('display', weightSum === 100 ? 'none' : '');
      }
      else {
        capacitiyWeightTrack.css('display', 'none');
        globalNumber.prop('disabled', true);
        specificNumber.prop('disabled', true);
      }
    }
    // Inicializa el estado
    initializeState();
    changeCapacitiyWeightTrack();

    // Siempre adjunta los manejadores de eventos para manejar los cambios de estado
    globalCheckbox.click(changeCapacitiyWeightTrack);
    specificCheckbox.click(changeCapacitiyWeightTrack);
    globalNumber.focusout(changeCapacitiyWeightTrack);
    specificNumber.focusout(changeCapacitiyWeightTrack);

    // Vuelve a ejecutar la verificación de estado cuando cualquier elemento relevante cambia
    globalNumber.on('change', changeCapacitiyWeightTrack);
    specificNumber.on('change', changeCapacitiyWeightTrack);
    globalCheckbox.on('change', changeCapacitiyWeightTrack);
    specificCheckbox.on('change', changeCapacitiyWeightTrack);

  });

  /**
   * Validación de pesos de categoría de competencias para cada tipo
   */
  onmount('[data-controller="count-weight-child-section"]', function () {
    const spanWeight = $(this);
    const section = spanWeight.parents(`:has(.child-section):first`).find('.child-section');

    function changeChildSectionWeightTrack(trigger) {
      const triggerInput = $(trigger);
      const disabledInput = triggerInput.parents(`:has([id^="weight_"]):first`).find('[id^="weight_"]')[0];

      let totalWeight = 0;
      section.find('input[type="number"]:not(:disabled)').toArray().forEach(function (weightInput) {
        if (disabledInput !== weightInput) {
          totalWeight += parseFloat(weightInput.value || 0);
        }
      });
      if (disabledInput.value && triggerInput.is("[type='checkbox']:checked, [type='number']")) {
        totalWeight += parseFloat(disabledInput.value || 0);
      }
      const color = totalWeight === 100 ? '#3c8dbc' : '#a94442';
      spanWeight.text(`${totalWeight}%`).parent().css('color', color);
    }

    section.find('input[type="number"]').focusout(function () {
      changeChildSectionWeightTrack(this);
    });
    section.find('input[type="checkbox"]').click(function () {
      changeChildSectionWeightTrack(this);
    });
  });

  /**
    * Activa o desactiva campos en una sección según el estado del checkbox.
  */
  $(document).on('change', '.activator-goals-tracking-integration', function () {
    const activator = $(this);
    const section = activator.data('section-id');
    const scaleSelector = $(`#section-scale-${section}`).find(`.section_scale_select`);
    if (activator.prop('checked')) {
      scaleSelector.val('percentage');
      $(`#child_section_${activator.data('section')} :checkbox`).attr('disabled', true);
      scaleSelector.find(`option[value='percentage']`).prop('selected', 'selected');
      scaleSelector.trigger('change');
      scaleSelector.prop('disabled', true);
      $(`#child_section_${activator.data('section')} :checkbox`).attr('disabled', true);
      $(`#child_section_${activator.data('section')} input:checkbox`).prop('checked', false);
      $(`#child_section_${activator.data('section')} :input[type="number"]`).attr('disabled', true);
      $(`#child_section_${activator.data('section')} :input[type="number"]`).val('');
    }
    else {
      scaleSelector.prop('disabled', false);
      $(`#child_section_${activator.data('section')} :checkbox`).attr('disabled', false);
      $(`#child_section_${activator.data('section')} input:checkbox`).prop('checked', false);
    }
  });
});

// Configuración: Ordenar Competencias
onmount("div[id^='sortable-capacity-']", function () {
  const $self = $(this);
  const checkbox = $self.find("input[type='checkbox']");
  const draggableDiv = $self.find('.col-12');
  const checkedCapacitiesContainer = $self.parent().parent().find('#sortable-capacities-checked');
  const uncheckedCapacitiesContainer = $self.parent().parent().find('#sortable-capacities-unchecked');
  const sortableIcon = $self.find('#sortable-points-icon');

  checkbox.on('click', function () {
    if (checkbox.is(':checked')) {
      draggableDiv.removeClass('no-draggable');
      $self.detach().appendTo(checkedCapacitiesContainer);
      sortableIcon.removeClass('display-none');
    }
    else {
      draggableDiv.addClass('no-draggable');
      $self.detach().appendTo(uncheckedCapacitiesContainer);
      sortableIcon.addClass('display-none');
    }
  });
});
// Configuración: Limitar la selección de escalas de potencial multiformulario descendente
const propagateBossPotentialScales = function () {
  const formWithOutRemove = $('.nested-fields').not('.removed').has('.select-steps-active option:selected');
  const stepBossId = $('[data-kind="boss"]').data('id');
  const bossFormsWithoutRemove = formWithOutRemove.filter(function () {
    return (
      $(this)
        .find('.select-steps-active option:selected')
        .toArray()
        .filter(opt => opt.value === `${stepBossId}`)
        .length > 0
    );
  });
  const mainBossForm = bossFormsWithoutRemove.first();
  const mainBossTab = $(`#${mainBossForm.data('id')}`);
  const mainTabSelect = mainBossTab.find('.potential_scale_select');
  const isMain = $(this).attr('id') === mainTabSelect.attr('id');
  if(isMain) {
    const mainSelectedScale = mainBossTab.find('.potential_scale_select').val();
    bossFormsWithoutRemove.slice(1).each(function () {
      const formTabId = $(this).data('id');
      $(`#${formTabId}`).find('.potential_scale_select').val(mainSelectedScale).change();
      const selectElement = $(`#${formTabId}`).find('.potential_scale_select');
      selectElement.find(`option[value="${mainSelectedScale}"]`).prop('selected', 'selected');
    });
  }
};

// Configuración: Deshabilitar escalas de potencial secundarias en multiformularios
onmount('.potential_scale_select', function () {
  disableSecondaryBossPotentialScales();
  $(this).change(propagateBossPotentialScales);
});

// Configuración: Deshabilitar escalas de potencial secundarias en multiformularios
onmount('form.evaluation_process_form', function () {
  $(this).on('submit', function () {
    $(this).find('.potential_scale_select').each(function () {
      const sectionName = `[type="checkbox"][name="${$(this).prop('name').replace('[talent_scale_id]', '[active]')}"]`;
      const isSectionChecked = $(sectionName).is(':checked');
      $(this).prop('disabled', !isSectionChecked);
    });
    const goalsIntegrationActivator = $(this).find('.activator-goals-tracking-integration');
    if (goalsIntegrationActivator.prop('checked')) {
      $(`.section_scale_select[data-section-id=${goalsIntegrationActivator.data('section-id')}]`)
        .prop('disabled', false);
    }
  });
});

// Configuración: Usado para esconder y mostrar el botón de configuración avanzada de resultados
onmount('.sections-forms-container', function () {
  const $self = $(this);
  const capacitiesCheckbox = $self.find('.active-capacities-checkbox');
  const goalsCheckbox = $self.find('.active-goals-checkbox');

  // Chequea si hay al menos un form que evalua competencias y objetivos al mismo tiempo
  // usando los checkbox
  function checkEvaluateCapacitiesAndGoals() {
    const allCapacitiesCheckboxes = $('.active-capacities-checkbox');
    let toReturn = false;
    allCapacitiesCheckboxes.each(function (_index, otherCapacitiesCheckbox) {
      const formId = otherCapacitiesCheckbox.attributes['ic-target'].value.split('-').at(-1);
      const otherGoalsCheckbox = $(`.active-goals-checkbox[ic-target$=${formId}]`);
      if (otherGoalsCheckbox.is(':checked') && otherCapacitiesCheckbox.checked) {
        toReturn = true;
        return;
      }
    });
    return toReturn;
  }

  // Esconde el botón según los checkbox
  function toggleResultsSettingsForm(clickedCheckbox, otherCheckbox) {
    const advancedConfigButton = $('#results-advanced-config');

    if (otherCheckbox.is(':checked') && clickedCheckbox.is(':checked')) {
      advancedConfigButton.removeClass('d-none');
    }
    else if (!(clickedCheckbox.is(':checked'))) {
      if (!checkEvaluateCapacitiesAndGoals()) advancedConfigButton.addClass('d-none');
    }
  }

  capacitiesCheckbox.on('click', function () {toggleResultsSettingsForm(capacitiesCheckbox, goalsCheckbox);});
  goalsCheckbox.on('click', function () {toggleResultsSettingsForm(goalsCheckbox, capacitiesCheckbox);});
});

// Configuración: Porcentaje metodología custom
onmount('.limit-decimal-length', function () {
  const $self = $(this);

  $self.on('change', function () {
    $self.val(parseFloat($self.val()).toFixed(2));
  });
});

// Evaluación: validaciones para el formulario de evaluación
onmount('[data-max-score="true"]', function () {
  var maxLimit = $('[data-max-score="true"]');
  var last = $('#original .nested-fields:last-child').find('[data-result-scale-field="max"]');

  maxLimit.on('click', function () {
    checkLimit();
  });

  last.on('click', function () {
    checkLimit();
  });

  function monitor() {
    if (last !== undefined) {
      last.off('change');
      last.parents('.form-group').removeClass('has-error');
    }

    last = $('#original .nested-fields:last-child').find("[data-result-scale-field='max']");
    checkLimit();
    last.change(function () {
      checkLimit(last);
    });
  }

  function checkLimit() {
    const valor = parseFloat(last[0].value);
    if (parseFloat(maxLimit[0].value) < valor) {
      maxLimit.parents('.form-group').addClass('has-error');
      last.parents('.form-group').addClass('has-error');
    }
    else {
      maxLimit.parents('.form-group').removeClass('has-error');
      last.parents('.form-group').removeClass('has-error');
    }
  }

  const observer = new MutationObserver(function (mutationsList) {
    mutationsList.forEach(function (mutation) {
      mutation.addedNodes.forEach(function () {
        monitor();
      });
      mutation.removedNodes.forEach(function () {
        monitor();
      });
    });
  });

  observer.observe(document.querySelector('#original'), { subtree: false, childList: true });
});

// Formulario de configuración versión 2
onmount('[data-evaluation-process-form-v2]', async function (obj) {
  const { loadCKeditor, CKeditorVersion, loadCKeditor5 } = await import('@assets/javascripts/components/ckeditor/load');
  if (CKeditorVersion() === '4') {
    await loadCKeditor(this, obj);
  }
  const form = $(this);
  const steps = form.find('[data-step]');
  const sequentialChecbox = $('#evaluation_process_sequential');
  const btnSummary = $('[data-id=summary]');
  const listItemForm = $('[data-item-form=true]');
  const divAlertModalForm = $('[data-modal-errors=true]');
  const btnPublishOptions = $('[data-config-publish-options]');

  const countActiveEvaluation = () => $('.activate-draggable:checked').length;

  // guarda la opción seleccionada al abrir el modal de opciones de publicación
  btnPublishOptions.on('click', function () {
    const optionSelected = $('input[data-boss-publish]:checked').val();
    $(this).data('option-selected', optionSelected);
  });

  // al presionar el botón cancelar en el modal de opciones de publicación selecciona la opción que tenía antes de abrir el modal
  $('[data-btn-cancel-publish-options]').on('click', function () {
    const lastOption = btnPublishOptions.data('option-selected');
    $(`input[data-boss-publish][value=${lastOption}]`).prop('checked', lastOption);
  });

  $('input[type="number"][name$="[weight]"]').change(function () {
    const inputNumber = $(this);
    const value = parseInt(inputNumber.val());
    const maxValue = parseInt(inputNumber.attr('max'));
    const minValue = parseInt(inputNumber.attr('min'));
    inputNumber.val(Math.min(
      Math.max(value, isNaN(minValue) ? -Infinity : minValue),
      isNaN(maxValue) ? Infinity : maxValue
    ));
  });

  $('.activator').each(function () {
    const activator = $(this);
    const inputWeight = $(`#weight_${activator.attr('id')}`);
    const scale = $(`select[data-checkbox="${activator.attr('id')}"]`);
    const inputId = $(`#id_${activator.attr('id')}`);
    const goalsIntegrationActivator =
      $(`.activator-goals-tracking-integration[data-section="${activator.attr('id')}"]`);
    if (activator.prop('checked')) {
      inputWeight.attr('disabled', false);
      inputId.attr('disabled', false);
      scale.attr('disabled', false);
      goalsIntegrationActivator.attr('disabled', false);
      if (goalsIntegrationActivator.prop('checked')) {
        const scaleSelector =
          $(`.section_scale_select[data-section-id=${goalsIntegrationActivator.data('section-id')}]`);
        scaleSelector.prop('disabled', true);
        $(`#child_section_${goalsIntegrationActivator.data('section')} :checkbox`).attr('disabled', true);
        $(`#child_section_${goalsIntegrationActivator.data('section')} input:checkbox`).prop('checked', false);
        $(`#child_section_${goalsIntegrationActivator.data('section')} :input[type="number"]`).attr('disabled', true);
        $(`#child_section_${goalsIntegrationActivator.data('section')} :input[type="number"]`).val('');
      }
    }
    else {
      inputWeight.attr('disabled', true);
      inputId.attr('disabled', true);
      scale.attr('disabled', true);
      goalsIntegrationActivator.attr('disabled', true);
    }
  });

  $(document).on('change', '.activator', function () {
    const activator = $(this);
    const state = $(`#weight_${activator.attr('id')}`).attr('disabled') || activator.prop('checked');
    const inputWeight = $(`#weight_${activator.attr('id')}`);
    inputWeight.attr('disabled', !state);

    // Habilita checkbox de integración de objetivos
    const goalsIntegrationActivator =
      $(`.activator-goals-tracking-integration[data-section="${activator.attr('id')}"]`);
    goalsIntegrationActivator.attr('disabled', !state);

    if (!state) {
      inputWeight.val('');
      goalsIntegrationActivator.prop('checked', false);
    }
    $(`#id_${activator.attr('id')}`).attr('disabled', !state);
    $(`#name_${activator.attr('id')}`).attr('disabled', !state);
    if (activator.hasClass('parent-section')) {
      const divChild = $(`#child_section_${activator.attr('id')}`);
      const periodSelect = $(`#periodSelect_${activator.attr('id')}`);
      if (activator.prop('checked')) {
        $(`#child_section_${activator.attr('id')} :checkbox`).attr('disabled', false);
        divChild.find('input.activable').prop('disabled', false);
        periodSelect.prop('disabled', false);
        periodSelect.removeClass('text-basic-600');
        periodSelect.addClass('text-basic-900');
      }
      else {
        $(`#child_section_${activator.attr('id')} :input[type="number"]`).attr('disabled', true);
        $(`#child_section_${activator.attr('id')} input:checkbox`).prop('checked', false);
        divChild.find('input.activable').prop('disabled', true);
        periodSelect.prop('disabled', true);
        periodSelect.removeClass('text-basic-900');
        periodSelect.addClass('text-basic-600');
      }
    }
  });

  const changeStatusListItemForm = () => {
    if (countActiveEvaluation() === 0) {
      listItemForm.removeClass('active');
      listItemForm.addClass('disabled');
      return;
    }
    listItemForm.removeClass('disabled');
  };

  const isMultipleFormsChecked = () => ($('input[type=radio][name=multiple_form]:checked').val() === 'true');

  const multipleForm = () => $('.nested-fields').not('.removed').find('.evaluation_form_name').length > 1;

  const stepAssignedToForm = (stepId) => {
    const formWithOutRemove = $('.nested-fields').not('.removed');
    const stepSelected = formWithOutRemove
      .find('.select-steps-active option:selected')
      .toArray()
      .map((item) => parseInt(item.value));
    return stepSelected.includes(stepId);
  };

  const validateStepAssignedToForm = (activableStepCheckbox) => {
    if (!isMultipleFormsChecked()) return;
    if (stepAssignedToForm(activableStepCheckbox.data('id'))) {
      activableStepCheckbox.prop('checked', true);
      warning(`Hay formularios que tienen esta evaluación asociada
      y se deben desvincular antes de poder desactivar el tipo de evaluación`);
      return true;
    }
  };

  const statusSectionPotential = (disabled) => {
    const formDefaultTab = $('a[data-form-default=true]').attr('href');
    const sectionsFormDefault = $(formDefaultTab);
    const potentialCheckbox = sectionsFormDefault.find('[data-section=potential]');
    potentialCheckbox.attr('disabled', disabled);
    const divChild = $(`#child_section_${potentialCheckbox.attr('id')}`);
    const scaleSelector = divChild.parent().parent().find('.section_scale_select');
    if (potentialCheckbox.prop('checked') && !potentialCheckbox.prop('disabled')) {
      scaleSelector.prop('disabled', false);
    }
    else {
      scaleSelector.prop('disabled', true);
    }
  };

  steps.each(function (_i, step) {
    step = $(step);
    const kind = step.data('step');
    //formName es el nombre que Rails le entrega a los inputs al usar 'fields_for'
    const formName = step.data('name');

    const checkbox = step.find(`[name="${formName}[active]"]`);
    const stepInputs = step.find('input:visible:not([data-id])');
    const stepConfiguration = form.find(`[data-step-configuration="${kind}"]`);

    checkbox.change(function () {
      changeStatusListItemForm();
      if(this.checked) {
        stepConfiguration.show();
        stepInputs.attr('disabled', null);
        if (step.find('[data-kind]').data('kind') === 'boss' && !multipleForm()) {
          statusSectionPotential(!this.checked);
        }
      }
      else {
        if (validateStepAssignedToForm($(this))) return;
        stepConfiguration.hide();
        stepInputs.attr('disabled', 'disabled');
        if (step.find('[data-kind]').data('kind') === 'boss' && !multipleForm()) {
          statusSectionPotential(!this.checked);
        }
      }
    });
  });

  const moveItem = (item, to, position) => {
    to.append(item);
    item.find('.position').val(position);
  };

  const showStep = () => {
    if (countActiveEvaluation() === 0) return;
    for (let index = 1; index <= countActiveEvaluation(); index++) {
      $(`#step_${index}`).removeClass('hide');
    }
  };

  // Lo que hace esta parte es buscar los text area de la seccion de configuracion para eliminar sus ckeditor
  // y crearlos de nuevo ya que cuando estos se mueven en sortable.js quedan deshabilitados.
  $(".sortable[data-group='evaluations']").on('nestedFormSortableChangeElement', async function (_e) {
    const { RICH_EDITOR_OPTIONS } = await import('@assets/javascripts/components/ckeditor/custom_configs');
    const item = $(this);
    const textareas = $(item.find('textarea'));
    if (textareas) {
      textareas.each(async function () {
        const textAreaId = this.id;
        if (CKeditorVersion() === '4') {
          CKEDITOR.instances[textAreaId].destroy();
          CKEDITOR.replace(textAreaId, SIMPLE_EDITOR_OPTIONS());
        }
        else {
          await loadCKeditor5(this, _e, RICH_EDITOR_OPTIONS());
        }
      });
    }
  });

  const setDraggable = () => {
    $('.activate-draggable:checked').parents('.item-draggable').addClass('drag').removeClass('no-draggable');
  };

  if (sequentialChecbox.prop('checked')) {
    showStep();
    setDraggable();
  }

  sequentialChecbox.on('change', function () {
    const textWithoutAsign = $('#text_without_asign');
    if (sequentialChecbox.prop('checked')) {
      const sortableSteps = $('#sortable_steps');
      sortableSteps.toggleClass('sortable');
      textWithoutAsign.removeClass('hide');
      showStep();
      setDraggable();
    }
    else {
      textWithoutAsign.addClass('hide');
      $('.step').children('div').each(function () {
        moveItem($(this), $('.without-asign'), '0');
      });
      $('.drag').toggleClass('drag').toggleClass('no-draggable');
      $('.step').addClass('hide');
    }
  });

  $('.activate-draggable').on('change', function () {
    if (!sequentialChecbox.prop('checked')) return;
    const itemDraggable = $(this).closest('.item-draggable');
    if ($(this).prop('checked')) {
      $(`#step_${countActiveEvaluation()}`).removeClass('hide');
      itemDraggable.addClass('drag').removeClass('no-draggable');
    }
    else {
      const stepToRemove = $(`#step_${countActiveEvaluation() + 1}`);
      stepToRemove.addClass('hide');
      moveItem(itemDraggable, $('.without-asign'), '0');
      stepToRemove.children('div').each(function () {
        const step = $(`#step_${countActiveEvaluation()}`);
        moveItem($(this), step, step.data('position'));
      });
      itemDraggable.removeClass('drag').addClass('no-draggable');
    }
  });

  const getActiveSteps = () => $('.activate-draggable:checked');

  const removeOptionSelect = (select) => {
    select.find('option').remove();
  };

  const optionExist = (select, optionValue) => {
    const options = select.find('option').toArray().map((item) => parseInt(item.value));
    return options.includes(optionValue);
  };

  const removeEmptyQuestios = () => {
    const writableQuestions = $('.open-questions .nested-fields, .potential-questions .nested-fields');
    writableQuestions.each(function () {
      if ($(this).find('input[type="text"]').val().length === 0) {
        $(this).find('a.remove_fields').click();
      }
    });
  };

  const addActiveStepSelect = ({ select, event, removeAllFirst = true }) => {
    if (getActiveSteps().length === 0) {
      warning('Seleccione un tipo de evaluación');
      if (event) event.stopPropagation();
      return;
    }
    if (removeAllFirst) removeOptionSelect(select);
    getActiveSteps().each(function () {
      if (optionExist(select, parseInt($(this).data('id')))) return;
      select.append(`<option value="${$(this).data('id')}">${$(this).data('name')}</option>`);
    });
  };

  const changeStatusInputInside = (selector, state) => {
    $(`${selector}>:input`).prop('disabled', state);
  };

  const toggleHide = (checkbox, selector) => {
    const element = $(selector);
    if (checkbox.is(':checked')) {
      element.removeClass('hide');
      changeStatusInputInside(selector, false);
    }
    else {
      element.addClass('hide');
      changeStatusInputInside(selector, true);
    }
  };

  const randomInt = () => Math.floor(Math.random() * (10000 - 1) + 1);

  const addIds = (inputs) => {
    const randomId = randomInt();
    let actualId;
    inputs.each(function () {
      actualId = $(this).attr('id');
      if (actualId) $(this).attr('id', `${actualId}_${randomId}`);
    });
  };

  const changeTabMessageIds = (configurationSection) => {
    const randomId = randomInt();
    configurationSection.find('#tab-introduction-message').attr('id', `tab-introduction-message-${randomId}`);
    configurationSection.find('li a[href="#tab-introduction-message"]')
      .attr('href', `#tab-introduction-message-${randomId}`);
    configurationSection.find('#tab-end-message').attr('id', `tab-end-message-${randomId}`);
    configurationSection.find('li a[href="#tab-end-message"]').attr('href', `#tab-end-message-${randomId}`);
  };

  const updateQuestionTemplates = (insertedItem) => {
    ['open', 'potential'].forEach(function (type) {
      //Actualiza los id's del template para preguntas abiertas
      const questionWrapper = insertedItem.find(`.${type}-questions .child-section`);
      const actualId = questionWrapper.attr('id');
      const newOpenQuestionButton = questionWrapper.find('.add_fields');
      newOpenQuestionButton.data('association-insertion-node', `#${actualId} .${type}-questions-list`);

      let newQuestionTemplate = newOpenQuestionButton.data('association-insertion-template');
      //newQuestionTemplate = newQuestionTemplate.replace(new RegExp(`data-copy-from="([^"]*)"`, 'g'), 'data-copy-from="[]"');
      newQuestionTemplate = newQuestionTemplate.replace(
        new RegExp(`data-copy-from="([^"]*)"`, 'g'),
        `data-copy-from="#${actualId} [name=&quot;${type}_question_name&quot;]"`
      );
      newOpenQuestionButton.data('association-insertion-template', newQuestionTemplate);

      //Actualiza el selector del importador para importar preguntas de forma masiva
      const questionImportButton = questionWrapper.find(`[data-import="${type}-question"]`);
      const currentLink = questionImportButton.attr('href');
      const newLink = currentLink.replace(/([^=]*container=)(.*)/g, '$1' + encodeURIComponent(`#${actualId}`));

      questionImportButton.attr('href', newLink);
    });
  };

  $('#evaluation_forms').on('cocoon:before-insert', function (_e, insertedItem, originalEvent) {
    const newNameForm = $('#new_name_form');
    if (!newNameForm.val()) {
      warning('Por favor escriba un nombre para el formulario');
      originalEvent.preventDefault();
    }
    $('.evaluation_form_name').each(function () {
      if ($(this).val() === newNameForm.val()) {
        warning('Ya existe un formulario con ese nombre');
        originalEvent.preventDefault();
        return false;
      }
    });

    insertedItem.find('[data-persisted="false"]').each(function () {
      const input = $(this);
      const pathIc = input.attr('ic-post-to').split('?');
      const pathParams = pathIc[1];
      const paramsObject = queryString.parse(pathParams);
      const newFormId = input.attr('name').split('][')[1];

      const progressBarSelector = `.progress-bar-form-sections-${paramsObject.form}`;
      insertedItem.find(progressBarSelector).addClass(`progress-bar-form-sections-${newFormId}`);
      paramsObject.form = newFormId;
      input.attr('ic-target', `.progress-bar-form-sections-${newFormId}`);
      input.attr('ic-post-to', `${pathIc[0]}?${queryString.stringify(paramsObject)}`);
    });

  });

  $('#evaluation_forms').on('cocoon:after-insert', function (_e, insertedItem, _originalEvent) {
    const newNameForm = $('#new_name_form');
    const elementId = insertedItem.find('.evaluation_form_name')
      .attr('id')
      .replace('talent_evaluation_process_evaluation_forms_attributes_', '')
      .replace('_name', '');
    insertedItem.find('.evaluation_form_name').attr('value', newNameForm.val());
    insertedItem.find('.sections').addClass(elementId);
    insertedItem.find('.advanced-configurations').addClass(elementId);
    insertedItem.data('id', `tab-${elementId}`);
    newNameForm.val('');
    addActiveStepSelect({ select: insertedItem.find('.select-steps-active') });
    toggleHide($('#asign_role_familiy'), '.select-role-families');
    addIds(
      insertedItem.find('.sections input, .child-section').add(insertedItem.find('.sections select, .section-period'))
    );
    addIds(insertedItem.find('.advanced-configurations textarea'));
    addIds(insertedItem.find('.advanced-configurations input[type="checkbox"]'));
    changeTabMessageIds(insertedItem.find('.advanced-configurations'));
    updateQuestionTemplates(insertedItem);
  });

  $('#evaluation_forms').on('cocoon:after-remove', function (_e, removedItem, _originalEvent) {
    removedItem.addClass('removed');
    removedItem.find('[name$="[_destroy]"]').attr('value', 'true');
  });

  $('#action-config').on('click', function (e) {
    toggleHide($('#asign_role_familiy'), '.select-role-families');
    $('.select-steps-active').each(function () {
      addActiveStepSelect({ select: $(this), event: e, removeAllFirst: false });
    });
  });

  const changeStateDestroy = (state) => {
    $('.nested-fields').not('.default').each(function () {
      $(this).addClass('hide removed');
      $(this).find('[name$="[_destroy]"]').attr('value', state);
    });
  };

  $('input[type=radio][name=multiple_form]').change(function () {
    $('#config_forms').toggleClass('hide');
    if ($(this).val() === 'false') {
      divAlertModalForm.addClass('hide');
    }
    else {
      divAlertModalForm.removeClass('hide');
    }
  });

  $('#asign_role_familiy').change(function () {
    toggleHide($(this), '.select-role-families');
  });

  if (multipleForm()) {
    $('#multiple_form').prop('checked', true);
    divAlertModalForm.removeClass('hide');
  }
  else {
    $('#simple_form').prop('checked', true);
    divAlertModalForm.addClass('hide');
  }

  const tabExist = (selector) => $(`[href$='${selector}']`).length > 0;

  const updateTabName = (selector, name) => {
    $(`[href$='${selector}']`).text(name);
  };

  const removeTabs = (all = false) => {
    const formNames = [];
    $(`.nested-fields`).not('.removed').find('.evaluation_form_name').each(function () {
      formNames.push($(this).val().trim());
    });
    $('ul.nav-tabs li a[data-form-default=false]').each(function () {
      if (formNames.includes($(this).text().trim()) && !all) {
        return;
      }
      else {
        $(this).remove();// remove tab
        $($(this).attr('href')).remove(); // remove tab-content
      }
    });
  };

  const removeAllForm = () => {
    changeStateDestroy('true');
    removeTabs(true);
  };

  const generateTabs = (ulElement) => {
    $('#config-forms #nested-forms > .nested-fields').not('.removed').each(function () {
      const inputNameValue = $(this).find('.evaluation_form_name').val();
      if (tabExist(`#${$(this).data('id')}`)) {
        updateTabName(`#${$(this).data('id')}`, inputNameValue.trim());
        return;
      }
      const elementId = $(this).data('id').replace('tab-', '');

      ulElement.append($('<li></li>')
        .addClass('nav-item')
        .append($('<a></a>')
          .attr({
            href: `#tab-${elementId}`,
            role: 'tab',
            'data-toggle': 'tab',
            'data-form-default': false,
          })
          .addClass('nav-link')
          .html(inputNameValue.trim())));

      $(`.tab-content[data-id=${ulElement.data('id')}]`)
        .append($('<div></div>')
          .addClass('tab-pane')
          .attr({
            id: `tab-${elementId}`,
            'aria-labelledby': `tab-${elementId}`,
          }));

      const sections = $(`div.hide.sections.${elementId}`);
      $(`#tab-${elementId}`).append(sections);
      sections.removeClass('hide');

      const configuration = $(`div.advanced-configurations.${elementId}`);
      $(`#tab-${elementId}`).append(configuration);
      configuration.removeClass('hide');
      $(this).data('id', `tab-${elementId}`);
    });
  };

  const hasRoleFamily = () => $('#asign_role_familiy:checked').length > 0;

  // Funciones para mostrar y remover errores en inputs.
  const addInputError = (element, msg) => {
    if (element.hasClass('has-error')) return;
    element.addClass('has-error');
    element.append(`<span class="help-block">${msg}</span>`);
  };

  const removeInputError = (element) => {
    element.removeClass('has-error');
    element.find('span[class="help-block"]').remove();
  };

  //al menos un step asignado al formulario
  const atLeastOneStep = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    formWithOutRemove.find('.select-steps-active').each(function () {
      removeInputSelect2Error($(this));
      if ($(this).find('option:selected').length === 0) {
        addInputSelect2Error($(this), 'Seleccione al menos un tipo de evaluación');
        ok = false;
      }
    });
    if (!ok) errors.push('Se debe asignar al menos un tipo de evaluación por formulario');
    return ok;
  };

  //validar que todos los formularios tengan nombre
  const validateFormNames = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    formWithOutRemove.find('.evaluation_form_name').each(function () {
      removeInputError($(this).parent('div'));
      if ($(this).val().trim() === '') {
        addInputError($(this).parent('div'), 'Se debe asignar un nombre al formulario');
        ok = false;
      }
    });
    if (!ok) errors.push('Se debe asignar un nombre al formulario');
    return ok;
  };

  // no se pueden repetir los tipos de evaluación para los distintos formularios si no tiene familias de cargo seleccionadas
  const repeatedSteps = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    const message = 'No se puede asignar el mismo tipo de evaluación a más de un formulario';
    const stepSelected = formWithOutRemove.find('.select-steps-active option:selected').toArray()
      .map((item)=> parseInt(item.value));
    stepSelected.forEach(function (stepId) {
      const inputSelectStepSelected = formWithOutRemove.find(
        `.select-steps-active option:selected[value="${stepId}"]`
      ).parent();
      if (inputSelectStepSelected.length > 1) {
        ok = false;
        addInputSelect2Error(inputSelectStepSelected, message);
      }
    });
    if (!ok) errors.push(message);
    return ok;
  };

  const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);

  // no se pueden repetir los tipos de evaluación y familias de cargo para los distintos formularios
  const repeatedStepsWithRoleFamilies = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    const message = 'No se puede asignar el mismo tipo de evaluación y familia de cargo a más de un formulario';
    const roleFamiliesSelected = formWithOutRemove.find('[data-model=role-families] option:selected')
      .toArray().map((item)=> parseInt(item.value));
    removeInputSelect2Error($('[data-model=role-families]'));
    const roleFamilyEmpty = formWithOutRemove.find('[data-model=role-families]').filter(function () {
      return !this.value;
    });
    // usamos 0 para los select de familias de cargo que no tienen ninguna seleccionada
    if (roleFamilyEmpty.length > 1) roleFamiliesSelected.push(0);
    roleFamiliesSelected.forEach(function (roleFamilyId) {
      let inputSelectRoleFamilySelected = formWithOutRemove
        .find(`[data-model=role-families] option:selected[value="${roleFamilyId}"]`).parent();
      if (roleFamilyId === 0) inputSelectRoleFamilySelected = roleFamilyEmpty;
      if (inputSelectRoleFamilySelected.length > 1) {
        const optionStepSelected = inputSelectRoleFamilySelected.closest('.nested-fields').not('.removed')
          .find('.select-steps-active option:selected');
        const stepSeleted = optionStepSelected.toArray().map((item)=> parseInt(item.value));
        const duplicateSteps = findDuplicates(stepSeleted);
        if (duplicateSteps.length > 0) ok = false;
        duplicateSteps.forEach(function (stepId) {
          addInputSelect2Error(optionStepSelected.parent()
            .find(`option:selected[value="${stepId}"]`).parent(), message);
          addInputSelect2Error(optionStepSelected.parent().find(`option:selected[value="${stepId}"]`).parent()
            .closest('.nested-fields').find('[data-model=role-families]'), message);
        });
      }
    });
    if (!ok) errors.push(message);
    return ok;
  };

  const validateConfigForm = (eventSave) => {
    const errors = [];
    let atLeastOneStepResult = false;
    atLeastOneStepResult = atLeastOneStep(errors);
    validateFormNames(errors);
    if (hasRoleFamily()) {
      repeatedStepsWithRoleFamilies(errors, atLeastOneStepResult);
    }
    else {
      repeatedSteps(errors, atLeastOneStepResult);
    }
    if (errors.length === 0) {
      removeAlert(divAlertModalForm);
      return;
    }
    createAlertError(errors, divAlertModalForm);
    eventSave.stopPropagation();
  };

  /**
   * Método para agregar y/o borrar múltiples formularios
   */
  $('#save-forms').on('click', function (e) {
    const stepBoss = $('[data-kind=boss]').parent();
    const status = !stepBoss.find('[data-kind=boss]').is(':checked');
    (multipleForm()) ? statusSectionPotential(false) : statusSectionPotential(status);
    if (isMultipleFormsChecked()) {
      validateConfigForm(e);
      generateTabs($('.nav-tabs-custom[data-scrolling-tabs=true]').find('ul.nav.nav-tabs'));
      btnSummary.removeClass('hide');
    }
    else {
      removeAllForm();
      btnSummary.addClass('hide');
    }
    disableSecondaryBossPotentialScales();
    removeTabs();
    $.onmount();
  });

  $('[name="talent_evaluation_process[score_type]"]').on('change', function () {
    const original = $('#original');
    const other = $('#other');
    const maxValue = $('[data-max-score]');
    const value = maxValue.val();
    const otherValue = maxValue.data('other-value');

    if(original.hasClass('hide')) {
      other.addClass('hide');
      other.find(':input').prop('disabled', true);

      original.removeClass('hide');
      original.find(':input').prop('disabled', false);
    }
    else{
      original.addClass('hide');
      original.find(':input').prop('disabled', true);

      other.removeClass('hide');
      other.find(':input').prop('disabled', false);
    }

    maxValue.val(otherValue).data('other-value', value);
  });

  //logic button summary

  if (!isMultipleFormsChecked()) {
    btnSummary.addClass('hide');
  }

  const getFormbySteps = (stepName) => {
    const forms = [];
    $('.nested-fields').not('.removed').find('.select-steps-active option:selected').each(function () {
      if ($(this).text().trim() === stepName) {
        forms.push($(this).closest('.nested-fields').find('.evaluation_form_name').val());
      }
    });
    return forms;
  };

  const getRoleFamilies = (formName) => {
    if (!hasRoleFamily()) return [];
    let roleFamilies = [];
    $('.nested-fields').not('.removed').find('.evaluation_form_name').each(function () {
      if ($(this).val() === formName) {
        roleFamilies = $(this).closest('.nested-fields').not('.removed')
          .find('[data-model=role-families] option:selected').toArray().map((item)=> item.text);
      }
    });
    return roleFamilies.length > 0 ? roleFamilies : ['Todas'];
  };

  btnSummary.on('click', function (e) {
    const activeSteps = getActiveSteps();
    if (activeSteps.length === 0) {
      warning('No hay evaluaciones activas');
      e.stopPropagation();
      return;
    }
    const titleRoleFamilies = $('#title-role-families');
    if (hasRoleFamily()) {
      titleRoleFamilies.removeClass('hide');
    }
    else {
      titleRoleFamilies.addClass('hide');
    }

    const contentRowHtml = (col1, col2) => `<div class="row">
      <div class="col-md-6 ${hasRoleFamily() ? 'border-right' : ''}">${col1}</div>
      <div class="col-md-6">${col2.join(', ')}</div>
      </div>`;

    const generateContentByStep = (forms, step) => {
      const body = $(`.summary-body[data-id=${step.data('id')}]`);
      body.empty();
      forms.forEach(function (nameForm) {
        body.append(contentRowHtml(nameForm, getRoleFamilies(nameForm)));
      });
    };

    $('.evaluator-step-summary').addClass('hide');
    activeSteps.each(function () {
      $(`.evaluator-step-summary[data-id=${$(this).data('id')}]`).removeClass('hide');
      generateContentByStep(getFormbySteps($(this).data('name')), $(this));
    });
  });

  /**
   * Configurar preguntas enumeradas al crear/cargar preguntas libres / preguntas de potencial
   */
  function updateQuestionLabelNumbering(labels) {
    labels.each(function (i, label) {
      label.textContent = label.textContent.replace('placeholder_question_number', `${i + 1}`);
    });
  }

  onmount('.open-questions-list, .potential-questions-list', function () {
    $(this).on('cocoon:after-insert', function (_e, insertedItem, _originalEvent) {
      const labels = $(insertedItem).parent().find('label:visible');
      updateQuestionLabelNumbering(labels);
    });

    $(this).on('cocoon:after-remove', function (e, _insertedItem, _originalEvent) {
      const labels = $(e.target).find('label:visible');

      updateQuestionLabelNumbering(labels);
    });
  });

  onmount('[data-maximum-evaluators="true"]', function () {
    const regexNumberOrBlank = '^$|^[1-9]';
    const regexNumber = '^[1-9]';
    $(this).on('keyup', function () {
      const input = $(this);
      if (!input.val().match(regexNumberOrBlank)) {
        input.val(1);
      }
      else if (input.val() === '') {
        input.val('');
      }
    });
    $(this).on('change', function () {
      const input = $(this);
      if (!input.val().match(regexNumber)) {
        input.val(1);
      }
    });
  });

  const disableCheckbox = (checked, checkbox) => {
    if (checked) {
      checkbox.attr('disabled', false);
    }
    else {
      checkbox.attr('disabled', true);
      checkbox.prop('checked', false);
    }
  };

  /**
  * Deshabilita o habilita checkboxes según la selección de "Evaluación Descendente".
  */
  onmount('[data-name="Evaluación Descendente"]', function () {
    const calibrationCheckbox = $('#talent_evaluation_process_has_calibration_stage');
    const feedbackCheckbox = $('#talent_evaluation_process_has_feedback_stage');
    const checkbox = $(this);
    if (checkbox[0].dataset.evaluationProcessStatus === 'active') {
      return;
    }
    disableCheckbox(checkbox.prop('checked'), calibrationCheckbox);
    disableCheckbox(checkbox.prop('checked'), feedbackCheckbox);
    checkbox.on('change', function () {
      disableCheckbox(checkbox.prop('checked'), calibrationCheckbox);
      disableCheckbox(checkbox.prop('checked'), feedbackCheckbox);
    });
  });

  /**
  * Deshabilita o habilita la etapa de apelación según el estado de "Feedback".
  */
  onmount('#talent_evaluation_process_has_feedback_stage', function () {
    const appealCheckbox = $('#talent_evaluation_process_has_appeal_stage');
    const checkbox = $(this);
    if (checkbox[0].dataset.evaluationProcessStatus === 'active') {
      return;
    }
    disableCheckbox(checkbox.prop('checked'), appealCheckbox);
    checkbox.on('change', function () {
      disableCheckbox(checkbox.prop('checked'), appealCheckbox);
    });
  });

  /**
  * Controla la selección de escalas en un formulario.
  */
  onmount('select.section_scale_select', function () {
    const selectScale = $(this);
    const maxPercentageContainer = selectScale.parents(`:has(.max-percentage):first`).find('.max-percentage');
    const checkbox = selectScale.parents(`:has(.scale-section):first`).find('.scale-section');
    const wrapper = selectScale.parent();

    if  (checkbox.is(':checked') && selectScale.val() === '') {
      addInputError(wrapper, 'Se debe seleccionar una escala');

      $(checkbox).change(function () {
        removeInputError(wrapper);
      });

      $(selectScale).change(function () {
        removeInputError(wrapper);
      });
    }

    $(checkbox).change(function () {
      if(selectScale.hasClass('activable')) selectScale.attr('disabled', !checkbox.is(':checked'));
      maxPercentageContainer.find('input').attr('disabled', !checkbox.is(':checked'));
    });

    const addMaxPercentageInput = (select) => {
      const value = select.val();
      if (value === 'percentage') {
        maxPercentageContainer.find('span').show();
        maxPercentageContainer.find('input').show();
      }
      else {
        maxPercentageContainer.find('span').hide();
        maxPercentageContainer.find('input').hide();
      }
    };
    addMaxPercentageInput(selectScale);
    selectScale.on('change', function () {
      addMaxPercentageInput(selectScale);
    });
  });

  onmount('.form-group.has-error', function () {
    const parent = $(this);
    const config = { attributes: true };
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if(mutation.attributeName === 'disabled') {
          removeInputError(parent);
        }
      });
    });
    $(this).find('input.form-control').each(function () {
      observer.observe(this, config);
    }).change(function () {
      removeInputError(parent);
    });
  });

  form.submit(function () {
    removeEmptyQuestios();
  });

  /**
   * Validacion de total de Pesos de Competencias
   *  Verifica suma de pesos de categoría entre competencias transversales y específicas
   */
  onmount('[data-controller="capacitiy-weight-track"]', function () {
    const capacitiyWeightTrack = $(this);
    const section = capacitiyWeightTrack.parents('.child-section').first();

    const globalDiv = capacitiyWeightTrack.find('.row.global');
    const specificDiv = capacitiyWeightTrack.find('.row.specific');
    const globalWeight = globalDiv.find('.weight');
    const specificWeight = specificDiv.find('.weight');
    const errorDiv = capacitiyWeightTrack.find('.error');

    const globalCheckbox = section.find('.count-weight-global[type="checkbox"]:not([name*="no_global"])');
    const specificCheckbox = section.find('.count-weight-global[type="checkbox"][name*="no_global"]');
    const globalNumber = section.find('.count-weight-global[type="number"]:not([name*="no_global"])');
    const specificNumber = section.find('.count-weight-global[type="number"][name*="no_global"]');

    function isDisabledByRails() {
      return globalNumber.data('disabled-by-rails') === true;
    }

    function initializeState() {
      if (isDisabledByRails()) {
        capacitiyWeightTrack.css('display', 'none');
        globalDiv.css('display', 'none');
        specificDiv.css('display', 'none');
        errorDiv.css('display', 'none');
        globalNumber.prop('disabled', true);
        specificNumber.prop('disabled', true);
      }
    }

    function changeCapacitiyWeightTrack() {
      if (isDisabledByRails()) {
        capacitiyWeightTrack.css('display', 'none');
        globalDiv.css('display', 'none');
        specificDiv.css('display', 'none');
        errorDiv.css('display', 'none');
        globalNumber.prop('disabled', true);
        specificNumber.prop('disabled', true);
        return;
      }

      const showGlobal = globalCheckbox.is(':checked');
      const showSpecific = specificCheckbox.is(':checked');

      if (showGlobal || showSpecific) {
        capacitiyWeightTrack.css('display', '');
        let weightSum = 0;

        if (showGlobal) {
          weightSum = parseFloat(globalNumber.val() || 0);
          globalWeight.text(`${globalNumber.val() || '0'}%`);
          globalDiv.css('display', '');
          globalNumber.prop('disabled', isDisabledByRails());
        }
        else {
          globalDiv.css('display', 'none');
          globalNumber.prop('disabled', true);
        }

        if (showSpecific) {
          weightSum += parseFloat(specificNumber.val() || 0);
          specificWeight.text(`${specificNumber.val() || '0'}%`);
          specificDiv.css('display', '');
          specificNumber.prop('disabled', isDisabledByRails());
        }
        else {
          specificDiv.css('display', 'none');
          specificNumber.prop('disabled', true);
        }

        errorDiv.css('display', weightSum === 100 ? 'none' : '');
      }
      else {
        capacitiyWeightTrack.css('display', 'none');
        globalNumber.prop('disabled', true);
        specificNumber.prop('disabled', true);
      }
    }
    // Inicializa el estado
    initializeState();
    changeCapacitiyWeightTrack();

    // Siempre adjunta los manejadores de eventos para manejar los cambios de estado
    globalCheckbox.click(changeCapacitiyWeightTrack);
    specificCheckbox.click(changeCapacitiyWeightTrack);
    globalNumber.focusout(changeCapacitiyWeightTrack);
    specificNumber.focusout(changeCapacitiyWeightTrack);

    // Vuelve a ejecutar la verificación de estado cuando cualquier elemento relevante cambia
    globalNumber.on('change', changeCapacitiyWeightTrack);
    specificNumber.on('change', changeCapacitiyWeightTrack);
    globalCheckbox.on('change', changeCapacitiyWeightTrack);
    specificCheckbox.on('change', changeCapacitiyWeightTrack);

  });

  /**
   * Validación de pesos de categoría de competencias para cada tipo
   */
  onmount('[data-controller="count-weight-child-section"]', function () {
    const spanWeight = $(this);
    const section = spanWeight.parents(`:has(.child-section):first`).find('.child-section');

    function changeChildSectionWeightTrack(trigger) {
      const triggerInput = $(trigger);
      const disabledInput = triggerInput.parents(`:has([id^="weight_"]):first`).find('[id^="weight_"]')[0];

      let totalWeight = 0;
      section.find('input[type="number"]:not(:disabled)').toArray().forEach(function (weightInput) {
        if (disabledInput !== weightInput) {
          totalWeight += parseFloat(weightInput.value || 0);
        }
      });
      if (disabledInput.value && triggerInput.is("[type='checkbox']:checked, [type='number']")) {
        totalWeight += parseFloat(disabledInput.value || 0);
      }
      const color = totalWeight === 100 ? '#3c8dbc' : '#a94442';
      spanWeight.text(`${totalWeight}%`).parent().css('color', color);
    }

    section.find('input[type="number"]').focusout(function () {
      changeChildSectionWeightTrack(this);
    });
    section.find('input[type="checkbox"]').click(function () {
      changeChildSectionWeightTrack(this);
    });
  });

  /**
    * Activa o desactiva campos en una sección según el estado del checkbox.
  */
  $(document).on('change', '.activator-goals-tracking-integration', function () {
    const activator = $(this);
    const section = activator.data('section-id');
    const scaleSelector = $(`#section-scale-${section}`).find(`.section_scale_select`);
    if (activator.prop('checked')) {
      scaleSelector.val('percentage');
      $(`#child_section_${activator.data('section')} :checkbox`).attr('disabled', true);
      scaleSelector.find(`option[value='percentage']`).prop('selected', 'selected');
      scaleSelector.trigger('change');
      scaleSelector.prop('disabled', true);
      $(`#child_section_${activator.data('section')} :checkbox`).attr('disabled', true);
      $(`#child_section_${activator.data('section')} input:checkbox`).prop('checked', false);
      $(`#child_section_${activator.data('section')} :input[type="number"]`).attr('disabled', true);
      $(`#child_section_${activator.data('section')} :input[type="number"]`).val('');
    }
    else {
      scaleSelector.prop('disabled', false);
      $(`#child_section_${activator.data('section')} :checkbox`).attr('disabled', false);
      $(`#child_section_${activator.data('section')} input:checkbox`).prop('checked', false);
    }
  });

  // Extrae el tipo de step a partir del id del elemento
  var stepKind = this.id.replace('notify_start_', '');
  // Busca el checkbox dentro del contenedor o usa el propio elemento
  var $checkbox = $(this).find("input[type='checkbox']").length ? $(this).find("input[type='checkbox']") : $(this);
  // Selecciona el área de notificación asociada a este step
  var $notificationMessage = $('#notification_message_' + stepKind);

  // Función para actualizar el estado habilitado/deshabilitado
  var updateNotificationState = function () {
    $notificationMessage.prop('disabled', !$checkbox.is(':checked'));
  };

  // Inicializa el estado y escucha el evento 'change'
  updateNotificationState();
  $checkbox.on('change', updateNotificationState);
});

/**
 * Maneja la habilitación/deshabilitación del campo de mensaje de notificación para formulario v2
 */
onmount('[data-step-configuration]', function () {
  // Solo aplica para el formulario v2
  if (!$(this).closest('form').find('[data-feature="talent_evaluation_edit_configuration_v2"]').length) return;

  const $container = $(this);
  const stepKind = $container.data('step-configuration');
  const $notifyStartCheckbox = $container.find('input[name*="[notify_start]"]');
  const textareaId = `notification_message_${stepKind}`;

  function toggleNotificationMessage() {
    const isEnabled = $notifyStartCheckbox.is(':checked');
    if (window.CKEDITOR && CKEDITOR.instances[textareaId]) {
      const editor = CKEDITOR.instances[textareaId];
      editor.setReadOnly(!isEnabled);
      $(editor.container.$).find('.cke_wysiwyg_frame').css('opacity', isEnabled ? '1' : '0.5');
    }
    $(`#${textareaId}`).prop('disabled', !isEnabled);
  }

  // Configuración inicial y manejo de cambios
  setTimeout(toggleNotificationMessage, 1000);
  $notifyStartCheckbox.on('change', toggleNotificationMessage);
});

$(document).ready(function () {
  // Función para manejar el estado del editor CKEditor para los campos de notificación
  function setupNotificationMessageToggle() {
    $('[data-step-configuration]').each(function () {
      const $container = $(this);
      const stepKind = $container.data('step-configuration');
      const $notifyStartCheckbox = $container.find('input[name*="[notify_start]"]');
      const textareaId = `notification_message_${stepKind}`;

      function toggleNotificationMessage() {
        const isEnabled = $notifyStartCheckbox.is(':checked');
        if (window.CKEDITOR && CKEDITOR.instances[textareaId]) {
          const editor = CKEDITOR.instances[textareaId];
          editor.setReadOnly(!isEnabled);
          if (editor.container && editor.container.$) {
            $(editor.container.$).css('opacity', isEnabled ? '1' : '0.5');
          }
        }
        $(`#${textareaId}`).prop('disabled', !isEnabled);
      }

      // Configuración inicial y manejo de cambios
      setTimeout(toggleNotificationMessage, 1000);
      $notifyStartCheckbox.on('change', toggleNotificationMessage);
    });
  }

  // Ejecuta la configuración después de que CKEditor se haya inicializado
  setTimeout(setupNotificationMessageToggle, 1500);
});
