/** @file asideCopilotToggle()
 */
import onmount from 'onmount';

//si al momento de montar el componente el aside copilot se encuentra abierto, se le agrega un margen derecho al freshchat
$(document).on('turbolinks:load', function () {
  // No habilitado para mobile
  if(isTouchDevice()) {
    return;
  }

  // Abrir chat copilot cuando se activa desde el marketplace
  $('a[href="/marketplace/solutions/activate?dependencies%5Bhabilitar_modulos_copilot%5D=true"]').on(
    'click', function () {
      localStorage.setItem('copilotActive', 'true');
    });

  var copilotButton = $('[data-toggle="show-aside-copilot"]').hasClass('copilot-general-active');
  if(!copilotButton) {
    try {
      localStorage.removeItem('copilotActive');
    }
    catch (e) {
      return;
    }
  }
  else if (localStorage.getItem('copilotActive') === null && copilotButton) {
    localStorage.setItem('copilotActive', 'false');
  }

  const targetNode = document.body;

  const observerOptions = {
    childList: true,
    subtree: true,
  };

  let stopObserver = false;
  /* si pasa mas de 5 segundos y el observer sigue activo, se detiene para no escapar memoria,
    especificamente cuando fresh no se monta correctamente */
  setTimeout(() => {
    if(!stopObserver) {
      stopObserver = true;
    }
  }, 5000);
  const observer = new MutationObserver(function () {
    if(stopObserver) {
      observer.disconnect();
      return;
    }
    const freshContainer = document.getElementById('fc_frame');
    if(freshContainer !== null) {
      const copilotContainer = document.getElementsByClassName('aside-copilot-wrapper')[0];
      const rectFresh = freshContainer.getBoundingClientRect();
      if (copilotContainer) {
        const rectCopilot = copilotContainer.getBoundingClientRect();
        if (rectFresh.right > rectCopilot.left) {
          freshContainer.className += ' copilot-collapse';
          stopObserver = true;
        }
      }
      else {
        stopObserver = true;
      }
    }
  });

  observer.observe(targetNode, observerOptions);
});

// Función que permite mover los widgets de la integración con intercom para cuando esta colapsado
// o no el menú de copilot
function moveIntercomWidgets(collapse) {
  const launcherWidget = document.getElementsByClassName('intercom-lightweight-app-launcher')[0];
  const chatWidget = document.getElementsByClassName('intercom-messenger-frame')[0];
  let minimizeButton = document.getElementsByClassName('intercom-launcher-frame')[0];
  if (minimizeButton) { minimizeButton = minimizeButton.parentElement; }
  [launcherWidget, chatWidget, minimizeButton].forEach((element) => {
    if(element) {
      element.style.transition = 'right 0.3s ease-in-out';
      element.style.right = collapse ? '20px' : '420px';
    }
  });
}

onmount('[data-toggle="show-aside-copilot"]', async function () {
  // No habilitado para mobile
  if(isTouchDevice()) {
    $(this).hide();
    return;
  }

  const contentWraper = $('.content-wrapper');
  const asideCopilot = $('.aside-copilot-wrapper');
  const navBarContainer = $('.main-header');
  const copilotActive = localStorage.getItem('copilotActive');

  // Se maneja el estado del aside copilot mediante localStorage para manterner el estado al recargar la página
  if (copilotActive === 'true') {
    contentWraper.addClass('copilot-collapse');
    asideCopilot.removeClass('collapsed');
    navBarContainer.addClass('copilot-collapse');
  }
  $(this).on('click', function (e) {
    if ($(this).hasClass('copilot-general-active') === false) {
      window.location.href = '/marketplace/solutions/landing/buk_copilot';
    }
    else{
      e.preventDefault();
      if (localStorage.getItem('copilotActive') === 'true') {
        localStorage.setItem('copilotActive', 'false');
        moveIntercomWidgets(true);
      }
      else {
        localStorage.setItem('copilotActive', 'true');
        moveIntercomWidgets(false);
        $(this).trigger('amplitudeTracking', {
          message: 'Start_Assistant_Copilot',
          data: {
            source: 'NavBar',
          },
        });
      }
      const freshButton = $('#fc_frame');
      contentWraper.toggleClass('copilot-collapse');
      freshButton.toggleClass('copilot-collapse');
      navBarContainer.toggleClass('copilot-collapse');
      asideCopilot.toggleClass('collapsed');
    }
  });

  const textArea = document.getElementById('input-copilot');

  textArea?.addEventListener('input', () => {
    textArea.style.height = '34px';
    textArea.style.height = `${Math.min(textArea.scrollHeight, 64)}px`;

    if (textArea.value === '') textArea.style.height = '34px';
  });

});

/** funcion que permite verificar si un dispositivo es mobile mediante propiedad touch **/
function isTouchDevice() {
  /* eslint-disable-next-line no-undef */
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
