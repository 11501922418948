/** @file Controla el nombre de las acciones y ejecuta las llamadas ajax según la interacción del usuario con la tabla de empleados */
import onmount from 'onmount';
import { actionMessage } from '../lib/buk/alert';
import ModalBuilder from '../lib/modal-builder';

onmount('#data_filter_btn', function () {
  var openLiquidationbtn = $('#abrir_liquidacion_empleados');
  var closeLiquidationbtn = $('#cerrar_liquidacion_empleados');
  var restrictLiquidationbtn = $('#restringir_liquidacion_empleados');
  var publishLiquidationbtn = $('#publicar_liquidacion_empleados');
  var unpublishLiquidationbtn = $('#ocultar_liquidaciones_empleados');
  var dataFilterComponent = $('[ref="data-filter-ref"]')[0].__vue__;
  var removeEmployeebtn = $('#remover_empleados');

  unbindEvents();

  dataFilterComponent.$on('update', function () {
    changeSelectedEmployeessCount(false);
  });

  $(document).on('change', '#process_employees_table', function () {
    changeSelectedEmployeessCount();
  });

  openLiquidationbtn.on('click', function (e) {
    e.preventDefault();
    const bukSubprocessId = $(this).data('bukSubprocessId');
    const url = `/buk_subprocesses/${bukSubprocessId}/referenced_liquidacions`;
    var employeeIds = selectedEmployeeIds();
    $.post(url, { employee_ids: employeeIds }, function (response) {
      if (response.has_referenced_liquidacions) {
        showSweetAlert(e, response);
      }
      else {
        executeAjaxCall(openLiquidationbtn.attr('href'), defaultActionData());
        triggerReopenPayrollAmplitude(e);
      }
    });
    return false;
  });

  closeLiquidationbtn.on('click', function (e) {
    e.preventDefault();
    executeAjaxCall(closeLiquidationbtn.attr('href'), defaultActionData());
    return false;
  });

  restrictLiquidationbtn.on('click', function (e) {
    e.preventDefault();
    executeAjaxCall(restrictLiquidationbtn.attr('href'), defaultActionData());
    const totalEmployeesCount = $('#process_employees_table').DataTable().page.info().recordsTotal;
    const selectedEmployeesCount = countSelectedEmployees();
    const data = {
      'payrolls_affected': selectedEmployeesCount > 0 ? selectedEmployeesCount : totalEmployeesCount,
      'restricted_status': true,
      'source': 'Proceso',
    };
    $(e.target).trigger('amplitudeTracking', { message: 'Toggle_Restrict_Payrolls', data: data });
    return false;
  });

  publishLiquidationbtn.on('click', function (e) {
    e.preventDefault();
    const modalSize = 'md';
    const modalTitle = publishLiquidationbtn.attr('data-modal-title');
    const data = defaultActionData();
    const params = {
      employee_ids: data.employee_ids,
      segments: data.segments,
      advanced_filters_params: data.advanced_filters_params,
    };
    const modalBuilder = new ModalBuilder();
    modalBuilder.setSize(modalSize)
      .setFooter('')
      .show()
      .loadFromRemote(publishLiquidationbtn.attr('href'), params, modalTitle);
    return false;
  });

  unpublishLiquidationbtn.on('click', function (e) {
    e.preventDefault();
    executeAjaxCall(unpublishLiquidationbtn.attr('href'), defaultActionData());
    return false;
  });

  removeEmployeebtn.on('click', function (e) {
    e.preventDefault();
    const selectedEmployeesCount = countSelectedEmployees();
    if (selectedEmployeesCount > 0) {
      const confirmSwalMsg = removeEmployeebtn.attr('data-multi-select-confirm-swal');
      const subMessage = removeEmployeebtn.attr('data-confirm-swal-secondary-message');
      const accept = removeEmployeebtn.attr('data-confirm-swal-accept');
      const decline = removeEmployeebtn.attr('data-confirm-swal-decline');
      const icon = removeEmployeebtn.attr('data-confirm-swal-icon');
      actionMessage(confirmSwalMsg, subMessage, accept, decline, icon)
        .then(result => {
          if (result.isConfirmed) {
            executeAjaxCall(removeEmployeebtn.attr('href'), removeEmployeeActionData(), 'delete');
          }
        });
    }
    return false;
  });

  function executeAjaxCall(url, data, method = 'post') {
    $.ajax({
      url: url,
      method: method,
      data: data,
    });
  }

  function changeSelectedEmployeessCount(actionFromTable = true) {
    var labelTxt = '(Todas)';
    const selectedEmployeesCount = countSelectedEmployees();
    if (selectedEmployeesCount > 0 && actionFromTable) {
      labelTxt = '(' + selectedEmployeesCount + ')';
    }
    else if ($('#serialized_filters').val()) {
      labelTxt = '(Segmento)';
    }
    if (selectedEmployeesCount === 0) {
      removeEmployeebtn.addClass('disabled');
      removeEmployeebtn.find('span').html('(0)');
    }
    else {
      removeEmployeebtn.removeClass('disabled');
      removeEmployeebtn.find('span').html(labelTxt);
    }
    openLiquidationbtn.find('span').html(labelTxt);
    closeLiquidationbtn.find('span').html(labelTxt);
    restrictLiquidationbtn.find('span').html(labelTxt);
    publishLiquidationbtn.find('span').html(labelTxt);
    unpublishLiquidationbtn.find('span').html(labelTxt);
  }

  function countSelectedEmployees() {
    return selectedEmployees().length;
  }

  function selectedEmployees() {
    return $('#process_employees_table tr:not(:first) input[type="checkbox"]:checked');
  }

  function unbindEvents() {
    dataFilterComponent.$off('update');
    $(document).off('change', '#process_employees_table');
    openLiquidationbtn.off('click');
    closeLiquidationbtn.off('click');
    restrictLiquidationbtn.off('click');
    publishLiquidationbtn.off('click');
    unpublishLiquidationbtn.off('click');
    removeEmployeebtn.off('click');
  }

  function defaultActionData() {
    const data = {
      employee_ids: [],
      segments: $('#select_segment').val(),
      advanced_filters_params: $('#serialized_filters').val(),
    };
    if (countSelectedEmployees() > 0) {
      selectedEmployees().each(function () {
        data.employee_ids.push($(this).val());
      });
    }
    return data;
  }

  function removeEmployeeActionData() {
    const employees = selectedEmployeeIds();
    return {
      buk_subprocess: {
        buk_subprocess_employees: {
          employees: employees,
        },
      },
    };
  }

  function selectedEmployeeIds() {
    if (countSelectedEmployees() > 0) {
      return selectedEmployees().map(function () { return $(this).val(); }).get().join(',');
    }
    return '';
  }

  function showSweetAlert(event, response) {
    const confirmSwalMsg = openLiquidationbtn.attr('data-multi-select-confirm-swal');
    const subMessage = response.secondary_message;
    const accept = openLiquidationbtn.attr('data-confirm-swal-accept');
    const decline = openLiquidationbtn.attr('data-confirm-swal-decline');
    const icon = openLiquidationbtn.attr('data-confirm-swal-icon');
    actionMessage(confirmSwalMsg, subMessage, accept, decline, icon)
      .then(result => {
        if (result.isConfirmed) {
          executeAjaxCall(openLiquidationbtn.attr('href'), defaultActionData());
          triggerReopenPayrollAmplitude(event);
        }
      });
  }

  function triggerReopenPayrollAmplitude(event) {
    const totalEmployeesCount = $('#process_employees_table').DataTable().page.info().recordsTotal;
    const selectedEmployeesCount = countSelectedEmployees();
    const data = {
      'payrolls_reopened': selectedEmployeesCount > 0 ? selectedEmployeesCount : totalEmployeesCount,
      'process_stage': $(event.currentTarget).data('stage'),
    };
    $(event.target).trigger('amplitudeTracking', { message: 'Reopen_Payrolls', data: data });
  }
});
