/** @file
 * Archivo para obtener la geolocalización del usuario en el marcaje web
 */
import onmount from 'onmount';
import { notyAlertComplete } from '../../../../../../../../app/assets/javascripts/lib/buk/noty-alert';

onmount('#web-marking-widget', function () {
  const $buttons = $('#web-marking-widget .btn-asistencia');

  const enableButtons = () => $buttons.attr('disabled', false);

  const updateButtonUrls = (latitude, longitude) => {
    $buttons.each(function () {
      const $btn = $(this);
      const postToUrl = $btn.attr('ic-src') || $btn.attr('ic-post-to');
      if (postToUrl) {
        const updatedUrl = `${postToUrl}&latitude=${latitude}&longitude=${longitude}`;
        $btn.attr('ic-src', updatedUrl).attr('ic-post-to', updatedUrl);
      }
    });
  };

  if (!navigator.geolocation) {
    enableButtons();
    return;
  }

  navigator.permissions
    .query({ name: 'geolocation' })
    .then(permissionStatus => {
      if (permissionStatus.state === 'denied') {
        enableButtons();
        return;
      }

      if (permissionStatus.state === 'prompt') {
        notyAlertComplete(
          'Permisos de Ubicación',
          'Concede los permisos de ubicación en tu navegador para poder incluirla en tu comprobante de marcación.',
          '10000',
          'info'
        );
      }

      return new Promise(resolve => {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            updateButtonUrls(latitude, longitude);
            resolve();
          },
          () => resolve()
        );
      });
    })
    .finally(() => {
      enableButtons();
    });
});
