/** @file TODO: documentar */
import onmount from 'onmount';
import * as notify from '../../../../../../../../app/assets/javascripts/lib/buk/notice.js';
import Intercooler from '../../../../../../../../app/assets/javascripts/lib/intercooler.js';

// Método para construir el ícono que representa el tipo de dato al agregar un elemento a un contenedor.
function buildIconType(eventItem) {
  const fieldIconFromChipList = $(eventItem).find('input[name$="[chip_icon]"]').val();
  const fieldIconFromContainers = $(eventItem).find('input[name="chip_icon"]').val();
  const fieldIcon = fieldIconFromChipList || fieldIconFromContainers;
  return `<span class="material-icons notranslate pr-1" aria-hidden="true">${fieldIcon}</span>`;
}

// Descripción del parámetro al hacer Hover
function buildChipHtml(chipIconType, fieldType) {
  return `
    <div class="chip badge badge-default">
      ${chipIconType}
      <span>${fieldType.text}</span>
    </div>
  `;
}

// Definir las opciones del select una sola vez
const orderByOptions = [
  { value: '', text: 'Sin Ordenar' },
  { value: '0', text: 'Ascendente' },
  { value: '1', text: 'Descendente' },
];

// Definir las opciones del select de metricas de string, date, boolean
const metricOptionsForStringDateBoolean = [
  { value: '0', text: 'Separado por comas' },
  { value: '1', text: 'Contar' },
];

// Definir las opciones del select de metricas de string, date, boolean
const metricOptionsForNumeric = [
  { value: '1', text: 'Contar' },
  { value: '2', text: 'Media' },
  { value: '3', text: 'Sumar' },
  { value: '4', text: 'Máxima' },
  { value: '5', text: 'Mínima' },
];

// TODO: Definir las opciones del tipo de dato con traducciones
const dataType = [
  { value: '0', text: 'Texto' },
  { value: '1', text: 'Numérico' },
  { value: '2', text: 'Fecha' },
  { value: '3', text: 'Booleano' },
];

onmount('#custom-reports-drag-drop', async function () { // cuando existe el id custom-reports-drag-drop se ejecuta lo del interior

  // Contenedores de chips
  const groupByDropContainer = '.group-by-drop-container';
  const columnsDropContainer = '.drop-container';
  const $self = $(this); // es el obj que contiene todo el html del custom report
  const { default: Sortable } = await import('sortablejs');

  $('#list-categories>.nav-item').on('error.ic', function (_evt, elt, _status, _str, _xhr) {
    $('#async_category_error').removeClass('d-none');
    $(elt[0].attributes['ic-target']?.value).html('');
  });

  // #list-categories .nav-link.active es la categoria acutal de la cual se seleccionan los atributos custom
  onmount('#list-categories .nav-link.active', async function () {
    Intercooler.triggerRequest(this.parentElement);
  });

  function sorteable() {
    Sortable.create(this, {
      group: { name: 'parameters', pull: 'clone' },
      animation: 150,
      sort: false,
      filter: '.disabled',
      ghostClass: 'disabled',
      onAdd: function (evt) {
        // al momento de devolver que se destruya
        evt.item.remove();
      },
    });
  }

  function iframeInstructions(dropContainer) {
    // cuenta aquellos li que tengan la clase badge, es decir, los chips dentro del container de columnas
    const childrenCount = dropContainer.find('li').find('.badge').length;
    const instructionsColumns = dropContainer.find('.instructions-columns');
    if (instructionsColumns.length) {
      // muestra instruction colums si childrenCount es cero, cuando el flag metricas_reportes_personalizados
      instructionsColumns.toggle(childrenCount === 0);
    }
  }

  // Método para remover chip y actualizar input order
  // Eliminar un chip usando X no lanza evento onRemove de su contenedor
  function removeChipOnClickEvent(event) {
    const dropContainer = $(event.target).closest(
      `${columnsDropContainer},
      ${groupByDropContainer}`
    );
    const chip = event.target.parentElement.parentElement;
    const orderValue = $(chip).find('input[name$="[order]"]').val();
    // Se elimina el chip para poder contar los indices correctamente según la cantidad de chips en el contenedor
    chip.remove();
    if (dropContainer.hasClass(groupByDropContainer.replace('.', ''))) {
      // si elimino el elemento del contenedor group by sobrescribo chips columnas desde la cantidad de chips en agrupar,
      // mismo comportamiendo de onRemove de group-by-drop-container
      const indexOffset = $self.find(groupByDropContainer).find('li').length;
      overWriteSecuentialOrder(columnsDropContainer, indexOffset);
      // Modificamos el order de los chip de agrupar desde order 0
      overWriteSecuentialOrder(groupByDropContainer, 0);
    }
    else {
      // si elimino un elemento del contenedor columnas sobrescribo los subsiguientes con -1, mismo comportamiendo de onRemove de drop-container
      dropContainer.find('input[name$="[order]"]')
        .filter(function () { return +$(this).val() > orderValue; })
        .each(function () { $(this).val(+$(this).val() - 1); });
    }
    if (dropContainer.length) {
      iframeInstructions(dropContainer);
    }
    changeVisibilityOfManageButton();
  }

  // Cambiamos el index sumando la cantidad de chips en agrupar dado que cada container comienza con index 0
  function fixDraggableIndex(draggableIndex) {
    const indexOffset = $self.find(groupByDropContainer).find('li').length;
    draggableIndex += indexOffset;
    return draggableIndex;
  }

  // Cambiamos los index desde un valor inicial secuencialmente
  // Si agregramos un chip a agrupar, los chips de columnas deben comenzar desde la cantidad de chips en agrupar
  // Si quitamos un chip de agrupar, los chips de columnas deben comenzar desde 0
  function overWriteSecuentialOrder(chipContainer, startValue) {
    $self.find(chipContainer).find('input[name$="[order]"]').filter(function () { return $(this).val() >= 0; })
      .each(function () {
        $(this).val(startValue);
        startValue++;
      });
  }

  // Modifica los index de los chip siguientes de un contenedor para mantener el order luego de agregar uno nuevo
  function overWriteNextIndexes(chipContainer, event) {
    $self.find(chipContainer).find('input[name$="[order]"]').filter(function () {
      return $(this).val() >= event.newDraggableIndex;
    }).each(function () { $(this).val(+$(this).val() + 1); });
  }

  // Construye html de chip y modal
  function buildChipModal(chipText) {
    return `<span class="cursor-pointer" data-toggle="modal" data-target="#group-by-modal-report">
      ${chipText}
    </span>`;
  }

  // Define el accionable de cerrar para crear el chip al agregarlo a un container
  const chipClose = `<span
                    data-toggle="close"
                    class="material-icons notranslate pl-1 cursor-default"
                    aria-label="Cerrar">
                      close
                  </span>`;

  // Orderna los chips según el valor del atributo order
  // Para el contenedor de agrupar, al hacer append aunque el valor del input order sea el correo,
  // el chip quedará al final por lo que se debe manejar manualmente
  function sortChipsByOrderValue(container) {
    $self.find(container).find('li').sort(function (a, b) {
      return $(a).find('input[name$="[order]"]').val() - $(b).find('input[name$="[order]"]').val();
    }).each(function (_, element) {
      $self.find(container).append(element);
    });
  }

  // Verifica si un chip existe en un container
  function chipExistInContainer(container, fieldName, groupName) {
    return $self.find(container)
      .find(`input[value="${fieldName}"]`)
      .closest('li')
      .find(`input[value="${groupName}"]`)
      .length;
  }

  // Construiye una notificación
  function buildNotify(notifyText) {
    notify.info(notifyText);
    setTimeout(() => {
      notify.clear();
    }, 3000);
  }

  // Regex para remover comillas, espacios y demás caracteres especiales.
  function cleanTextWithRegex(text) {
    return text.replace(/([ #;&,.+*~':"!^$[\]()=>|/@])/g, '\\$1');
  }

  // Función para modificar los index al mover los chips de posición
  function modifyIndexesOnUpdate(container, event) {
    if (event.newDraggableIndex > event.oldDraggableIndex) {
      $self.find(container).find('input[name$="[order]"]').filter(function () {
        return $(this).val() > event.oldDraggableIndex && $(this).val() <= event.newDraggableIndex;
      }).each(function () { $(this).val(+$(this).val() - 1); });
    }
    else {
      $self.find(container).find('input[name$="[order]"]').filter(function () {
        return $(this).val() >= event.newDraggableIndex && $(this).val() < event.oldDraggableIndex;
      }).each(function () { $(this).val(+$(this).val() + 1); });
    }
    $(event.item).find('input[name$="[order]"]').val(event.newDraggableIndex);
  }

  /**
   * Permite habilitat/deshabilitar el boton de guardar en base a una flag
   * @param {boolean} disable - determina si se debe desabilitar el boton de guardar, por defecto este será visible
   * @returns void
   */
  function changeStateOfSaveButton({ disable }) {
    const saveButton = $('button[name="report_action"]');
    saveButton.attr('disabled', disable);
  }

  /**
   * Permite habilitat/deshabilitar el boton de descargar en base a una flag
   * @param {boolean} disable - determina si se debe desabilitar el boton de guardar, por defecto este será visible
   * @returns void
   */
  function changeStateOfDownloadButton({ disable }) {
    const downloadButton = $('button[name="report_download"]') ? $('button[name="report_download"]')
      : $('button[name="button" [data-title="Descargar"]');
    downloadButton.attr('disabled', disable);
  }

  /**
   * Permite habilitat/deshabilitar el boton de previsualizar en base a una flag
   * @param {boolean} disable - determina si se debe desabilitar el boton , por defecto este será visible
   * @returns void
   */
  function changeStateOfPreviewButton({ disable }) {
    const previewButton = $('#preview-button');
    if (disable) {
      previewButton.addClass('disabled');
    }
    else {
      previewButton.removeClass('disabled');
    }
  }

  /**
   * Esta función se encarga de habilitar/deshabilitar los botones de guardar y descargar en un reporte personalizado
   * el criterió para deshabilitar o no los botones depende del container de columnas y de agrupación por columna
   * si ninguno de los dos posee elementos, se deshabilitan ambos botones, al contrario, si al menos uno posee elemntos
   * se habilitan ambos botones
   * @returns void
   */
  function changeVisibilityOfManageButton() {
    const dropContainer = $self.find(columnsDropContainer);
    const sizeOfDropContainer = dropContainer.find('li').find('.badge').length;

    const hasElement = sizeOfDropContainer > 0;

    changeStateOfSaveButton({ disable: !hasElement });
    changeStateOfDownloadButton({ disable: !hasElement });
  }

  changeVisibilityOfManageButton();

  const attr = $self.find('#list-categories>li')?.first()?.attr('ic-post-to');
  if (typeof attr !== 'undefined' && attr !== false) {
    // drag-container contiene los chips de los atributos a seleccionar para el informe
    onmount('.drag-container', sorteable);
  }
  else {
    $self.find('.drag-container').each(sorteable);
  }

  $self.find(columnsDropContainer).each(function () {
    const dropContainer = $self.find(columnsDropContainer);
    iframeInstructions(dropContainer);
    createSortableColumnsDropContainer(this, dropContainer);
  });

  function createSortableGroupByContainer(that, groupByContainer) {
    Sortable.create(that, {
      group: 'parameters',
      animation: 150,
      sort: true,
      onAdd: function (evt) {
        // evitamos que se dupliquen campos
        let fieldName = $(evt.item).find('input[name$="[field_name]"]').val();
        let groupName = $(evt.item).find('input[name$="[group_name]"]').val();

        $(evt.item).find('input[name$="[order]"]').val(evt.newDraggableIndex);
        overWriteSecuentialOrder(groupByContainer, 0);

        $(evt.item).find('input[name$="[group_by]"]').val(true);
        fieldName = cleanTextWithRegex(fieldName);
        groupName = cleanTextWithRegex(groupName);
        iframeInstructions(groupByContainer);

        //Verificamos si el campo agregado está en el container de columnas
        const countColumns = chipExistInContainer(columnsDropContainer, fieldName, groupName);
        if (countColumns !== 0) {
          buildNotify('El campo que intenta agregar ya existe en la lista de columnas', fieldName, groupName);
          evt.item.remove();
          return;
        }

        //Verificamos si el campo agregado ya está en el container de agrupar
        const count = chipExistInContainer(groupByDropContainer, fieldName, groupName);
        if (count !== 1) {
          buildNotify('El campo que intenta agregar ya existe en la lista de agrupar', fieldName, groupName);
          evt.item.remove();
          return;
        }

        // Modificamos el order de los chip de columnas desde la cantidad de chips en agrupar
        const indexOffset = $self.find(groupByDropContainer).find('li').length;
        overWriteSecuentialOrder(columnsDropContainer, indexOffset);

        const showCategoriesInFields = $('input#show_categories_in_fields').val() === 'true';
        if (showCategoriesInFields) {
          // Añadimos el nombre del grupo del campos al soltarlo en la zona de dropdowns
          const translatedGroup = $(evt.item).find('input[name="group_translated"]').val();
          const translatedFieldName = $(evt.item).find('input[name="field_name_translated"]').val();

          // construimos el ícono de tipo
          const chipIconType = buildIconType(evt.item);

          // Se construye chip + modal
          const chipModal = buildChipModal(`${translatedGroup} - ${translatedFieldName}`);
          $(evt.item).find('.badge').html(`${chipIconType} ${chipModal} ${chipClose}`);

          $(evt.item).removeClass('li-item-name');
          //Verificamos si existe group-by-multiple
          const groupByMultiple = '.group-by-multiple';
          if (!$(groupByMultiple).length) {
            // eliminamos elemento anterior que pueda existir y agregamos el nuevo
            $(groupByDropContainer).find('li').remove();
          }
          $(groupByDropContainer).append(evt.item);

          // Reordenamos los chips después del append
          sortChipsByOrderValue(groupByDropContainer);
        }
        else {
          const translatedFieldName = $(evt.item).find('input[name="field_name_translated"]').val();
          // construimos el ícono de tipo
          const chipIconType = buildIconType(evt.item);

          // Se construye chip + modal
          const chipModal = buildChipModal(translatedFieldName);
          $(evt.item).find('.badge').html(`${chipIconType} ${chipModal} ${chipClose}`);
          $(evt.item).removeClass('li-item-name');
          //Verificamos si existe group-by-multiple
          const groupByMultiple = '.group-by-multiple';
          if (!$(groupByMultiple).length) {
            // eliminamos elemento anterior que pueda existir y agregamos el nuevo
            $(groupByDropContainer).find('li').remove();
          }
          $(groupByDropContainer).append(evt.item);

          // Reordenamos los chips después del append
          sortChipsByOrderValue(groupByDropContainer);
        }
      },
      onRemove: function () {
        // Modificamos el order de los chip de columnas desde la cantidad de chips en agrupar
        const indexOffset = $self.find(groupByDropContainer).find('li').length;
        overWriteSecuentialOrder(columnsDropContainer, indexOffset);
        // Modificamos el order de los chip de agrupar desde order 0
        overWriteSecuentialOrder(groupByDropContainer, 0);
        iframeInstructions(groupByContainer);
      },
      onChange: function (_evt) {
        changeVisibilityOfManageButton();
      },
      onMove: function (_evt) {
        changeVisibilityOfManageButton();
      },
      onUpdate: function (evt) {
        modifyIndexesOnUpdate(groupByDropContainer, evt);
      },
    });
  }

  $self.find(groupByDropContainer).each(function () {
    const groupByContainer = $self.find(groupByDropContainer);
    iframeInstructions(groupByContainer);
    createSortableGroupByContainer(this, groupByContainer);
  });

  function createSortableColumnsDropContainer(that, dropContainer) {
    Sortable.create(that, {
      group: 'parameters',
      animation: 150,
      sort: true,
      onAdd: function (evt) {
        addClickEventToChip(evt.item);
        evt.newDraggableIndex = fixDraggableIndex(evt.newDraggableIndex);
        // evitamos que se dupliquen campos
        let fieldName = $(evt.item).find('input[name$="[field_name]"]').val();
        let groupName = $(evt.item).find('input[name$="[group_name]"]').val();
        $(evt.item).find('input[name$="[group_by]"]').val(false);
        fieldName = cleanTextWithRegex(fieldName);
        groupName = cleanTextWithRegex(groupName);
        //Verifica instruccion al iframe
        iframeInstructions(dropContainer);
        const count = $self.find(columnsDropContainer)
          .find(`input[value="${fieldName}"]`)
          .closest('li')
          .find(`input[value="${groupName}"]`)
          .length;
        if (count !== 1) {
          buildNotify('El campo que intenta agregar ya existe en la lista de columnas', fieldName, groupName);
          evt.item.remove();
          return;
        }

        //Verificamos si el campo agregado está en el container de agrupación
        const countGroupBy = chipExistInContainer(groupByDropContainer, fieldName, groupName);
        if (countGroupBy !== 0) {
          buildNotify('El campo que intenta agregar ya existe en la lista de agrupar', fieldName, groupName);
          evt.item.remove();
          return;
        }

        overWriteNextIndexes(columnsDropContainer, evt);
        $(evt.item).find('input[name$="[order]"]').val(evt.newDraggableIndex);

        const showCategoriesInFields = $('input#show_categories_in_fields').val() === 'true';
        if (showCategoriesInFields) {
          // Añadimos el nombre del grupo del campos al soltarlo en la zona de dropdowns
          const translatedGroup = $(evt.item).find('input[name="group_translated"]').val();
          let translatedFieldName = $(evt.item).find('.badge').html();

          $(evt.item).removeClass('li-item-name');

          // construimos el ícono de tipo
          const chipIconType = buildIconType(evt.item);

          //Si existe modal en la página
          if ($('#group-by-modal-report').length) {
            translatedFieldName = $(evt.item).find('input[name="field_name_translated"]').val();
            const chipModal = buildChipModal(`${translatedGroup} - ${translatedFieldName}`);
            $(evt.item).find('.badge').html(`${chipIconType} ${chipModal} ${chipClose}`);
          }
          else {
            $(evt.item).find('.badge').html(`${translatedGroup} - ${translatedFieldName} ${chipClose}`);
          }

        }
        else {
          let translatedFieldName = $(evt.item).find('.badge').html();
          $(evt.item).removeClass('li-item-name');
          // construimos el ícono de tipo
          const chipIconType = buildIconType(evt.item);
          //Si existe modal en la página
          if ($('#group-by-modal-report').length) {
            translatedFieldName = $(evt.item).find('input[name="field_name_translated"]').val();
            const chipModal = buildChipModal(translatedFieldName);
            $(evt.item).find('.badge').html(`${chipIconType} ${chipModal} ${chipClose}`);
          }
          else {
            $(evt.item).find('.badge').html(`${translatedFieldName} ${chipClose}`);
          }
        }

        if (dropContainer.find('li').find('.badge').length > 0) {
          changeStateOfPreviewButton({ disable: false });
        }
      },
      onRemove: function (evt) {
        const container = $(groupByDropContainer);
        const hasContainer = container.length > 0;
        const groupByContainerBadgesHtml = [];
        container.find('li').find('.badge').each(function () {
          groupByContainerBadgesHtml.push($(this).text());
        });
        const eventBadgeHtml = $(evt.item).find('.badge').text();
        // Si existe el contenedor de agrupar y el chip fue movido desde columnas a agrupar dejamos
        // la reponsabilidad de corregir los index al evento onAdd del contenedor agrupar
        if (!(hasContainer && groupByContainerBadgesHtml.includes(eventBadgeHtml))) {
          $self.find(columnsDropContainer).find('input[name$="[order]"]')
            .filter(function () { return $(this).val() > evt.oldDraggableIndex; })
            .each(function () { $(this).val(+$(this).val() - 1); });
        }
        //Verifica instruccion al iframe
        iframeInstructions(dropContainer);
        if (dropContainer.find('li').find('.badge').length === 0) {
          changeStateOfPreviewButton({ disable: true });
        }
      },
      onChange: function (_evt) {
        changeVisibilityOfManageButton();
      },
      onMove: function (_evt) {
        changeVisibilityOfManageButton();
      },
      onUpdate: function (evt) {
        evt.newDraggableIndex = fixDraggableIndex(evt.newDraggableIndex);
        evt.oldDraggableIndex = fixDraggableIndex(evt.oldDraggableIndex);
        modifyIndexesOnUpdate(columnsDropContainer, evt);
      },
    });
  }

  onmount(columnsDropContainer, async function () {
    $self.find(columnsDropContainer).each(function () {
      const dropContainer = $self.find(columnsDropContainer);
      iframeInstructions(dropContainer);
      changeVisibilityOfManageButton();
      changeStateOfPreviewButton({ disable: dropContainer.find('li').length === 0 });
      createSortableColumnsDropContainer(this, dropContainer);
    });
  });

  function addClickEventToChip(node) {
    node.addEventListener('click', function (event) {
      if (event.target.getAttribute('data-toggle') === 'close') {
        removeChipOnClickEvent(event);
        if ($self.find(columnsDropContainer).find('li').length === 0) {
          changeStateOfPreviewButton({ disable: true });
        }
        //Actualiza contador de variables seleccionadas
        $('#count-selected-variables').text($('#ul-selected-variables-id li').length);
      }
      if (event.target.getAttribute('data-toggle') === 'modal') {
        var selectElementOrderBy = $('#group-by-modal-report .modal-content .modal-body #select_ondenar_por');
        var selectElementGroupBy = $('#group-by-modal-report .modal-content .modal-body #select_agrupar_por');

        // Guardar la id del chip en el elemento hidden del modal
        const chipId = $(event.target).parent().parent().find('input[name$="[id_modal]"]').val();
        $('#chip_id_hidden_field').val(chipId);

        // Vaciar el select antes de añadir las nuevas opciones
        selectElementOrderBy.empty();
        selectElementGroupBy.empty();
        // Añadir opciones al select de orderBy
        orderByOptions.forEach(function (option) {
          var optionElement = $('<option></option>')
            .attr('value', option.value)
            .text(option.text);
          selectElementOrderBy.append(optionElement);
        });
        const orderBy = $(event.target).parent().parent().find('input[name$="[order_by]"]').val();
        // Establecer la opción 'Sin Ordenar' como seleccionada por defecto
        selectElementOrderBy.val(orderBy);

        //Muestro el "select de agrupar por" solo si no esta en el campo de "agrupar por"
        var groupBy = $(event.target).parent().parent().find('input[name$="[group_by]"]').val() || 'false';

        //Ver si fue agregado un parametro al campo "agrupar por"
        const groupByHasChildren = $self.find(groupByDropContainer).find('li').length > 0;

        if (groupBy === 'true' || !groupByHasChildren) {
          selectElementGroupBy.parent().hide();
        }
        else if (groupBy === 'false') {
          selectElementGroupBy.parent().show();
          // Depende si el tipo de dato es numerico o string, date, boolean
          // 0 -> numeric
          // 1 -> string
          // 2 -> date
          // 3 -> boolean
          const fieldType = $(event.target).parent().parent().find('input[name$="[field_type]"]').val();
          let optionsGroupBy = metricOptionsForStringDateBoolean;
          switch (fieldType) {
            case '1':
              optionsGroupBy = metricOptionsForNumeric;
              break;
            default:
              optionsGroupBy = metricOptionsForStringDateBoolean;
              break;
          }

          // Añadir opciones al select de groupBy
          optionsGroupBy.forEach(function (option) {
            var optionElement = $('<option></option>')
              .attr('value', option.value)
              .text(option.text);
            selectElementGroupBy.append(optionElement);
          });
          const fieldMetric = $(event.target).parent().parent().find('input[name$="[field_metric]"]').val();
          // Establecer metrica seleccionada por defecto
          selectElementGroupBy.val(fieldMetric);
        }
      }
    });
  }

  document.querySelectorAll('.li-selected-variables').forEach(function (node) {
    addClickEventToChip(node);
  });

  //Guardar seleccion de modal
  $('[id*="confirm-modal-report"]').on('click', function () {
    var selectOrderBy = $('#group-by-modal-report .modal-content .modal-body #select_ondenar_por');
    var selectGroupBy = $('#group-by-modal-report .modal-content .modal-body #select_agrupar_por');
    var isGroupBySelectHidden = selectGroupBy.is(':hidden');

    //Elemento escondido
    var hiddenValue = $('#chip_id_hidden_field').val();

    //Busca los elementos con la id y les asigna el valor
    var parentElement = $('.drop-container');

    //Guardo el valor seleccionado de order_by
    var selectorOrderBy = 'input[name*="[' + hiddenValue + ']"][name$="[order_by]"]';
    var lastOrderedElement = parentElement.find(selectorOrderBy).val(selectOrderBy.val());
    //Guardo el valor seleccionado de field_metric
    if (!isGroupBySelectHidden) {
      parentElement.find('input[name*="[' + hiddenValue + ']"][name$="[field_metric]"]').val(selectGroupBy.val());
    }

    //Obtiene los valores de campos columnas y agrupar_por para mantener el último order_by seleccionado
    const groupByDropContainerValue = $self.find(groupByDropContainer).find('input[name$="[order_by]"]');
    const dropContainerValue = $self.find(columnsDropContainer).find('input[name$="[order_by]"]');
    const concatenatedValues = groupByDropContainerValue.add(dropContainerValue);
    concatenatedValues.each(function () {
      if (!$(this).is(lastOrderedElement)) {
        $(this).removeAttr('value');
      }
    });

    // Cierra el modal
    $('#group-by-modal-report').modal('hide');
  });
});

onmount('#layout-selected-variables-id', async function () {
  const { default: Sortable } = await import('sortablejs');

  // Cambiamos el index sumando la cantidad de chips en agrupar dado que cada container comienza con index 0
  function fixDraggableIndex(draggableIndex) {
    const indexOffset = $('ul-selected-variables-id').find('li').length;
    draggableIndex += indexOffset;
    return draggableIndex;
  }

  // Función para modificar los index al mover los chips de posición
  function modifyIndexesOnUpdate(container, event) {
    if (event.newDraggableIndex > event.oldDraggableIndex) {
      $(container).find('input[name$="[order]"]').filter(function () {
        return $(this).val() > event.oldDraggableIndex && $(this).val() <= event.newDraggableIndex;
      }).each(function () { $(this).val(+$(this).val() - 1); });
    }
    else {
      $(container).find('input[name$="[order]"]').filter(function () {
        return $(this).val() >= event.newDraggableIndex && $(this).val() < event.oldDraggableIndex;
      }).each(function () { $(this).val(+$(this).val() + 1); });
    }
    $(event.item).find('input[name$="[order]"]').val(event.newDraggableIndex);
  }

  // Método para remover chip y actualizar input order
  // Eliminar un chip usando X no lanza evento onRemove de su contenedor
  function removeChipOnClickEvent(event) {
    const chip = event.target.closest('li');
    const dropContainer = $(chip).closest('.selected-variables-drop-container');

    const fieldName = $(chip).find('input[name$="[field_name]"]').val();
    $(chip).find('input[name$="[to_remove]"]').val(true);
    chip.style.display = 'none';

    const orderValue = $(chip).find('input[name$="[order]"]').val();

    if (fieldName) {
      $(`.id_chip_${fieldName}`).removeClass('badge-info').addClass('badge-default');
    }
    dropContainer.find('input[name$="[order]"]')
      .filter(function () { return +$(this).val() > orderValue; })
      .each(function () { $(this).val(+$(this).val() - 1); });
  }

  // Agregamos los atributos de intercooler para que el botón de cerrar
  // modal haga lo mismo que el botón de cancelar
  $('#select-data-button .modal-header').find('.close')
    .attr('ic-post-to', $('#cancel-select-data-button').attr('ic-post-to'))
    .attr('ic-target', $('#cancel-select-data-button').attr('ic-target'))
    .attr('ic-trigger-on', $('#cancel-select-data-button').attr('ic-trigger-on'))
    .attr('ic-on-beforesend', $('#cancel-select-data-button').attr('ic-on-beforesend'));

  // reemplazamos el evento al presionar la tecla ESC
  document.querySelector('#select-data-button').addEventListener('keydown', function (event) {
    event.stopPropagation();
    if (event.key === 'Escape') {
      $('#select-data-button').find('.close')[0].click();
    }
  });

  document.querySelector('[id*="layout-selected-variables-id"]').addEventListener('click', function (event) {
    event.stopPropagation();
    if (event.target.getAttribute('data-toggle') === 'close') {
      removeChipOnClickEvent(event);

      const groupByModalValue = $('#ul-selected-variables-id').attr('data-group-by');

      const li = $('#ul-selected-variables-id li').filter(function (index) {
        const item = $('#ul-selected-variables-id li')[index];
        const toRemove = $(item).find('input[name$="[to_remove]')?.val();
        const groupBy = $(item).find('input[name$="[group_by]')?.val();
        return toRemove !== 'true' && groupBy === groupByModalValue;
      });
      $('#count-selected-variables').text(li.length);
    }
  });

  document.querySelector('#select-data-button').addEventListener('click', function (event) {
    if (event.target.matches('#select-data-button')) {
      $('#select-data-button').find('.close')[0].click();
    }
  });

  let dropContainer = $('.selected-variables-drop-container');
  if (dropContainer.length > 1) {
    // raise error
    throw new Error('There are more than one selected-variables-drop-container');
  }
  dropContainer = dropContainer[0];
  Sortable.create(dropContainer, {
    group: 'parameters',
    animation: 150,
    sort: true,
    handle: '.handle',
    scroll: true,
    scrollSensitivity: 50,
    scrollSpeed: 50,
    bubbleScroll: true,
    filter: '.blocked',
    onUpdate: function (evt) {
      evt.newDraggableIndex = fixDraggableIndex(evt.newDraggableIndex);
      evt.oldDraggableIndex = fixDraggableIndex(evt.oldDraggableIndex);
      modifyIndexesOnUpdate('.selected-variables-drop-container', evt);
    },
    onMove: function (evt) {
      // Prevent items from being placed before fixed first item
      if (evt.related.classList.contains('blocked')) {
        return false;
      }
      return true;
    }
  });
});

let hoverTimeout = null;
onmount('[data-button-download-enable]', async function () {
  function validateRequiredInputs() {
    const $requiredElements = $('[required]');
    let isValid = true;
    $requiredElements.each(function () {
      const $element = $(this);
      if ($element.is('select')) {
        const value = $element.val();
        if (value.length <= 0) { isValid = false; }
      }
      else if ($element.is(':checkbox, :radio')) {
        if (!$element.is(':checked')) { isValid = false; }
      }
      else if ($element.is('period-picker')) {
        const value = $element.find('input').val();
        if (!value) { isValid = false; }
      }
      else{
        const value = $element.val();
        if (!value) { isValid = false; }
      }
    });
    return isValid;
  }

  function updateDownloadButtonState() {
    const downloadButton = $('button[data-button-download-enable=true]');
    validateRequiredInputs() ? downloadButton.removeClass('disabled') : downloadButton.addClass('disabled');
  }

  updateDownloadButtonState();
  $(document).on('change', function () {
    updateDownloadButtonState();
  });
});

$(document).on('mouseenter', '.item-parameter', function () {

  clearTimeout(hoverTimeout);
  // Ocultar el párrafo instructivo
  hoverTimeout = setTimeout(() => {
    $('.layout-description-parameter .tooltip-message').addClass('hidden');
    // Almacenamos los valores en constantes para evitar múltiples búsquedas en el DOM
    const $self = $(this);
    const fieldTypeValue = $self.find('input[name="field_type"], input[name$="[field_type]"]').val();
    const fieldDescription = $self.find('input[name="field_description"], input[name$="[field_description]"]').val();
    const fieldDataSample = $self.find('input[name="field_data_sample"], input[name$="[field_data_sample]"]').val();

    const fieldType = (dataType.find(item => item.value === fieldTypeValue) || { text: 'Tipo de dato desconocido' });
    // Construcción de la interfaz de tipo
    const chipIconType = buildIconType($self);
    const chipHTML = buildChipHtml(chipIconType, fieldType);

    // Actualización de las descripciones de la UI
    $('.type-description').html(chipHTML);
    $('.description-description').html(`<p>${fieldDescription}</p>`);
    $('.example-data-description').html(`<p>${fieldDataSample}</p>`);
    // Mostrar el contenido oculto
    $('.layout-description-parameter .description-parameter-container').removeClass('hidden');
  }, 250);
});

$(document).on('mouseleave', '.item-parameter', function () {
  clearTimeout(hoverTimeout);
  hoverTimeout = null;
  $('.layout-description-parameter .description-parameter-container').addClass('hidden');
  $('.layout-description-parameter .tooltip-message').removeClass('hidden');
});
