/** @file
  * Permite agregar el evento `Generate_All_Process_Files cuando se selecciona la opción
  * al procesar.`
  */

import onmount, { $ } from 'onmount';

onmount('new_variable_process_form', async () => {
  $('#variable_process_form_generar_archivos').on('change', (event) => {
    const { checked } = event.target;
    const submitButton = $('#confirmar_procesar');
    if (checked) {
      const amplitudeData = '{"message":"Generate_All_Process_Files","event":"click","multi-trigger":false}';
      submitButton.attr('data-amplitude-tracking', amplitudeData);
    }
    else {
      submitButton.removeAttr('data-amplitude-tracking');
    }
  });
});
