/** @file Componente JS encargado de cargar el editor de texto de Buk en el contenedor indicado.
 *
 * El editor de texto de fondo es Froala, por lo que si en algún momento se decide cambiar de proveedor
 * de editor de texto, todos los cambios relacionados a cargar el editor se deben realizar en este componente.
 *
 * Para realizar la carga del editor, el componente realiza un onmount de la clase 'froala_text_editor' que se
 * encuentra definida en el custom input 'app/lib/text_editor_input.rb'. Esto significa que si el custom input
 * es llamado en una vista de la plataforma, internamente cargará el editor a través de este componente.
 *
 * Los toolbars que se configuren en este componente son los que tendrá la plataforma disponibles para usarlos.
 * Esto quiere decir que si se necesita agregar un nuevo toolbar para el editor, se lo debe agregar en este
 * componente.
 *
 * Toolbars disponibles:
 *  1. rich: toolbar completo, es decir que tiene todos los botones del editor.
 *  2. basic: toolbar con solo opciones para fuente.
 *  3. simple: toolbar con solo opciones para fuente, imágenes y tablas.
*/
import onmount from 'onmount';

onmount('.froala_text_editor', async function () {
  const container = $(this);
  if (container.length === 0) return;

  const { loadPlugins, loadLanguage, loadStyles, TOOLBAR } = await import('./custom_configs');

  const toolbar = TOOLBAR[container.attr('toolbar')];
  if (toolbar.length === 0) return; // Permitimos solamente los toolbars disponibles

  const isDocument = (container.attr('is_document') === 'true');

  const froalaKey = container.attr('froala_key');

  const element = container.get(0);
  const language = container.attr('language');

  await loadStyles();
  await loadPlugins();
  await loadLanguage(language);

  element.editor = await loadFroala(element, isDocument, toolbar, froalaKey, language);
});

async function loadFroala(element, isDocument, toolbar, froalaKey, language) {
  const { default: FroalaEditor } = await import('froala-editor');

  return new FroalaEditor(element, {
    key: froalaKey,
    attribution: false,
    events: {
      'html.set': function () {
        // Hack para hacer que Froala lea bien los estilos de las celdas de una tabla
        this.$el.find('table').each(function () {
          var currentWidth = this.style.width;
          // Verificar si el width está puesto al 100% en el atributo style de la tabla
          if (currentWidth && currentWidth.includes('100%')) {
            this.style.width = ''; // Eliminar el atributo width
          }
        });
      },
    },
    toolbarSticky: true,
    wordCounterCount: false,
    charCounterCount: false,
    placeholderText: '',
    documentReady: isDocument,
    toolbarButtons: toolbar,
    zIndex: 1050,
    language: language,
  });
}
