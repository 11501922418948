/** @file TODO: documentar */
import { each, debounce } from 'lodash-es';
import onmount from 'onmount';
import { isInDom } from '../lib/interaction-functions';
import summaryTable from './summary_table';
import { escapeName } from '../lib/string.js';

// Permite identificar y ordenar columnas con fechas
// que utilicen el formato de buk https://bukhr.github.io/bux-design-system/docs/overview
// referencia: https://datatables.net/blog/2014-12-18
(function initUltimateDateTimeSortingAlgorithm() {
  const dateRegexLong = /^(3[0-1]|[0-2][0-9])-(0[1-9]|1[0-2])-(\d\d\d\d)$/;
  const dateRegexMedium = /^(0[1-9]|1[0-2])-(\d\d\d\d)$/;
  const dateRegexPeriod = /^([1-2])° (0[1-9]|1[0-2])-(\d\d\d\d)$/;

  function dateRegexp(date, format) {
    if (format === 'ddmmyyyy') {
      return String(date).trim().match(dateRegexLong);
    }
    else if (format === 'mmyyyy') {
      return String(date).trim().match(dateRegexMedium);
    }
    else if (format === 'pmmyyyy') {
      return String(date).trim().match(dateRegexPeriod);
    }
    else {
      return null;
    }
  }

  function isValidDate(date, format) {
    return dateRegexp(date, format) !== null;
  }

  // Dada una fecha en formato dd-mm-yyyy, mm-yyyy o periodo° mm-yyyy
  // retorna una fecha en formato yyyymmdd, yyyymm o yyyymm respectivamente
  function dateToStamp(date, format) {
    const regex = dateRegexp(date, format);
    if (format === 'ddmmyyyy') {
      return `${regex[3]}${regex[2]}${regex[1]}`;
    }
    else if (format === 'mmyyyy') {
      return `${regex[2]}${regex[1]}`;
    }
    else if (format === 'pmmyyyy') {
      return `${regex[3]}${regex[2]}${regex[1]}`;
    }
  }

  function stripHtml(text) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = text;
    return tmp.textContent || tmp.innerText || '';
  }

  $.fn.dataTable.ext.type.detect.unshift(function (date) {
    if (isValidDate(stripHtml(date), 'ddmmyyyy')) {
      return 'ddmmyyyy';
    }
    else if (isValidDate(stripHtml(date), 'mmyyyy')) {
      return 'mmyyyy';
    }
    else if (isValidDate(stripHtml(date), 'pmmyyyy')) {
      return 'pmmyyyy';
    }
    else {
      return null;
    }
  });

  $.fn.dataTable.ext.type.order['ddmmyyyy-pre'] = function (date) {
    return dateToStamp(stripHtml(date), 'ddmmyyyy');
  };

  $.fn.dataTable.ext.type.order['mmyyyy-pre'] = function (date) {
    return dateToStamp(stripHtml(date), 'mmyyyy');
  };

  $.fn.dataTable.ext.type.order['pmmyyyy-pre'] = function (date) {
    return dateToStamp(stripHtml(date), 'pmmyyyy');
  };

})();

$.extend($.fn.DataTable.defaults, {
  retrieve: true,
  order: [0, 'asc'],
  iDisplayLength: 25,
  iStateDuration: -1,
  lengthMenu: [10, 25, 100, 500, 1500],
  deferRender: true,
  language: {
    'decimal': ',',
    'thousands': '.',
    'sProcessing': window.i18nDatatable?.sProcessing || 'Procesando...',
    'sLengthMenu': window.i18nDatatable?.sLengthMenu || 'Mostrar _MENU_ registros',
    'sZeroRecords': window.i18nDatatable?.sZeroRecords || 'No se encontraron resultados',
    'sEmptyTable': window.i18nDatatable?.sEmptyTable || 'Ningún dato disponible en esta tabla',
    'sInfo': window.i18nDatatable?.sInfo || 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
    'sInfoEmpty': window.i18nDatatable?.sInfoEmpty || 'Mostrando registros del 0 al 0 de un total de 0 registros',
    'sInfoFiltered': window.i18nDatatable?.sInfoFiltered || '(filtrado de un total de _MAX_ registros)',
    'sInfoPostFix': '',
    'sSearch': window.i18nDatatable?.sSearch || 'Buscar:',
    'sUrl': '',
    'sInfoThousands': ',',
    'sLoadingRecords': window.i18nDatatable?.sLoadingRecords || 'Cargando...',
    'oPaginate': {
      'sFirst': window.i18nDatatable?.sFirst || 'Primero',
      'sLast': window.i18nDatatable?.sLast || 'Último',
      'sNext': window.i18nDatatable?.sNext || 'Siguiente',
      'sPrevious': window.i18nDatatable?.sPrevious || 'Anterior',
    },
    'oAria': {
      'sSortAscending':
        window.i18nDatatable?.sSortAscending || ': Activar para ordenar la columna de manera ascendente',
      'sSortDescending': window.i18nDatatable?.sSortDescending
        || ': Activar para ordenar la columna de manera descendente',
    },
  },
});

function perColumnSearch($table, api) {
  const DelaySearchColumns = 300;
  api.columns().every(function () {
    const column = this;
    const search = column.search();
    const name = $(column.header()).data('name');
    if (!name) {
      return;
    }
    const target = $table.find(':input[data-filter="' + escapeName(name) + '"]');
    if (search && target.length) {
      target.val(search);
    }
  });
  $table.find(':input[data-filter]').on('keyup change blur', debounce(function () {
    let column = api.column($(this).data('filter') + ':name');
    column = column.length ? column : api.column($(this).parent('td').index());
    const dataRegex = !!this.getAttribute('data-regex-filter');
    const smartSearch = !dataRegex;
    const dataCaseInSensitive = !this.getAttribute('data-case-sensitive-filter');

    if (column.search() !== this.value) {
      column.search(this.value, dataRegex, smartSearch, dataCaseInSensitive).draw();
    }
  }, DelaySearchColumns));
}

$.fn.dataTable.ext.search.push(function (settings, _data, dataIndex) {
  const datatable = new $.fn.dataTable.Api(settings.nTable);
  const row = datatable.row(dataIndex).node();
  if($(settings.nTable).data('canSignToggle')) {
    return !!$(row).find('*[data-can-sign="true"]').length;
  }
  else {
    return true;
  }
});

$.fn.dataTable.ext.type.search.string = function (data) {
  // eslint-disable-next-line no-negated-condition
  return ! data ?
    '' :
    typeof data === 'string' ?
      data
        .replace(/\n/g, ' ')
        .replace(/[áàäâ]/g, 'a')
        .replace(/[éèëê]/g, 'e')
        .replace(/[íìïî]/g, 'i')
        .replace(/[óòöô]/g, 'o')
        .replace(/[úùüû]/g, 'u') :
      data;
};

$.fn.dataTable.ext.search.push(function (settings, data, dataIndex, row) {
  if($(settings.nTable).data('searchSelect2')) {
    const datatable = settings.oInstance.api();
    /*En caso de que se filtre un select, se debe realizar por la opcion seleccionada*/
    row.forEach((_column, columnIndex) => {
      var select = [];
      var seleccionado = '';
      select = datatable.cell(dataIndex, columnIndex).node().querySelector('select');
      if(select) {
        seleccionado = select.selectedOptions[0].text;
        if(seleccionado !== '') {
          data[columnIndex] = seleccionado;
        }
      }
    });
  }
  return true;
});

function canSignOnly($table, datatable) {
  $table.on('filterSignOnly', function () {
    $table.data('canSignToggle', !$table.data('canSignToggle'));
    datatable.draw();
  });
}

function exportable(table, columnsExcluded, tableSettings = null) {

  const columns = (tableSettings || $(this).DataTable.settings[0]).aoColumns;
  const columnNames = columns.map(column => column.name);
  const columnsIndexesExcluded = columnsExcluded.map(column => columnNames.indexOf(column));
  const exportButton = {
    exportOptions: {
      format: {
        body: function (data, _row, _column, _node) {
          // si hay algun elemento con la clase .do-not-export lo sacamos
          const dataSelector = $($.parseHTML(data)).not('.do-not-export');
          const input = dataSelector.find('input');

          if (input.length > 0) {
            return input.val().trim();
          }

          if (dataSelector.length > 0) {
            if(dataSelector.first().hasClass('employee-name-preview')) {
              var name = dataSelector.first().text().trim();
              var code = dataSelector.find('.status-code').text().trim();
              return code.length > 0 ? `${name} - ${code}` : name;
            }
            else {
              return dataSelector.text().trim();
            }
          }

          return data;
        },
      },
      columns: Array.from(columns.keys()).filter(column => !columnsIndexesExcluded.includes(column)),
    },
  };

  new $.fn.dataTable.Buttons(table, {
    buttons: [
      $.extend(true, {}, exportButton, {
        extend: 'csvHtml5',
      }),
      $.extend(true, {}, exportButton, {
        extend: 'excelHtml5',
      }),
    ],
  });

  table.buttons().container()
    .appendTo(table.table().container());
}

function reporte(table, columnsExcluded, reportTitle = null, textButton = 'Generar reporte') {
  const dataTableSettings = $(this).dataTableSettings;
  const columns = (dataTableSettings[dataTableSettings.length - 1]).aoColumns;
  const columnNames = columns.map(column => column.name);
  const columnsIndexesExcluded = columnsExcluded.map(column => columnNames.indexOf(column));

  const exportButton = {
    exportOptions: {
      format: {
        body: function (data, _row, _column, _node) {
          const dataSelector = $($.parseHTML(data));
          const input = dataSelector.find('input');

          if (input.length > 0) {
            return input.val().trim();
          }

          if (dataSelector.length > 0) {
            return dataSelector.text().trim();
          }

          return data;
        },
      },
      columns: Array.from(columns.keys()).filter(column => !columnsIndexesExcluded.includes(column)),
    },
  };

  new $.fn.dataTable.Buttons(table, {
    buttons: [
      $.extend(true, {}, exportButton, {
        extend: 'excelHtml5',
        text: `<span>${textButton} </span><i class="fa fa-download"></i>`,
        ...(reportTitle ? { title: reportTitle } : {}),
      }),
    ],
  });

  table.buttons().container()
    .insertBefore(table.table().container());

  table.buttons().container().addClass('float-right');
}

/**
 * Proxy form permite hacer submit de todos los inputs de un formulario que son
 * escondidos por la paginación de datatables.
 *
 * Pasando el parámetro `data-proxy-form="true"`, se agregarán los inputs al formulario
 * que contiene la tabla. En caso de que la tabla no esté dentro del formulario, se puede
 * indicar `data-proxy-form="id"`, indicando el id del formulario a utilizar.
 */
function proxyForm(table) {
  const target = table.data('proxy-form');
  let form;
  if (target === true) {
    form = table.closest('form');
  }
  else {
    form = $(`#${target}`);
  }
  const inputContainer = $('<fieldset>');
  form.append(inputContainer);

  form.on('submit', function () {
    let params = table.$('input, select').serializeArray();

    // en el caso de que la tabla esté dentro del formulario, quitamos los campos que se estan mostrando
    // en la pagina actual de la tabla para evitar que se dupliquen
    if (form.has(table).length > 0) {
      params = params.filter(function (input) {
        if (input.value === '') return false;
        return $(`table#${table[0].id} tbody tr td input[name='${input.name}'][value='${input.value}']`).length === 0;
      });
    }

    inputContainer.empty();

    $.each(params, function () {
      inputContainer.append(
        $('<input>')
          .attr('type', 'hidden')
          .attr('name', this.name)
          .val(this.value)
      );
    });
  });
}

$.fn.dataTable.ext.errMode = function (settings, _technote, _message) {
  const tableId = '#' + settings.sTableId;
  const numberOfChildren = $(tableId + ' thead').children('tr').children('td').length;
  const errorMessage = {
    500: 'Excedio el tiempo de espera.',
    422: 'No es posible la solicitud.',
  };
  // eslint-disable-next-line max-len
  var newRowContent = `
  <td class="dataTables_empty" valign="top" colspan= ${numberOfChildren}>
    <div class="alert alert_danger text-left alert-dismissible fade show alert__scroll" role="alert">
      <div class="alert_container d-flex">
        <div class="alert_container__icon pr-0">
          <span class=" material-icons " aria-hidden="true">close</span>
        </div>
        <div class="alert_container__content pl-0 ">
          <h4 class="alert_container__title">Peligro.</h4>
          ${errorMessage[settings.jqXHR.status]}
        </div>
      </div>
    </div>
  </td>
`;
  $(tableId + ' tbody').replaceWith(newRowContent);
  $('.dataTables_processing').hide();
};

/** Crea listeners para cargar remotamente  */
function setupAjaxChild(datatable, $table) {
  $table.on('click', '[data-ajax-child-row]', function () {
    const url = $(this).data('ajaxChildRow');
    if (!url) {
      return;
    }
    const tr = $(this).closest('tr');
    const row = datatable.row(tr);
    if (row.child.isShown()) {
      row.child.hide();
    }
    else {
      $.get(url).then(content => {
        row.child(content).show();
      });
    }
  });
}

onmount('[data-datatable]',
  function (obj) {
    if ($(this).parents('.dataTables_scroll').length) {
      return false;
    }

    var $table = $(this),
      data = $table.data(),
      datatable;
    if (typeof(data.stateSave) === 'undefined' && this.id) {
      data.stateSave = true;
    }

    if ($table.find('thead').length === 0) {
      return;
    }

    if ($table.attr('data-summary')) {
      data.footerCallback = summaryTable;
    }

    if ($table.attr('data-fixed-right')) {
      data['scrollX'] = true;
      data['scrollCollapse'] = true;
    }

    datatable = $table.DataTable(data);
    $table.data('DataTable', datatable);

    if ($table.data('exportable')) {
      // Se toma la configuración de la tabla actual
      const tableSettings = $table.dataTable().fnSettings();
      exportable(datatable, $table.data('columns-excluded-in-export'), tableSettings);
    }

    if ($table.data('reporte')) {
      reporte(
        datatable,
        $table.data('columns-excluded-in-export'),
        $table.data('report-title'),
        $table.data('text-button')
      );
    }

    if ($table.attr('data-fixed-right')) {
      perColumnSearch($table.parents('.dataTables_scroll'), datatable);
    }
    else {
      perColumnSearch($table, datatable);
    }
    canSignOnly($table, datatable);
    obj.datatable = datatable;
    obj.$table = $table;

    if ($table.data('proxy-form')) {
      proxyForm($table.dataTable());
    }

    setupAjaxChild(datatable, $table);
  },
  function (obj) {
    if (obj.$table) {
      obj.$table.find('input[data-filter]').off('keyup change blur');
      //Esta condicion permite evitar la destruccion de las tablas ante solicitudes ajax.
      if (!($(this).data('not-destroy'))) {
        // Solo destruir el datatable si el elemento sigue en el DOM.
        if (isInDom(obj.$table[0])) {
          obj.datatable.destroy();
        }
      }
    }
  }
);

onmount('[data-can-sign-only]', function () {
  const $self = $(this);
  const targets = $self.data('canSignOnly');
  const $targets = $(targets);
  if ($targets.length === 0) {
    throw new Error(`form-toggle target '${targets}' does not exist`);
  }

  $self.on('click', doFilter);

  function doFilter(e) {
    e.preventDefault();
    // eslint-disable-next-line lodash/preferred-alias
    each($targets, (obj) => {
      $(obj).trigger('filterSignOnly');
    });
  }
});
