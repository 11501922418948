/** @file TODO: documentar */
import onmount from 'onmount';
import { action } from '../lib/buk/alert';

onmount('#generate-file-new-signee-model', async function () {
  const $documentTemplateSelect = $('#document_template_select_id');
  const programmableSignees = JSON.parse($('#programmable_signatures')[0].value);
  const $selectSigneeTypes = $('.document_template_signee_type_select');
  const $signeeSelects = $('.document_template_signee_select');
  const $generateButton = $('#boton-generar-documento-empleado');
  const $generateFileModal = $('#generate-file-modal');
  const $generateFileModalLoader = $('#generate-file-modal-loader');
  const $generateFileModaErrorAlert = $('#generate-file-modal-alert');
  const $legalAgentsSelect = $('#legal_agent_selects');
  const $hiddenDocumentsGeneration = $('#hidden_document_generation');
  const $startWorkflow = $('#employee_file_start_workflow');
  const $warningMessage = $('#warning_message_for_checkbox_visibility');
  const $alertMessageSignatures = $('#alert_message_signatures');

  function workflowCheckboxLayout() {
    const checkedHiddenDocuments = $hiddenDocumentsGeneration?.prop('checked');
    $startWorkflow.attr('disabled', !checkedHiddenDocuments);
    if(checkedHiddenDocuments === false) {
      $warningMessage.removeClass('hidden');
      $startWorkflow.prop('checked', false);
    }
    else {
      $warningMessage.addClass('hidden');
    }
  }

  function resetDisplayedSignatureTypes(neededSignatures) {
    $selectSigneeTypes.each(function (i, signeeTypeSelect) {
      signeeTypeSelect.hidden = i >= neededSignatures.length;
      if (i < neededSignatures.length) {
        signeeTypeSelect.value = neededSignatures[i].label;
      }
    });
  }

  function getProgrammableNeededSignatures(neededSignatures) {
    return neededSignatures.filter((ns) => programmableSignees[ns.value]);
  }

  function refreshTemplateSignees(jsonTemplate) {
    const neededSignatures = jsonTemplate.needed_signatures;
    resetDisplayedSignatureTypes(neededSignatures);
    showAlertMessageSignatures(neededSignatures);
    const templateProgrammableSignees = getProgrammableNeededSignatures(neededSignatures);
    $signeeSelects.each(function (i, signeeSelect) {
      signeeSelect.innerHTML = '';
      const signeeSelectWrapper = $(`#${signeeSelect.id}_wrapper`)[0];
      if (i < templateProgrammableSignees.length) {
        const signeeType = templateProgrammableSignees[i];
        programmableSignees[signeeType.value].forEach(function (signee) {
          signeeSelect.append(
            new Option(
              signee.label,
              JSON.stringify({ type: signeeType.value, value: signee.value }),
              signee.priority === i,
              signee.priority === i
            )
          );
        });
        signeeSelectWrapper.hidden = false;
      }
      else {
        signeeSelectWrapper.hidden = true;
      }
    });
    $('#company_signees_extra_info').attr('hidden', neededSignatures.length === 0);
    $legalAgentsSelect.attr('hidden', templateProgrammableSignees.length === 0);
  }

  function refreshTemplateReviewer(jsonTemplate) {
    var selector = $('#document_template_reviewer_employee_option');
    if (!selector) return;

    var hideElement = true;
    if (jsonTemplate.reviewer) {
      $('#reviewer_type_assigned').val(jsonTemplate.reviewer);
      $('#reviewer_id_field').attr('placeholder', jsonTemplate.reviewer);
      hideElement = false;
    }
    selector.attr('hidden', hideElement);
  }

  function refreshItemsOnDocumentTemplateChange() {
    const selectedTemplate = $documentTemplateSelect.val();
    if (!selectedTemplate) return;

    const jsonTemplate = JSON.parse(selectedTemplate);
    refreshTemplateSignees(jsonTemplate);
    refreshTemplateReviewer(jsonTemplate);

    const $visibleSelectors = $('.select2');

    $visibleSelectors.addClass('w-100');
  }

  $(document).ready(function () {
    function updateSecondCheckboxVisibility() {
      const isFirstChecked = $('#hidden_document_generation').prop('checked');
      const selectedOption = $('#document_template_select_id option:selected');
      const requiresAction = selectedOption.data('requires-action');
      const shouldEnable = isFirstChecked && requiresAction;
      const $secondCheckbox = $('#employee_file_start_workflow');
      const $label = $('label[for="employee_file_start_workflow"]');

      $('#second-checkbox-container').toggleClass('hidden', !requiresAction);
      $secondCheckbox.prop('disabled', !shouldEnable);
      $label.toggleClass('text-muted text-dark', !shouldEnable);
      $secondCheckbox.tooltip('dispose').removeAttr('title data-bs-original-title');

      if (!shouldEnable) {
        const tooltipText = $secondCheckbox.data('title');
        $secondCheckbox.attr('title', tooltipText);

        $secondCheckbox.tooltip({
          placement: 'top',
          boundary: 'window',
          fallbackPlacement: ['top'],
        });
      }
    }

    $('#hidden_document_generation, #document_template_select_id').change(updateSecondCheckboxVisibility);
    updateSecondCheckboxVisibility();
  });

  function generateFileModalHandler(container, operation) {
    let containerClass = 'd-block';
    let finalContainer;

    if (container === 'alert') {
      finalContainer = $generateFileModaErrorAlert;
    }
    else if (container === 'main') {
      finalContainer = $generateFileModal;
    }
    else if (container === 'loader') {
      finalContainer = $generateFileModalLoader;
      containerClass = 'd-flex';
    }

    if (operation === 'hide') {
      finalContainer.removeClass(containerClass).addClass('d-none');
    }
    else if (operation === 'show') {
      finalContainer.removeClass('d-none').addClass(containerClass);
    }
  }

  function showAlertMessageSignatures(signatures) {
    if (signatures.length > 1) {
      const additionalLegalAgentText = $alertMessageSignatures.data('additionalLegalAgent');
      const neededSignaturesHash = {};
      const neededSignaturesLabels = signatures.map((signature) => signature.label);
      let legalAgentKey = '';

      neededSignaturesLabels.forEach((signature, iSignature) => {
        if (neededSignaturesHash[signature]) {
          neededSignaturesHash[signature].count++;
          neededSignaturesHash[signature].locations.push(iSignature);
        }
        else {
          neededSignaturesHash[signature] = { count: 1, locations: [iSignature] };
        }

        if (neededSignaturesHash[signature].count >= 2) {
          legalAgentKey = signature;
        }
      });

      if (legalAgentKey) {
        const additionalLegalAgentIndex = neededSignaturesHash[legalAgentKey].locations[1];

        neededSignaturesLabels[additionalLegalAgentIndex] = additionalLegalAgentText;
      }

      const neededSignaturesAlert = neededSignaturesLabels.join(', ');

      $alertMessageSignatures.find('#alert_message_signatures_text').text(neededSignaturesAlert);
      $alertMessageSignatures.removeClass('d-none');
    }
    else {
      $alertMessageSignatures.addClass('d-none');
    }
  }

  function sendAmplitudeTracking(elementTarget, event, extraData = {}) {
    const amplitude = $generateFileModal.data('amplitude');

    if (!amplitude) return false;

    if (event === 'document_create_button_clicked') {
      const documentType = getSelectedTemplateType($documentTemplateSelect);
      const amplitudeTemplateData = getSelectedAmplitudeTemplateData($documentTemplateSelect);
      const checkedHiddenDocuments = $hiddenDocumentsGeneration?.prop('checked') ?? true;
      elementTarget.trigger('amplitudeTracking', {
        message: 'document_create_button_clicked',
        data: {
          source: amplitude.source,
          document_creator_role: amplitude.creator,
          document_template_type: documentType,
          document_batch: 1,
          document_reviewer: amplitudeTemplateData?.document_reviewer ?? false,
          signature_required: amplitudeTemplateData?.signature_required ?? false,
          document_visible: checkedHiddenDocuments,
          signature_order: amplitudeTemplateData?.signature_order ?? false,
          has_multiple_open_periods: extraData?.documentPeriodStartDate !== null,
        },
      });
    }
    else if (event === 'document_signed_button_clicked') {
      const documentType = getSelectedTemplateType($documentTemplateSelect);
      const signerRole = amplitude?.creator === 'Colaborador' ? 'employee_signature' : amplitude?.creator;

      elementTarget.trigger('amplitudeTracking', {
        message: 'document_signed_button_clicked',
        data: {
          source: amplitude.source,
          document_signer_role: [signerRole],
          document_batch: 1,
          document_type: [documentType],
        },
      });
    }
  }

  function settingDocumentReady() {
    const $signRequestButton = $('#sign-request-button');

    $signRequestButton.on('click', function () {
      sendAmplitudeTracking($signRequestButton, 'document_signed_button_clicked');
    });

    $('.close').on('click', () => { window.location.reload(true); });
  }

  function getSelectedTemplateType(select) {
    return JSON.parse(select.find(':selected')[0].value).action_params.document_type;
  }

  function getSelectedAmplitudeTemplateData(select) {
    return JSON.parse(select.find(':selected')[0].value).amplitude_template_data;
  }

  refreshItemsOnDocumentTemplateChange();

  $generateButton.on('click', function (e) {
    e.preventDefault();
    $generateButton.prop('disabled', true);

    generateFileModalHandler('alert', 'hide');
    generateFileModalHandler('main', 'hide');
    generateFileModalHandler('loader', 'show');

    try {
      const requestInfo = JSON.parse($documentTemplateSelect.val());
      const amplitude = $generateFileModal.data('amplitude');

      const documentPeriodStartDateElement = $('#document_period_start_date');
      const documentPeriodStartDate = documentPeriodStartDateElement.length
        ? documentPeriodStartDateElement.val()
        : null;

      sendAmplitudeTracking($generateButton, 'document_create_button_clicked', { documentPeriodStartDate });

      const templateProgrammableSignees = getProgrammableNeededSignatures(requestInfo.needed_signatures);
      const legalAgentIds = templateProgrammableSignees.map(function (_, i) {
        // For future reference: This has to be changed to allow multiple signee types
        return JSON.parse($signeeSelects[i].value).value;
      });

      $.ajax({
        url: requestInfo.action_path,
        method: 'post',
        data: {
          ...requestInfo.action_params,
          legal_agents_ids: legalAgentIds,
          hidden_document_generation: $hiddenDocumentsGeneration?.prop('checked'),
          document_period_start_date: documentPeriodStartDate,
          start_workflow: $startWorkflow.prop('checked'),
          source_request: amplitude?.source,
        },
        success: (data) => {
          $generateFileModal.html(data);

          generateFileModalHandler('main', 'show');
          generateFileModalHandler('loader', 'hide');
          settingDocumentReady();
        },
        error: (xhr) => {
          const error = JSON.parse(xhr.responseText);

          $generateFileModaErrorAlert.find('.alert_container__content').html(error.status);
          generateFileModalHandler('alert', 'show');

          generateFileModalHandler('main', 'show');
          generateFileModalHandler('loader', 'hide');

          $generateButton.prop('disabled', false);
        },
      });
    }
    catch (error) {

      generateFileModalHandler('main', 'show');
      generateFileModalHandler('loader', 'hide');

      $generateButton.prop('disabled', false);
    }
  });

  $documentTemplateSelect.on('change', refreshItemsOnDocumentTemplateChange);
  $hiddenDocumentsGeneration.on('change', workflowCheckboxLayout);
});

onmount('#employee-documents-table', async function () {
  const $deleteDocumentActions = $('.delete-file-button');

  $deleteDocumentActions.on('click', (e) => softDeleteFlow(e));

  function deleteMessage() {
    return (
      action(
        `<p style="text-align:center"> ¿Estás seguro que deseas eliminar el documento? </p>`,
        '<div id="confirmar-eliminacion-papelera-ficha">Eliminar</div>',
        'Cancelar',
        'warning',
        '',
        'btn-destructive'
      )
    );
  }
  function softDeleteFlow(e) {
    const ids = [];
    const id = e.target.href || e.target.parentElement.href; // El evento puede gatillarse por hacer click en el ícono o en el botón
    ids.push(id.split('/').at(-1));
    $(e.target).trigger(
      'amplitudeTracking',
      { message: 'send_to_paperbin_button_clicked', data: { source: 'ficha_empleado', quantity: ids.length, ids: ids } }
    );
    deleteMessage().then(function (res) {
      $(e.target).trigger('amplitudeTracking', { message: 'send_to_paperbin_confirmation', data:
      { source: 'ficha_empleado', quantity: ids.length, ids: ids } });
      if (res.isConfirmed) {
        $.ajax({
          url: e.currentTarget.href,
          method: 'delete',
        }).done($(e.target).trigger(
          'amplitudeTracking',
          { message: 'send_to_paperbin_success', data: { source: 'ficha_empleado', quantity: ids.length, ids: ids } })
        );
      }
    });
  }
});

document.addEventListener('ajax:beforeSend', function (event) {
  const form = event.target.closest('form');
  if (form?.id === 'select-reassigners-form') {
    const selectedSigner = form.querySelector('input[name="multiple_document[selected_old_signer]"]:checked');

    if (selectedSigner) {
      const dataAmplitude = {
        message: 'Modify_Signature',
        data: {
          modified_document_signer: selectedSigner.value,
          document_template_type: form.querySelector('#document_type').value,
          document_visible: form.querySelector('#is_visible').value,
          signature_order: form.querySelector('#signature_order').value,
        },
      };
      //  La solución: Disparar el evento en el formulario
      $(form).trigger('amplitudeTracking', dataAmplitude);
    }
  }
});
