/** @file Comportamientos del modal de cambio de estado de contratos masivos **/

import onmount, { $ } from 'onmount';

/**
 * Determina las opciones disponibles de cambio de estado para contratos, dado
 * un estado seleccionado (excluido).
*/
onmount('#datatable-massive-contracts-jobs', function () {
  setSelectChangeStatus($(this), '#change-status-button-dropdown-contract', '#change-status-select-contract');
});

/**
 * Determina las opciones disponibles de cambio de estado para términos, dado
 * un estado seleccionado (excluido).
*/
onmount('#datatable-massive-contract-jobs-ends', function () {
  setSelectChangeStatus($(this), '#change-status-button-dropdown-contract-end', '#change-status-select-contract-end');
});

function setSelectChangeStatus($datatable, changeStatusButtonDropdownId, changeStatusSelectId) {
  $(changeStatusButtonDropdownId).on('click', function () {
    const $changeStatusSelect = $(changeStatusSelectId);
    const checkboxes = $datatable.find('input[data-checkbox-status]:checked');
    const selectedStatuses = checkboxes.toArray().map((check) => check.dataset.checkboxStatus);
    const uniqueStatus = [...new Set(selectedStatuses)];

    $changeStatusSelect.empty();
    $changeStatusSelect.append($('<option></option>').val('success').text('Exitoso'));
    $changeStatusSelect.append($('<option></option>').val('pending').text('Pendiente'));

    if(uniqueStatus.length === 1) {
      $(`${changeStatusSelectId} option[value="${uniqueStatus}"]`).remove();
    }
  });
}
