/** @file TODO: documentar */
import onmount from 'onmount';

onmount('button.toggle-inactive', function () {
  $(this).on('click', function () {
    if ($(this).text().trim() === 'Mostrar Inactivos') {
      $('.inactive').removeClass('d-none').show();
      $(this).text('Ocultar Inactivos');
    }
    else {
      $('.inactive').hide();
      $(this).text('Mostrar Inactivos');
    }
  });
});

onmount('.switch .custom-control-input', function () {
  const textid = '#status_' + $(this).attr('id');
  $(this).on('click', function () {
    if ($(this).is(':checked')) {
      $(textid).text('Activado');
    }
    else {
      $(textid).text('Desactivado');
    }
  });
});
