/** @file
 * Define los componentes de React que se usarán en la aplicación.
 */
import onmount from 'onmount';

onmount('.item-formulado', async () => {
  await import('../stylesheets/components/_monaco_editor.scss');
  const { define } = await import('remount');
  const { MonacoEditor } = await import('./components/react/monaco_editor');
  // Sólo definimos el componente si este no ha sido definido en la instancia actual.
  if (!window.customElements.get('monaco-editor')) {
    define({ 'monaco-editor': MonacoEditor }, { attributes: ['initialValue'] });
  }
});

onmount('.columna-formulada', async () => {
  await import('../stylesheets/components/_monaco_editor.scss');
  const { define } = await import('remount');
  const { MonacoEditor } = await import('./components/react/editor_accounting');
  if (!window.customElements.get('editor-formulas-accounting')) {
    define({ 'editor-formulas-accounting': MonacoEditor }, { attributes: ['initialValue', 'klass'] });
  }
});
onmount('.period-picker-wrapper', async () => {
  // Sólo definimos el componente si este no ha sido definido en la instancia actual.
  const { define } = await import('remount');
  const { PeriodPicker } = await import('./components/react/period_picker.jsx');
  if (window.customElements.get('period-picker')) return;

  define(
    { 'period-picker': PeriodPicker },
    {
      attributes: [
        'input-id',
        'label-id',
        'label',
        'placeholder',
        'period-type',
        'initial-value',
        'input-options',
        'required',
        'disabled',
        'can-clear',
        'end-of-period',
        'default-month',
        'start-date',
        'end-date',
        'link-date-picker-start-date',
        'enabled-months',
        'week-starts-on',
        'translations',
        'formats',
        'read-only',
        'error-message',
        'hint',
        'process-ic-nodes',
        'language',
      ],
    }
  );
});

onmount('date-picker', async () => {
  // Sólo definimos el componente si este no ha sido definido en la instancia actual.
  const { define } = await import('remount');
  const { Datepicker } = await import('./components/react/date_picker.jsx');
  if (window.customElements.get('date-picker')) return;

  define({ 'date-picker': Datepicker }, {
    attributes: [
      'id',
      'label-id',
      'name',
      'value',
      'min-date',
      'max-date',
      'disabled-dates',
      'disable-weekends',
      'highlighted-dates',
      'clear-button-label',
      'disabled-input',
      'read-only-input',
      'hint',
      'error-message',
      'required',
      'language',
    ],
  });
});

onmount('range-date-picker', async () => {
  // Sólo definimos el componente si este no ha sido definido en la instancia actual.
  const { define } = await import('remount');
  const { RangeDatePicker } = await import('./components/react/range_date_picker.jsx');
  if (window.customElements.get('range-date-picker')) return;

  define({ 'range-date-picker': RangeDatePicker }, {
    attributes: [
      'id',
      'label-id',
      'name',
      'value',
      'min-date',
      'max-date',
      'disabled-dates',
      'disable-weekends',
      'highlighted-dates',
      'ok-button-label',
      'clear-button-label',
      'disabled-input',
      'read-only-input',
      'hint',
      'error-message',
      'intercooler',
      'process-ic-nodes',
      'required',
      'language',
    ],
  });
});
