/** @file TODO: documentar */

/**
 *
 * Funciones comunes para las acciones que usan los atributos:
 * - data-multi-select-form
 * - data-multi-select-action="toggle"
 */

/**
 * Asigna un tooltip y deshabilita la acción que contiene el atributo multi-select
 *
 * @param {JQuery} linkContainer Contenedor de la acción
 */
function prepareLinkContainer(linkContainer) {
  linkContainer.addClass(' disabled');
  linkContainer.attr('data-toggle', 'tooltip');
  linkContainer.attr('data-placement', 'left');
  linkContainer.tooltip('enable');

  // Revisamos si el hijo del contenedor o el mismo tienen el atributo multi-select-hide para ocultar el contenedor.
  toogleContainerDisplay(linkContainer, false);
}

/**
 * Maneja el tooltip y habilitación de la acción que contiene el atributo multi-select
 * de acuerdo a número de checkboxes seleccionados
 *
 * En el caso de que se necesite añadir un tooltip que se muestre cuando hayan items seleccionados,
 * se puede añadir data-multi-select-tooltip y data-multi-select-original-tooltip,
 * para intercambiar los mensajes de los tooltip, dependiendo de si hay o no items seleccionados.
 *
 * @param {JQuery} linkContainer Contenedor de la acción
 * @param {checkboxCount} event Número de checkboxes seleccionados
 */
function handleLinkContainer(linkContainer, checkedBoxes) {
  linkContainer.toggleClass('disabled', checkedBoxes === 0);

  // Permite añadir un tooltip para cuando hayan items seleccionados
  const innerTooltip = linkContainer.children(0).attr('data-multi-select-tooltip');
  const originalTooltip = linkContainer.children(0).attr('data-multi-select-original-tooltip');
  if (!!innerTooltip && !!originalTooltip) {
    const tooltipToUse = checkedBoxes === 0 ? originalTooltip : innerTooltip;
    linkContainer
      .attr('title', tooltipToUse)
      .tooltip('dispose')
      .tooltip({ title: tooltipToUse });
    linkContainer.tooltip('enable');
  }
  else {
    linkContainer.tooltip(checkedBoxes === 0 ? 'enable' : 'disable');
  }

  // Revisamos si el hijo del contenedor o el mismo tienen el atributo multi-select-hide para ocultar el contenedor
  // o mostrarlo según corresponda.
  toogleContainerDisplay(linkContainer, checkedBoxes > 0);
}

/**
 * Lanza el evento checkboxCount con el número de checkboxes chequeados
 * @param {*} checkboxSource Contenedor de los checkboxes. Puede ser una tabla de Datatable.
 * @returns {object} Los checkboxes chequeados y cuantos son.
 */
function countCheckbox(checkboxSource) {
  // Preguntamos si es un datatable asincrono
  if ((checkboxSource.data('serverSide') && checkboxSource.data('datatable')) || checkboxSource.data('fijiTable')) {
    if(checkboxSource.data('dt-query-select')) return querySelectInputs(checkboxSource);
    return asyncCountCheckbox(checkboxSource);
  }
  else {
    return syncCountCheckbox(checkboxSource);
  }
}

/**
 * Lanza el evento checkboxCount con el número de checkboxes chequeados
 * @param {*} checkboxSource Contenedor de los checkboxes. Puede ser una tabla de Datatable.
 * @returns {object} Los checkboxes chequeados y cuantos son.
 */
function syncCountCheckbox(checkboxSource) {
  let checkedBoxes;
  if ($.fn.dataTable.isDataTable(checkboxSource)) {
    checkedBoxes = checkboxSource.data('DataTable').$('input[type=checkbox]:checked');
  }
  else {
    checkedBoxes = checkboxSource.find('input[type=checkbox]:checked');
  }
  const checkedBoxesNumber = checkedBoxes.length;
  const event = $.Event('checkboxCount', { count: checkedBoxesNumber });
  checkboxSource.trigger(event);
  return { checkedBoxes: checkedBoxes, count: checkedBoxesNumber };
}

/**
 * Busca en el inputStore para recrear los inputs chequeados.
 * (Este método busca mantener la compatibilidad entre los dos tipos de datatable.)
 * Lanza el evento checkboxCount con el número de checkboxes chequeados
 * @param {*} checkboxSource Contenedor de los checkboxes. Puede ser una tabla de Datatable.
 * @returns {object} Los checkboxes chequeados creados en caliente y cuantos son.
 */
function asyncCountCheckbox(checkboxSource) {
  const storeInput = checkboxSource.find('[data-dt-saved-selects]');

  // Devuelve los ids guardados en la tabla.
  const recordIds = JSON.parse(storeInput.val() || '[]');
  const checkedBoxesNumber = recordIds.length;
  const event = $.Event('checkboxCount', { count: checkedBoxesNumber });

  // Obtenemos el nombre del parámetro a enviar.
  const checkboxName = storeInput.data('dtSavedSelects');
  const checkBoxes = recordIds.map(function (id) {
    return $('<input>').attr({ type: 'hidden', name: checkboxName, value: id, 'data-multi-select-input': 'true'})[0];
  });

  checkboxSource.trigger(event);
  return { checkedBoxes: $(checkBoxes), count: checkedBoxesNumber };
}

/**
 * Busca en el InputStore para recrear los inputs chequeados Y los query params de la datatable
 * (Incompatible con tablas que no sean Datatable)
 * @param (*) checkboxSource Contenedor de los checkboxes. Debe ser una Datatable
 * @returns {object} Los checkboxes chequeados Y un input extra con los query params
 */
function querySelectInputs(checkboxSource) {
  if(!checkboxSource.data('query-select-on')) return asyncCountCheckbox(checkboxSource);
  const storeInput = checkboxSource.find('[data-dt-saved-selects]');
  const ransackParams = storeInput.data('dt-ransack-params') ? storeInput.data('dt-ransack-params') : '';
  const tmpDatatable = checkboxSource.DataTable(checkboxSource.data());
  const ransackInput = $('<input>').attr({
    type: 'hidden',
    name: 'ransack-params',
    value: JSON.stringify(ransackParams),
    'data-multi-select-input': 'true',
  });
  const datatablesInput = $('<input>').attr({
    type: 'hidden',
    name: 'datatable-params',
    value: JSON.stringify(tmpDatatable.ajax.params()),
    'data-multi-select-input': 'true',
  });
  const { checkedBoxes, count } = asyncCountCheckbox(checkboxSource);
  const event = $.Event('checkboxCount', { count: tmpDatatable.ajax.json().recordsFiltered });
  checkboxSource.trigger(event);
  return { checkedBoxes: checkedBoxes.add(ransackInput).add(datatablesInput), count: count };
}

/**
 * Muestra u oculta el contenedor según si está presente el atributo data multi-select-hide
 * en el contenedor o el hijo
 * @param {JQuery} linkContainer Contenedor de la acción.
 * @param {Boolean} showDisplay Si se muestra u oculta el contenedor
 */
function toogleContainerDisplay(linkContainer, showDisplay) {
  if (linkContainer.children('[data-multi-select-hide="true"]').length > 0 ||
    Boolean(linkContainer.data('multi-select-hide'))) {
    showDisplay ? linkContainer.show() : linkContainer.hide();
  }
}

/**
 * Devuelve los ids de los registros seleccionados en la tabla
 * @returns {Array} Un array con los ids de los registros seleccionados
*/
function getSelectedIds() {
  const table = $('[data-dt-multi-select]');
  const storeInput = table.find('[data-dt-saved-selects]').filter(function () {
    return $(this).val() !== '';
  });
  const recordIds = JSON.parse(storeInput.val() || '[]');

  return recordIds;
}

export { prepareLinkContainer, handleLinkContainer, countCheckbox, toogleContainerDisplay, getSelectedIds };
