/** @file Controla las acciones de elementos relacionados a tareas pendientes */
import onmount, { $ } from 'onmount';

const mounted = { 'mobile': false, 'desktop': false };
const pendingTaskWidget = { calledFromUserConfig: false };
const openPendingTaskSidebar = async function (device) {
  if (mounted[device]) return;

  mounted[device] = true;

  const $toogleButton = (device === 'desktop') ? $('#toggle-aside-reminder-navbar-loaded') : $('#tareas-tab');
  const locationSearch = new URLSearchParams(window.location.search);
  const deliveryQuery = locationSearch.get('delivery');
  const refererQuery = locationSearch.get('referer');

  if (!deliveryQuery || !refererQuery) return;

  if (deliveryQuery === 'pending_task_reminder' && refererQuery === 'mail') {
    $toogleButton.click();
  }
};

const isMobile = function () {
  return window.matchMedia('(max-width: 960px)').matches;
};

onmount('.pending-task-aside-render-async', function () {
  const $pendingAsideRenderAsync = $(this);
  let $pendingTaskAsideLoader;
  let $pendingTaskAsideContent;

  $pendingAsideRenderAsync
    .on('beforeSend.ic', function (...params) {
      const settings = params[3];

      $pendingTaskAsideLoader = $('#pending-task-aside-loader');
      $pendingTaskAsideContent = $('#pending-task-aside-content');

      if (settings.url.includes('/pendings')) {
        $pendingTaskAsideLoader.removeClass('d-none');
        $pendingTaskAsideContent.addClass('d-none');
      }
    })
    .on('complete.ic', function (...params) {
      const data = params[2];
      const status = params[3];

      setTimeout(() => {
        if (data.includes('pending-task-aside-render-async') && status === 'success') {
          $pendingTaskAsideLoader.addClass('d-none');
          $pendingTaskAsideContent.removeClass('d-none');
        }
      }, 100);
    });
});

onmount('.edit_user_config', function () {
  const $updatePendingTaskConfigForm = $(this);

  /* eslint-disable no-undef */
  $updatePendingTaskConfigForm
    .on('success.ic', function () {
      const $pendingTaskWidget = $('#pending-task-widget');
      const $pendingTaskWidgetLoader = $('#pending-task-widget-loader');

      if (isMobile()) {
        Intercooler.triggerRequest($('#pending-task-aside-mobile-render-async'));
      }
      else {
        pendingTaskWidget.calledFromUserConfig = true;

        Intercooler.triggerRequest($pendingTaskWidget);
        $pendingTaskWidget.addClass('d-none');
        $pendingTaskWidgetLoader.removeClass('d-none');
      }
    });
  /* eslint-enable no-undef */
});

onmount('#pending-task-widget', function () {
  const $pendingTaskWidget = $(this);

  $pendingTaskWidget
    .on('success.ic', function () {
      if (pendingTaskWidget.calledFromUserConfig) {
        const $pendingTaskWidgetLoader = $('#pending-task-widget-loader');

        $pendingTaskWidget.removeClass('d-none');
        $pendingTaskWidgetLoader.addClass('d-none');
      }
    });
});

isMobile()
  ? onmount('#tareas-tab', () => openPendingTaskSidebar('mobile'))
  : onmount('#toggle-aside-reminder-navbar-loaded', function () {
    openPendingTaskSidebar('desktop');

    // Se encarga de actualizar el widget de Tareas Pendientes del portal y la campana de notificaciones
    // cuando ocurre un cambio desde el aside bar de Tareas Pendientes.
    const notificationsButton = $(this);

    /* eslint-disable no-undef */
    notificationsButton.on('update-pending-task-deps', function () {
      if ($('#pending-task-widget').length) {
        Intercooler.triggerRequest($('#pending-task-widget'));
      }
      else {
        Intercooler.triggerRequest($('#toggle-aside-reminder-navbar-loaded'));
      }
    });

    notificationsButton.on('actualizar-campana-notificaciones', function () {
      Intercooler.triggerRequest($('#toggle-aside-reminder-navbar-loaded'));
    });
    /* eslint-enable no-undef */
  });

window.addEventListener('resize', () => {
  isMobile()
    ? openPendingTaskSidebar('mobile')
    : openPendingTaskSidebar('desktop');
});
