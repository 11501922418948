/** @file TODO: documentar */
import { debounce } from 'lodash-es';
import onmount from 'onmount';

class WordSearchPlugin {
  constructor(input, filterFunc, lazzyBuildNodes = false) {
    this.input = $(input);
    this.nodes = [];
    this.debouncedFilter = debounce(this.filterNodes, 750);
    this.filter = filterFunc;
    this.lazzyBuildNodes = lazzyBuildNodes;
    this.setup(lazzyBuildNodes);
  }
  setup(lazzyBuildNodes) {
    const self = this;
    this.setOptions();
    if (lazzyBuildNodes) {
      $('input[type="search"]').on('input', function () {
        self.buildNodes();
      });
    }
    else{
      this.buildNodes();
    }
    this.setHandlers();
  }

  setOptions() {
    this.options = {
      node_selector: this.input.data('targetNode'),
      text_selector: this.input.data('targetText'),
      empty_message: this.input.data('emptyMessage'),
    };
  }

  buildNodes() {
    if(this.nodes.length > 0 && this.lazzyBuildNodes) this.nodes = [];

    const self = this;
    $(this.options.node_selector).each(function () {
      const $text = $(this).find(self.options.text_selector);
      const $filterText = $text.text() + $(this).data('extraTextSearch');

      self.nodes.push({
        filter: self.filterText($filterText),
        node: $(this)
      });
    });
  }
  setHandlers() {
    const self = this;
    this.input.on('input', function () {
      self.debouncedFilter(this.value);
    });
    this.input.on('keydown', function (e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        self.debouncedFilter(this.value);
        return false;
      }
    });
  }

  filterNodes(text) {
    const isGeneralFilter = $('#general_filter').length > 0;
    const results = this.filter(text, this.nodes);
    const emptyMessage = $(this.options.empty_message);
    if (isGeneralFilter) {
      $(this.options.node_selector).parents('.box-inner').hide();
    }

    $(this.options.node_selector).hide();
    results.forEach(function (match) {
      if (isGeneralFilter) {  match.node.not(':visible').show(); }
      isGeneralFilter ? match.node.parents('.box-inner').show() : match.node.show();
    });
    if (emptyMessage.length) {
      results.length > 0 ? emptyMessage.hide() : emptyMessage.show();
    }
  }
  filterText(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, ' ').toLowerCase();
  }
}

onmount('[data-word-filter]', async function () {
  const filter = (text, nodes) => wordFilter(text, nodes);
  new WordSearchPlugin(this, filter);
});

onmount('[lazzy-data-word-filter]', async function () {
  const lazzyBuildNodes = true;
  const filter = (text, nodes) => wordFilter(text, nodes);
  new WordSearchPlugin(this, filter, lazzyBuildNodes);
});

function wordFilter(text, nodes) {
  const unformattedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const words = unformattedText.replace(/\s+/g, ' ').trim().toLowerCase().split(' ');
  return nodes.filter(function (node) {
    return words.every(function (word) {
      return node.filter.indexOf(word) !== -1;
    });
  });
}
