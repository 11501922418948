/** @file TODO: documentar */
import onmount from 'onmount';

/*
 * Permite hacer pasar desde el menú admin a menú colaborador en el nuevo sidebar,
 * los botones para hacer el cambio están en el footer del mismo menú
 */

onmount('.sidebar-wrapper', function () {
  const bodySelector = $('body');
  const sidebarSelector = $('.sidebar__min');
  const self = $(this);
  const activeSectionSelector = document.querySelectorAll('.sidebar__min .nav-link.active');

  /* Si el usuario realiza click en boton toggle, es porque prefiere menu colapsado*/
  /* Si prefiere colapsado, al pulsar boton de menu, este se abre y luego se vuelve a cerrar  */

  $(sidebarSelector).on('click', '[data-toggle="pill"]',  () => {
    bodySelector.removeClass('sidebar-collapse');
  });

  if(activeSectionSelector.length < 1) {
    const firstChildMenuList = document.querySelectorAll('.sidebar__min .nav-link');
    const firstChildContentMenuList = document.querySelectorAll('.sidebar__menu .tab-pane.fade');
    $(firstChildMenuList[0]).addClass('active');
    $(firstChildContentMenuList[0]).addClass('show active');
  }

  function isTouchDevice() {
    /* eslint-disable-next-line no-undef */
    return true === ('ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch);
  }

  if (isTouchDevice() === false) {
    $('.nav-link[data-menu-tooltip="tooltip"]').tooltip({
      placement: 'right',
      trigger: 'hover',
      html: true,
      customClass: 'menu_min__tooltip',
    });
  }

  if ($(window).width() <= 960) {

    $(document).on('click', '[data-toggle="push-menu"]', function (e) {
      e.preventDefault();
      if ($('body').hasClass('sidebar-open')) {
        self.after('<div class="sidebar-background" data-toggle="push-menu"></div>');
        $('.sidebar-background').animate({ opacity: 1 }, 'fast');
      }
      else {
        closeSidebar();
      }
    });

    /* Swipe left feature */

    var touchstartX = 0;
    var touchendX = 0;

    var gestureZone = self[0];

    gestureZone.addEventListener('touchstart', function (event) {
      touchstartX = event.changedTouches[0].screenX;
    }, false);

    gestureZone.addEventListener('touchend', function (event) {
      touchendX = event.changedTouches[0].screenX;
      handleGesture();
    }, false);
  }

  function handleGesture() {
    if (touchstartX > (touchendX + 75)) {
      $('body').removeClass('sidebar-open');
      closeSidebar();
    }
  }

  function closeSidebar() {
    const sidebarBackground = $(document).find('.sidebar-background');
    sidebarBackground.animate({ opacity: 0 }, 'fast');
    setTimeout(function () {
      sidebarBackground.remove();
    }, 500);
  }

  // TODO: CAMBIAR A CELL PARA NO SEPARAR LOGICAS. Por el momento, los anchore se manejarán por js
  const miOnboardingStructure = { link_anchore: 'onboarding', group: 'buk' };
  menuWithAnchore(miOnboardingStructure);

});

function menuWithAnchore(miOnboardingStructure) {
  const currentUrl = window.location.href;
  const urlAnchor = currentUrl.split('#')[1];
  if(urlAnchor ===  miOnboardingStructure['link_anchore']) {
    // Se remueven las clases 'active'
    $(document).find('.nav-link.active').removeClass('active');
    const miBuk = $(document).find('#'.concat(miOnboardingStructure['group']));
    // Se le agrega la clase 'active' al grupo
    $(document).find('#'.concat(miOnboardingStructure['group'], '-tab')).addClass('active');
    const menuLinks = miBuk.find('.nav-link');
    // Se le agrega la clase 'active' al link
    for(var linkIdx = 0; linkIdx < menuLinks.length; linkIdx++) {
      const menuLink = $(menuLinks[linkIdx]).attr('href');
      if(menuLink.split('#')[1] === miOnboardingStructure['link_anchore']) {
        $(menuLinks[linkIdx]).addClass('active');
      }
    }
  }
}
