/** @file
  * Permite modificar el `file_type` del evento `Download_Process_File` al descargar 'Otros Pagos'
  * según el archivo seleccionado en el formulario.
  */
import onmount, { $ } from 'onmount';

onmount('#new_item_transfer_form', async () => {
  $('#item_transfer_form_transfer_type').on('change', event => {
    const selector = event.target;
    const { selectedIndex } = selector.options;
    const { value } = selector.options[selectedIndex];
    if (value === '' || value === undefined) return;

    const submitButton = $('#item_transfer_submit_button');
    const amplitudeData = JSON.parse(
      submitButton.attr('data-amplitude-tracking')
    );
    amplitudeData.data.file_type = value;
    submitButton.attr('data-amplitude-tracking', JSON.stringify(amplitudeData));
  });
});
