/**
 * @file Views helper
 * @description Este archivo contiene funciones y eventos para interacciones dinámicas específicas de las vistas .js.erb
 */
import onmount, { $ } from 'onmount';

onmount('#applicants_in_stage_table', function () {

  /**
   * Elimina una fila de la tabla DataTable para un postulante específico
   */
  function removeRowFromApplicantsTable(applicantId) {
    const tr = $(`#row-content-${applicantId}`).closest('tr');
    $('#applicants_in_stage_table').DataTable().row(tr).remove();
  }

  /**
   * Redibuja la tabla DataTable
   * Esto asegura que los cambios realizados (como eliminación de filas) se reflejen correctamente
   */
  function redrawApplicantsTable() {
    $('#applicants_in_stage_table').DataTable().draw(false);
  }

  window.removeRowFromApplicantsTable = removeRowFromApplicantsTable;
  window.redrawApplicantsTable = redrawApplicantsTable;
});
