/** @file Enviar datos a amplitud para los eventos relacionados con procesos */
import onmount from 'onmount';

const methods = {
  getUrlMatches,
  subscribeToBukSubprocessClickEvent,
  sendNewBukSubprocessEvent,
  subscribeRevisarNominaBtnClickEvent,
  subscribePagarBtnClickEvent,
};

const processSuccessfullyCreatedMessages = [
  'Proceso creado correctamente',
  'Process successfully created',
];

function getUrlMatches(url) {
  const regex = /buk_subprocesses\/(\d+)/;
  return url.match(regex);
}

function subscribeToBukSubprocessClickEvent() {
  $('.buk_subprocess_amplitude').on('click',
    (e) => {
      const data = {
        'payroll_process_id': +e.target.getAttribute('data-id'),
        'payroll_status': e.target.getAttribute('data-status'),
        'payroll_process_type': e.target.getAttribute('data-process-type'),
      };
      $(e.target).trigger('amplitudeTracking', { message: 'View_Payroll_Process', data: data });
    }
  );
}

function sendNewBukSubprocessEvent($event) {
  const alertText = $event.text().trim();
  if (processSuccessfullyCreatedMessages.some((message) => alertText.includes(message))) {
    const match = getUrlMatches(window.location.href);
    if (match && match[1]) {
      let processId = +match[1];
      onmount('#data_filter_btn', async function () {
        if (processId) {
          const data = {
            'payroll_process_id': processId,
            'payroll_process_type': $('#buk_subprocess_process_type').val(),
            'payroll_process_participants': +$('#process_employees_table tr:not(:first) input[type="checkbox"]').length,
          };
          $(this).trigger('amplitudeTracking', { message: 'Create_Payroll_Process', data: data });
          processId = null;
        }
      });
    }
  }
}

function subscribeRevisarNominaBtnClickEvent($event) {
  $event.on('click',
    (e) => {
      const data = {
        'payroll_process_id': +$(e.currentTarget).data('id'),
        'payroll_process_type': $(e.currentTarget).data('process-type'),
      };
      $(e.target).trigger('amplitudeTracking', { message: 'Check_Payroll', data: data });
    }
  );
}

function subscribePagarBtnClickEvent($event) {
  $event.on('click',
    (e) => {
      const data = {
        'payroll_process_id': +$(e.currentTarget).data('id'),
        'payroll_pay_type': $(e.currentTarget).data('type'),
        'payroll_process_type': $(e.currentTarget).data('process-type'),
      };
      $(e.target).trigger('amplitudeTracking', { message: 'Pay_Payroll', data: data });
    }
  );
}

onmount('#buk_subprocess_table', async function () {
  subscribeToBukSubprocessClickEvent();
});

onmount('#table_processes_semi_monthly', async function () {
  subscribeToBukSubprocessClickEvent();
});

onmount('#table_processes_monthly', async function () {
  subscribeToBukSubprocessClickEvent();
});

onmount('.alert_success', async function () {
  sendNewBukSubprocessEvent($(this));
});

onmount('#revisar_nomina_button', async function () {
  subscribeRevisarNominaBtnClickEvent($(this));
});

onmount('#pagar_submit_button', async function () {
  subscribePagarBtnClickEvent($(this));
});

export default methods;
