/* eslint-disable buk/no-static-import */
/** @file configuraciones para el módulo de talento:
 * - Configuración: comportamientos para la configuración de evaluaciones
 * - Evaluación: comportamientos relacionados al proceso de evaluación activa
 * - Objetivos
 * - Competencias
*/
const ZONE = 'es';
import onmount from 'onmount';
import {
  warning, info, action as swalAction, actionMessage as swalActionMessage
} from '../../../../../../../app/assets/javascripts/lib/buk/alert.js';
import { groupBy } from 'lodash-es';
import {
  CompletionPercentageCalculator
} from '../behaviors/completion-percentage-on-change.js';
import * as notify from '../../../../../../../app/assets/javascripts/lib/buk/notice.js';
import { removeAlert, createAlertError, addInputSelect2Error, removeInputSelect2Error } from './utils.js';

window.info = info;

// Evaluación
$(document).on('change', '.autosave input, .autosave textarea, .autosave select', function () {
  const self = this;
  const url = $(self).parents('.autosave').data('root-path');
  const questionId = self.name.match(/attempt\[(\d*)\].*/)?.[1];

  if (!questionId) { return; }

  const data = { question_id: questionId };
  if (self.type == 'checkbox') {
    data[self.name] = $(`[name="${self.name}"]:checked`).map((_i, inp) => inp.value).toArray();
    if (data[self.name].length == 0) {
      data[self.name] = [""];
    }
  }
  // Evitar realizar auto-save para inputs de tipo archivo.
  // TODO: Cambiar el data, para que envie un formulario en vez un hash con la información
  // Esto porque los archivos no se estan enviando correctamente. Estos deben ser enviador en un Formulario.
  else if (self.type === 'file') {
    return;
  }
  else {
    data[this.name] = this.value;
  }

  $.ajax({
    url,
    data,
    method: 'PUT',
    success: function (_response) {
      $(self).closest('div').find('.errors').hide();
      if ($(self).closest('label').hasClass('special-radio-button')) {
        $('.errors').hide();
        $('div.has-error').removeClass('form-group has-error');
      }
      const now = new Date();
      $(self)
        .parents('.answer-box')
        .find('.autosave-response')
        .hide()
        .html(`Respuesta guardada ${now.toLocaleDateString(ZONE)} ${now.toLocaleTimeString(ZONE)}`)
        .fadeIn();
    },
    error: function (request) {
      const response  = request.responseJSON;
      const error = response ? response.errors : 'Ocurrió un error desconocido, por favor intente nuevamente';
      const nps = $(self).closest('label').hasClass('special-radio-button');
      const div = nps ? $('div.toggle_buttons') : $(self).closest('div');
      if(div.parents('#edit_attempt').length === 0) {
        div.find('.errors').hide();
        div.append(`<div class="errors text-danger">${error}</div>`);
        div.addClass('form-group has-error');
        div.parents('.answer-box').find('.autosave-response').html('');
      }
    },
  });
});

// Evaluator step form
const updateSections = (target) => {
  let total = 0;
  const sectionSelector = target.data('section-selector');
  target.find(`${sectionSelector} > .sortable-box__title .section-measure-trigger`).each((_, e) => {
    const checked = $(e).is(":checked");
    const parent = $(e).closest(sectionSelector);
    if(checked) {
      if(parent.data('ponderable')) total += parseFloat(parent.find(`${sectionSelector}.weight-container .weight`).val() || 0);
      parent.removeClass('disabled');
      parent.find(`${sectionSelector}.activable`).prop('disabled', false);
    }
    else {
      parent.addClass('disabled');
      parent.find(`${sectionSelector}.activable`).prop('disabled', true);
    }
  });
  target.find(`${sectionSelector}.total-weight-container .total-weight`).text(total);
  $("#total_weight_sections_value").text($("#total_weight_sections").text());
  if (total == 100) {
    $("#total_weight_sections_final").removeClass("text-danger").addClass('text-success');
    target.find(`${sectionSelector}.total-weight-container`).removeClass("text-danger").addClass('text-success');
  }
  else {
    $("#total_weight_sections_final").removeClass("text-success").addClass('text-danger');
    target.find(`${sectionSelector}.total-weight-container`).removeClass("text-success").addClass('text-danger');
  }
};

$(document).on('change', '.measurable-activable .section-measure-trigger', function () {
  const target = $(this).closest('.measurable-activable');
  updateSections(target);
  const sectionSelector = target.data('section-selector');
  const checked = $(this).is(":checked");
  const parent = $(this).parents(sectionSelector);
  const boxAction = checked ? 'expand' : 'collapse';
  parent.boxWidget(boxAction);
});

$(document).on('keyup change', '.measurable-activable .weight', function () {
  updateSections($(this).closest('.measurable-activable'));
});

// Cultura
$(document).on('click', 'a.bs-wizard-step[data-toggle="tab"]', function () {
  $(this).addClass('completed');
});

// Cultura
function updateEvaluationEmployees(input) {
  const target = $($(input).data('selectTarget'));
  const id = $(input).data('id');
  const value = target.val();
  const newValue = value.includes(`${id}`) && (!$(input).is(":checked") || ($(input).is(":checked")&& !$(input).hasClass('remove-evaluated-selected')))? value.filter(i => i != id) : value.concat(id);
  target.val(newValue);
  if($("#new_employees_link").attr("href")) {
    const href = $("#new_employees_link").attr("href").split("?")[0];
    const params = newValue.map(v => `selected_ids[]=${v}`).join("&");
    $("#new_employees_link").attr('href', `${href}?${params}`);
  }
}

// Cultura
function showRemoveMassive() {
  let countNotVisible = 0;
  let countEmployees = 0;
  $('.seleted-employees').each(function () {
    if(!($(this).closest('tr').is(':visible'))) {
      countNotVisible = countNotVisible + 1;
    }
    countEmployees = countEmployees + 1;
  });
  if(countEmployees == countNotVisible) {
    $('.remove-evaluated-selected').hide();
  }
  else{
    $('.remove-evaluated-selected').show();
  }
}

// Cultura
function countEmployeesChecked() {
  let countChecked = 0;
  $('.seleted-employees').each(function () {
    // If checkbox is checked
    if(this.checked && $(this).closest('tr').is(':visible')) {
      countChecked = countChecked + 1;
    }
  });
  $("#span-remove-selected").html('('+countChecked+')');
}

// Cultura
function countShowMassive() {
  countEmployeesChecked();
  showRemoveMassive();
}

// Cultura
$(document).on('change', '.evaluated_employee, .remove-evaluated', function () {
  updateEvaluationEmployees(this);
  countShowMassive();
});

$(document).on('click', '.selected-employees', function () {
  countShowMassive();
});

// Configuración: Cambiar tooltip al cambiar la escala por sección de evaluación
$(document).on('change', '.section_scale_select', function () {
  const sectionId = $(this).data('sectionId');
  const tooltipElement = $(`#section-scale-${sectionId} [data-toggle="tooltip"][data-html]`);
  const scaleType = $(this).closest('.section').find('.section_scale_type');
  const optgroup = this.options[this.selectedIndex].parentElement;

  if (optgroup.label == 'Otros') {
    tooltipElement.attr('data-original-title', tooltipElement.attr('data-title'));
    scaleType.val(this.value);
  }
  else {
    scaleType.val('scale');
  }
});

// Evaluación
$(document).on('change', '.employee-checkbox', function () {
  if ($(".employee-checkbox").is(":checked")) {
    $(".reminder-button").removeClass("disabled");
  }
  else {
    $(".reminder-button").addClass("disabled");
  }
});

// Evaluación: recordatorios
$(document).on('click', '.reminder-button', function () {
  const url = $(location).attr("pathname") + "/reminder";
  const ids = [];
  const btnReminder = $(this);
  btnReminder.attr('disabled', true);

  $(".employee-checkbox:checked").each(
    function () {
      const id = $(this).attr("data-id");
      ids.push(id);
    });

  if (ids.length==0) {
    warning("Seleccione una evaluación");
  }
  else {
    $.ajax({
      type: "POST",
      url: url,
      data: {evaluator_person_ids: ids},
      success: function () {
        btnReminder.attr('disabled', false);
        info("Recordatorios enviados");
      },
      error: function () {
        btnReminder.attr('disabled', false);
        warning("Hubo un problema con el envio de Recordatorios");
      }
    });
  }
});

// Evaluación: recordatorios
$(document).on('click', '.feedback-reminder-button', function () {
  const url = $(location).attr("pathname") + "/feedback_reminder";
  const textSendReminders = $(this).data('text-reminder');
  swalAction(textSendReminders, 'Aceptar', 'Cancelar').then(function (res) {
    if (res.value) {
      $.ajax({
        type: 'POST',
        url: url,
        success: function () {
          info('Recordatorios enviados');
        },
        error: function () {
          warning('Hubo un problema con el envio de Recordatorios');
        },
      });
    }
  });
});

// Evaluación: resultados
$(document).on('click', '#evaluation-result-tabs a[data-toggle="tab"]', function () {
  const anchor = $(this).attr('href');
  const action = $("#evaluation-filters").attr('action');
  $("#evaluation-filters").attr('action', `${action}${anchor}`);
});

// Evaluación: resultados
onmount("#evaluation-filters", function () {
  const anchor = $(location).attr('hash');
  const action = $(this).attr('action');
  $(this).attr('action', `${action}${anchor}`);
});

// Configuración: escala de resultados (este código debe ser borrado cuando se elimine la vista antigua de desempeño)
$(document).on('change', '#talent_evaluation_process_score_type', function () {
  const resultScaleMaxScore = $('[data-max-score]');
  if($('#original').hasClass('hide')) {
    $('#other').addClass('hide');
    $("#other").find(":input").prop("disabled",true);

    $('#original').removeClass('hide');
    $("#original").find(":input").prop("disabled",false);
  }
  else{
    $('#original').addClass('hide');
    $("#original").find(":input").prop("disabled",true);

    $('#other').removeClass('hide');
    $("#other").find(":input").prop("disabled",false);
  }
  switch ($('#talent_evaluation_process_score_type').val()) {
    case 'porcentaje':
      resultScaleMaxScore.val(100);
      break;
    case 'nota':
    default:
      resultScaleMaxScore.val(5);
  }
});

// Competencias
onmount("#capacities-table", function () {
  function deleteSelected(url, capacitiesIds) {
    $.ajax({
      url: url,
      method: 'delete',
      data: {
        capacities_ids: capacitiesIds
      }
    });
  }
  function getCapacitiesIds() {
    const ids = [];
    $('.capacities_id:checked').each(function () {
      ids.push($(this).data('id'));
    });
    return ids;
  }
  const capacitiesLink = $('#selected_capacities');
  capacitiesLink.attr('disabled', 'true');
  capacitiesLink.on('click', function () {
    const capacitiesIds = getCapacitiesIds();
    if (capacitiesIds.length > 0) {
      if (confirm('¿Seguro desea eliminar los elementos selecionados?')) {
        deleteSelected(capacitiesLink.data('url'), capacitiesIds);
      }
    }
  });
  var count = 0;
  const maxCount = $('.capacities_id_all').data('value');
  $('.capacities_id').on('click', function () {
    count = getCapacitiesIds().length;
    capacitiesLink.attr("disabled", count == 0);
    $("#seleccionados").html(`(${count})`);
  });
  $('.capacities_id_all').on('click', function () {
    if($('.capacities_id_all').prop('checked')) {
      capacitiesLink.attr("disabled", maxCount == 0);
      $("#seleccionados").html(`(${maxCount})`);
    }
    else{
      capacitiesLink.attr("disabled", maxCount > 0);
      count = 0;
      $("#seleccionados").html(`(${count})`);
    }
  });
});

// Configuración: Seleccionar cargo a evaluar
onmount('[data-auto-select-supervisor=true]', function () {
  const selector = $(this);
  selector.on('change', function () {
    const employeeId =  selector.data('id');
    const values = selector.find(':selected');
    const firstEvaluatorBoss = $(`[data-evaluator=1][data-step-kind=boss][data-employee=${employeeId}]`);
    firstEvaluatorBoss.val(values.attr('boss_id')).trigger('change');
    const selectedRow = firstEvaluatorBoss.closest('tr');
    selectedRow.find('[data-table-attribute="division_name"]').html(values.attr('division'));
    selectedRow.find('[data-table-attribute="area_name"]').html(values.attr('area'));
    selectedRow.find('[data-table-attribute="department_name"]').html(values.attr('department'));
    selectedRow.find('[data-table-attribute="jefe"]').html(values.attr('boss_name'));
  });
});

// Configuración: validaciones para vista de participantes y evaluadores
onmount("[data-participants-form='true']", function () {
  const divAlertlForm = $('[data-modal-errors]');
  const activeStepIds = $('[data-steps]').find('option').toArray().map((item) => parseInt(item.value));
  const tableParticipant = $('[data-table-participants]').dataTable();
  const stepBossId = parseInt($('#step_boss').val());

  const validateForms = (errors) => {
    let ok = true;
    $('[data-select-form]').each(function () {
      const selectForm = $(this);
      removeInputSelect2Error(selectForm);
      if ($(this).val() === '') {
        ok = false;
        addInputSelect2Error(selectForm);
      }
    });
    if (!ok) errors.push('Se deben asignar todos los formularios por tipo de evaluación');
  };

  const atLeastOneSelected = (selectInputs) => selectInputs.find('option:selected').toArray().map((item) => item.value).filter(e => e !== '').length > 0;

  // agrupa inputs por su value
  const groupByEvaluators = (inputs) => groupBy(inputs, function (element) {
    return $(element).val();
  });

  const RepeteadEvaluatorOk = (object) => {
    let ok = true;
    for (const [key, value] of Object.entries(object)) {
      if (value.length > 1 && key !== '') {
        ok = false;
        $(value).each(function (_l, select) {
          select = $(select);
          addInputSelect2Error(select);
        });
      }
    }
    return ok;
  };

  const addErrorInputValidator = (selectValidator) => {
    if (atLeastOneSelected(selectValidator)) return false;
    addInputSelect2Error(selectValidator);
    return true;
  };

  if ($('[data-verificator]').data('verificator')) {
    // mostrar alerta si existen verificadores sin asignar
    let verificatorOk = true;
    const errorVerificator = 'Existen participantes que no tienen verificadores asignados';
    tableParticipant.$('tr').each(function (_i, trEmployee) {
      trEmployee = $(trEmployee);
      const verificator = trEmployee.find(`[data-verificator=${trEmployee.data('id')}]`);
      if (!atLeastOneSelected(verificator)) {
        verificatorOk = false;
        // marcamos el selector si no tiene seleccionado verificador
        addInputSelect2Error(verificator);
      }
    });
    if (!verificatorOk) createAlertError([errorVerificator], divAlertlForm);
  }

  const requireBossValidation = () => $('[data-validator]').length > 0;
  // valida evaluadores, validadores
  const validateEvaluators = (errors) => {
    const evaluationProcessStatusActive = $('[data-process-active]').data('process-active');
    let evaluatorOk = true;
    let validatorOk = true;
    let duplicateEvaluatorOk = true;
    const atLeastOneEvaluatorMsg = 'Se debe asignar al menos un evaluador por tipo de evaluación';
    const errorValidatorMsg = 'Se debe asignar al menos un validador por evaluador asignado';
    const duplicateEvaluator = `No se puede asignar a un participante
      el mismo evaluador más de una vez en un tipo de evaluación.`;
    removeInputSelect2Error($('[data-evaluator]'));
    removeInputSelect2Error($('[data-validator]'));
    tableParticipant.$('tr').each(function (_i, trEmployee) {
      trEmployee = $(trEmployee);
      activeStepIds.forEach(function (stepId) {
        const evaluatorsSelectByStep = trEmployee.find(`[data-employee=${trEmployee.data('id')}]`).filter(`[data-step=${stepId}]`);
        if (!atLeastOneSelected(evaluatorsSelectByStep) && evaluationProcessStatusActive) {
          evaluatorOk = false;
          // si no tiene seleccionado ningun evaluador por tipo de evaluación marcamos el evaluador 1
          addInputSelect2Error(evaluatorsSelectByStep.filter('[data-evaluator=1]'));
        }
        duplicateEvaluatorOk = RepeteadEvaluatorOk(groupByEvaluators(evaluatorsSelectByStep)) && duplicateEvaluatorOk;
        if (stepId !== stepBossId) return;
        if (!requireBossValidation()) return;
        // validación para los validadores que tienen evaluador seleccionado
        evaluatorsSelectByStep.each(function (_k, selectEvaluator) {
          selectEvaluator = $(selectEvaluator);
          if (atLeastOneSelected(selectEvaluator)) {
            const selectValidator = trEmployee.find(`[data-validator=${selectEvaluator.data('evaluator')}]`)
              .filter(`[data-employee=${selectEvaluator.data('employee')}]`);
            if (addErrorInputValidator(selectValidator)) validatorOk = false;
          }
        });
      });
    });
    if (!duplicateEvaluatorOk) errors.push(duplicateEvaluator);
    if (!evaluatorOk) errors.push(atLeastOneEvaluatorMsg);
    if (!validatorOk) errors.push(errorValidatorMsg);
  };

  $(this).submit(function (e) {
    const submitButton = $(this).find('#add-employees-to-process-button');
    const btnLabel = submitButton.find('.btn-label');
    const defaultText = btnLabel.text();

    const errors = [];

    submitButton.prop('disabled', true);
    btnLabel.text(submitButton.data('savingText'));

    validateForms(errors);
    validateEvaluators(errors);

    if (errors.length > 0) {
      createAlertError(errors, divAlertlForm);
      e.preventDefault();
      submitButton.prop('disabled', false);
      btnLabel.text(defaultText);
      return;
    }
    removeAlert(divAlertlForm);
  });
});

// Evaluación: validaciones y acciones para modal "Añadir Evaluador"
onmount('[data-add-evaluator-form]', function () {
  const formAddEvaluator = $(this);
  let action = formAddEvaluator.attr('action').split('/');
  action = action.splice(0,action.length-2).join('/');
  const divAlertModalForm = formAddEvaluator.find('[data-modal-errors=true]');

  const checkEvaluatorAndValidator = (event) => {
    const selectEvaluator = $('.selector-evaluator');
    const selectValidator = $('.selector-validator');
    if (selectEvaluator.val() === selectValidator.val()) {
      event.preventDefault();
      createAlertError(['El evaluador y el validador no pueden ser la misma persona'], divAlertModalForm);
      addInputSelect2Error(selectValidator);
    }
    else {
      removeInputSelect2Error();
      removeAlert();
    }
  };

  const updateAction = () => {
    const selectEvaluation = $('.selector-evaluation');
    formAddEvaluator.attr('action', `${action}/${selectEvaluation.val()}/add_evaluator`);
  };

  formAddEvaluator.submit(function (event) {
    updateAction();
    checkEvaluatorAndValidator(event);
  });

  onmount('[data-dependents-select]', function () {
    const principalSelect = $(this);
    const dependentsInputs = $(principalSelect.data('dependents-select'));
    dependentsInputs.attr('disabled', principalSelect.val() === '');

    principalSelect.on('change', function () {
      dependentsInputs.attr('disabled', principalSelect.val() === '');
      const needValidator = principalSelect.find('option:selected').data('validator') === true;
      $('.select-validator, .selector-validator').toggle(needValidator).attr('required', needValidator).attr('disabled', !needValidator);
    });
  });
});

// Objetivos: Modal de pesos de categorías
onmount('.balance-weights', function () {
  const formButton = $('.button-form-category_weight');
  const alert = $('.alert-category-weights');
  const self = this;
  const lastInput = $(self).find('input.weight_to_balance[type=number]:last');
  // if data option disable-only-persisted is true, only persiste
  const noDisablePersisted = !lastInput.data('no-disable-persisted') ||
  $(self).find('input.weight_to_balance[type=number]').length > 1;
  lastInput.prop('readonly', true);
  if ($(self).find('input.weight_to_balance[type=number]').length === 1) {
    lastInput[0].value = 100.0;
  }
  if((noDisablePersisted && lastInput.val() === '0.0') || lastInput.val() === '') {
    calculateLastInput();
  }
  $(self).on('change mouseup keyup', 'input.weight_to_balance[type=number]:not(:last)', function () {
    calculateLastInput();
  });
  function calculateLastInput() {
    let sum = 0;
    $(self).find('input.weight_to_balance[type=number]:not(:last)').each(function () {
      sum += +$(this).val();
    });
    if (lastInput.data('round') === 2) {
      lastInput.val(Math.round((100 - sum) * 100) / 100);
    }
    else {
      lastInput.val(100 - sum);
    }
    if (lastInput.val() < 0 || lastInput.val() > 100) {
      alert.show();
      formButton.prop('disabled', true);
    }
    else {
      alert.hide();
      formButton.prop('disabled', false);
    }
  }
});

// Objetivos: mostrar modal de pesos de categorías
onmount('[data-target^="#category-weights-modal"]', function () {
  const button = $(this);
  $('[data-open-goal-period]').on('select2:select', function () {
    const select = $(this);
    if (parseInt(select.val()) === select.data('open-goal-period')) {
      button.removeClass('disabled');
    }
    else {
      button.addClass('disabled');
    }
  });
});

// Evaluación: animar btn comenzar ev
onmount('#start-button-performance', function () {
  $(this).find('i').addClass('fa-fw fa-spin');
});

// Evaluación: confirmar feedback y apelación
function validEmails(emails) {
  const ammountCommas = countOccurrences(emails, ',');
  const ammountAtSign = countOccurrences(emails, '@');
  if (ammountAtSign > 1 && (ammountAtSign !== (ammountCommas + 1))) {
    warning('Los correos electrónicos deben estar separados por coma');
    return false;
  }
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const invalidEmails = emails.split(',').filter(email => !regex.test(email.trim()));
  if (invalidEmails.length > 0) {
    warning(`El correo electrónico ingresado no es válido`);
    return false;
  }
}

// Evaluación: confirmar feedback y apelación
function countOccurrences(string, char) {
  const regex = new RegExp(char, 'g');
  const matches = string.match(regex);
  return matches ? matches.length : 0;
}

onmount('#confirm-feedback-form', async function (obj) {
  const form = $(this);
  const selector = form.find('#talent_employee_evaluation_feedback_approved');
  const commentLabel = form.find('#label-comentario');

  await import('../../../../../../../app/assets/javascripts/vendor/select2.js');
  const { CKeditorVersion, loadCKeditor } = await import('@assets/javascripts/components/ckeditor/load');
  if (CKeditorVersion() === '4') await loadCKeditor(this, obj);
  selector.select2({ minimumResultsForSearch: Infinity, width: '50%' });

  selector.change(function () {
    const option = selector[0].options[selector[0].selectedIndex].value;
    option === 'true' ? commentLabel.html('Comentario') : commentLabel.html('Comentario *');
  });

  form.submit(function () {
    const option = selector[0].options[selector[0].selectedIndex].value;
    const text = CKeditorVersion() === '4' ? CKEDITOR.instances['talent_employee_evaluation_message'].getData() :
      $('talent_employee_evaluation_message').val();
    if (option == 'false' && text == '') {
      warning('El campo comentario es obligatorio');
      return false;
    }
    const emails = form.find('#talent_employee_evaluation_appeal_recipients').val();
    if (emails !== '') {
      return validEmails(emails);
    }
  });

  selector.on('change', (event) => {
    const { value } = event.target;
    $(this).find("input[name='talent_employee_evaluation[appeal_status]']").attr('disabled', value === 'true');
    $(this).find("input[name='talent_employee_evaluation[appeal_status]']").prop('checked', false);
    if (value === 'true') {
      $(this).find('#feedback-appeal-data').css('display', 'none');
    }
  });
});

// Evaluación: confirmar apelación
onmount('#confirm-appeal-form', async function (obj) {
  const { loadCKeditor, CKeditorVersion } = await import('@assets/javascripts/components/ckeditor/load');
  if (CKeditorVersion() === '4') await loadCKeditor(this, obj);
  const form = $(this);
  const selector = form.find('#talent_employee_evaluation_appeal_approved');
  const commentLabel = form.find('#label-comentario');

  form.submit(function () {
    const option = selector[0].options[selector[0].selectedIndex].value;
    const text = CKeditorVersion() === '4' ? CKEDITOR.instances['talent_employee_evaluation_message'].getData() :
      $('talent_employee_evaluation_message').val();
    if (option === 'false' && text === '') {
      warning("El campo comentario es obligatorio para la opción 'No conforme'");
      return false;
    }
    const emails = form.find('logbook_appeal_resolution_recipients').val();
    if (emails !== '') {
      return validEmails(emails);
    }
  });

  selector.on('change', (event) => {
    const { value } = event.target;
    if (value === 'true') {
      commentLabel.removeClass('required');
      commentLabel.text('Comentario');
    }
    else {
      commentLabel.addClass('required');
      commentLabel.text('Comentario *');
    }
  });
});

// Evaluación: porcentaje de completitud
onmount('#edit_attempt', function () {
  const completionData = $('#completion-data').data('completion-data');
  const calculator = new CompletionPercentageCalculator(this, completionData);
  calculator.target($('.progress-bar')[0], function (completionPercentage) {
    $('.progress-bar').css('width', `${completionPercentage}%`);
    $('.progress-bar-primary').text(`${completionPercentage.toFixed(1)}%`);
  }).listen();
});

// Configuración: editar participantes
onmount("[data-submit-per-page='true']", function () {
  const $editParticipantsForm = $(this);
  onmount('[data-submit-per-page] .page-link', function () {
    $(this).click(async function () {
      $.ajax({
        type: 'POST',
        url: $editParticipantsForm.attr('action'),
        data: $editParticipantsForm.serialize().replace(/&evaluator%5B%5D=|&validator%5B%5D=/gm, ''),
        success: (data) => {
          notify.clear();
          const saved = data.status === 200;
          saved ?
            notify.notice(data.message) :
            notify.error(data.message);
        },
      });
    });
  });
});

function prepareAndEnableExport() {
  const canvas = document.getElementById('talent-evaluation-process-capacities-map-graph');
  const inputField = document.getElementById('screenshot-field');
  const exportButton = document.getElementById('export-evaluation-report-button');
  if (exportButton) {
    if (canvas && inputField) {
      canvas.toBlob(function (blob) {
        const reader = new FileReader();
        reader.onload = function () {
          const base64Image = reader.result.split(',')[1];
          inputField.value = base64Image;
          if (inputField.value) {
            exportButton.disabled = false;
          }
        };
        reader.readAsDataURL(blob);
      }, 'image/png');
    }
    else {
      exportButton.disabled = false;
    }
  }
}

onmount('#export-evaluation-report-button', function () {
  setTimeout(prepareAndEnableExport, 3000);
});

// Objetivos
onmount("[data-goals-validation='true']", function () {
  const form = $(this);
  const submitBtn = form.find("button[type='submit']");
  const categoryInput = form.find("select[data-disable-if-changed='true']");
  const weightInput = form.find("input[data-disable-on-validation='true']");
  const initialCategory = categoryInput.val();
  const initialWeight = weightInput.val();
  const swalTitle = form.attr('data-validation-swal-title');
  const swalMessage = form.attr('data-validation-swal-message');
  weightInput.on('change keyup focus unfocus', function () {
    submitBtn.attr('disabled', !(parseFloat($(this).val()) <= 100.0 && parseFloat($(this).val()) >= 0.0));
  });
  categoryInput.change(function () {
    const isInitial = categoryInput.val() === initialCategory;
    weightInput.val('0');
    weightInput.attr('disabled', !isInitial);
  });
  form.on('submit', async function (e) {
    if(initialWeight === weightInput.val() || parseFloat(weightInput.val()) === 0.0) return true;
    e.preventDefault();
    await swalActionMessage(
      swalTitle,
      swalMessage
    );
    form.off('submit');
    form.submit();
  });
});

onmount('.limit-by-area', function () {
  const checkbox = $(this).find('input[type="checkbox"]');
  const destroyField = $(this).find('input[data-destroy-input="true"]');
  const selector = $(this).find('div.area-select');

  checkbox.on('change', () => {
    if(checkbox.is(':checked')) {
      selector.find('select').removeAttr('disabled');
      selector.show('slow');
      destroyField.val(false);
    }
    else {
      selector.find('select').attr('disabled', true);
      selector.hide('slow');
      destroyField.val(true);
    }
  });
});
