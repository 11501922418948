/** @file Manejo de firmas dinámicas en el formulario de carga de documentos de empleados */
import onmount, { $ } from 'onmount';

onmount('#new_employee_file', function () {
  const $addBtn = $('#add-new-signee');
  const $container = $('#document_template_needed_signatures_sortable');
  const $orderNumbersContainer = $('#sortable_order_number');
  let fields = $container.find('.signature-field:not(.hidden)').length;
  const $isVisible = $('#employee_file_is_visible');
  const $startWorkflow = $('#employee_file_start_workflow');
  const $label = $('label[for="employee_file_start_workflow"]');

  if (!$addBtn.length || !$container.length || !$orderNumbersContainer.length) return;

  const hiddenFields = {
    employeeSign: $('#employee_sign_hidden'),
    firstCompanySignee: {
      required: $('#requires_first_company_signee_hidden'),
      signee: $('#first_signee_hidden'),
      other: $('#first_signee_other_hidden'),
    },
    secondCompanySignee: {
      required: $('#requires_second_company_signee_hidden'),
      signee: $('#second_signee_hidden'),
      other: $('#second_signee_other_hidden'),
    },
  };

  function generateUniqueId(base) {
    return `${base}_${new Date().getTime()}_${Math.random().toString(36).substr(2, 5)}`;
  }

  function initializeSelect2(element) {
    const $element = $(element);
    if ($element.hasClass('select2-hidden-accessible')) return;
    $element.select2();
  }

  function loadSignees(companySignee, signee) {
    companySignee.required.val('1');
    companySignee.signee.val(
      `${signee.personId}::${signee.type}::${signee.position}`
    );
    if (signee.type === 'other') {
      companySignee.other.val(signee.personId);
    }
  }

  function updateHiddenFields() {
    const visibleFields = $container.find('.signature-field:not(.hidden)');

    hiddenFields.employeeSign.val('0');
    hiddenFields.firstCompanySignee.required.val('0');
    hiddenFields.firstCompanySignee.signee.val('');
    hiddenFields.firstCompanySignee.other.val('');
    hiddenFields.secondCompanySignee.required.val('0');
    hiddenFields.secondCompanySignee.signee.val('');
    hiddenFields.secondCompanySignee.other.val('');
    const companySignees = [];

    visibleFields.each((index, field) => {
      const $field = $(field);
      const $typeSelect = $field.find('.signature_type_select');
      if (!$typeSelect.length) return;

      let $personSelect;
      switch ($typeSelect.val()) {
        case 'other':
          $personSelect = $field.find('.toggle_other select[name$="[person_id]"]');
          break;
        case 'legal_agent':
          $personSelect = $field.find('.toggle_legal select[name$="[person_id]"]');
          break;
        case 'supervisor':
          $personSelect = $field.find('.toggle_supervisor select[name$="[person_id]"]');
          break;
        default:
          $personSelect = $();
      }

      const personId = $personSelect.length ? $personSelect.val() : '';
      const position = index + 1;

      if ($typeSelect.val() === 'employee') {
        hiddenFields.employeeSign.val(`1::${position}`);
      }
      else {
        companySignees.push({ personId, type: $typeSelect.val(), position });
      }

      const $numberElement = $field.find('.position-number');
      if ($numberElement.length) $numberElement.text(position);

      const $positionInput = $field.find('input[name$="[position]"]');
      if ($positionInput.length) $positionInput.val(position);
    });

    if (companySignees.length > 0) {
      loadSignees(hiddenFields.firstCompanySignee, companySignees[0]);
    }

    if (companySignees.length > 1) {
      loadSignees(hiddenFields.secondCompanySignee, companySignees[1]);
    }
  }

  function updateOrderNumbers() {
    $orderNumbersContainer.empty();
    const visibleFields = $container.find('.signature-field:not(.hidden)');

    visibleFields.each((index, field) => {
      const position = index + 1;
      const $numberElement = $('<h1>').text(position).addClass('position-number');
      $orderNumbersContainer.append($numberElement);
      const $fieldNumber = $(field).find('.position-number');
      if ($fieldNumber.length) $fieldNumber.text(position);
    });
    updateHiddenFields();
  }

  $addBtn.on('click', function (e) {
    e.preventDefault();
    const hiddenFieldsList = $container.find('.signature-field.hidden');

    if (hiddenFieldsList.length > 0) {
      const $hiddenField = $(hiddenFieldsList[0]);
      $hiddenField.removeClass('hidden');
      fields++;
      $container.append($hiddenField);
      $hiddenField
        .find('.signature_type_select, select[name$="[person_id]"]')
        .each(function () {
          const newId = generateUniqueId($(this).attr('name'));
          $(this).attr('id', newId);
          initializeSelect2(this);
        });
      updateOrderNumbers();
    }
    if (fields === 1) $('#start_workflow_wrapper').show('slow');
    if (fields >= 3) $addBtn.addClass('hidden');
  });

  $container.on('click', '[data-remove-signee]', function (e) {
    e.preventDefault();
    const $fieldToRemove = $(this).closest('.signature-field');

    if ($fieldToRemove.length) {
      $fieldToRemove.find('input, select').val('').trigger('change');
      $fieldToRemove.find('.person-select-wrapper').addClass('hidden');
      $fieldToRemove.addClass('hidden');
      fields--;
      $addBtn.removeClass('hidden');
      if (fields === 0) $('#start_workflow_wrapper').hide('slow');
      updateOrderNumbers();
    }
  });

  $container.on('change', '.signature_type_select', function () {
    const $wrapper = $(this).closest('.signature-field');
    const signatureType = $(this).val();
    $wrapper.find('.person-select-wrapper').addClass('hidden');

    switch (signatureType) {
      case 'other':
        $wrapper.find('.toggle_other').removeClass('hidden');
        break;
      case 'legal_agent':
        $wrapper.find('.toggle_legal').removeClass('hidden');
        break;
    }
    updateHiddenFields();
  });

  function toggleStartWorkflow() {
    if ($isVisible.is(':checked')) {
      $label.removeClass('text-muted');
      $label.addClass('text-dark');
      $startWorkflow.prop('disabled', false);
    }
    else {
      $label.removeClass('text-dark');
      $label.addClass('text-muted');
      $startWorkflow.prop('disabled', true).prop('checked', false);
    }
  }
  $isVisible.on('change', toggleStartWorkflow);

  $container.on('change', 'select[name$="[person_id]"]', function () {
    updateHiddenFields();
  });

  $container.on('drop', function (event) {
    event.preventDefault();
    updateOrderNumbers();
  });

  updateOrderNumbers();

  $container
    .find('.signature_type_select, select[name$="[person_id]"]')
    .each(function () {
      initializeSelect2(this);
    });
});

onmount('#new_employee_file', async function () {
  const $employeeFileUrlSelect = $('#employee_file_file_url');
  const $signeeWrapperSelect = $('#file_upload_signees_wrapper');
  const $reviewerWrapperSelect = $('#file_upload_reviewer_wrapper');
  $employeeFileUrlSelect.on('change', () => {
    if($employeeFileUrlSelect.val().endsWith('.pdf')) {
      $reviewerWrapperSelect.show('slow');
      $signeeWrapperSelect.show('slow');
    }
    else {
      $reviewerWrapperSelect.hide('slow');
      $signeeWrapperSelect.hide('slow');
    }
  });

  /*Amplitude Analitics*/
  $('#upload_employee_file_button').on('click', (e) => {
    $(e.target).trigger('amplitudeTracking', { message: 'document_upload_button_clicked', data:
     { source: 'ficha_empleado', quantity: 1 } });
  });
});
