/** @file
 * Helper para la creacion de IDs aleatorios
 */

/**
 * Genera un identificador aleatorio en formato de cadena alfanumérica.
 * @returns {string} - Un ID aleatorio.
 */
export function getRandomId() {
  return Math.random()
    .toString(36)
    .substring(2);
}
