/** @file comportamiento formulario de edicion de haberes **/

import onmount, { $ } from 'onmount';

let lastEnabledState = false;

function updateTooltip($container, show) {
  if (show) {
    $container.tooltip({
      placement: 'top',
      trigger: 'hover',
      title: $('#declarar-dt-bono').data('tooltip-message'),
    });
  }
  else {
    $container.tooltip('dispose');
  }
}

function shouldEnableCheckbox() {
  const bonoRebaja = $('#bono_rebaja').val();
  const bonoTipoRemuneracion = $('#bono_tipo_remuneracion').val();
  const bonoTermino = $('#checkbox_termino').is(':checked');

  return (bonoRebaja === 'Fijo' || bonoRebaja === 'Formulado') &&
         (bonoTipoRemuneracion === 'remuneracion_fija') &&
         !bonoTermino;
}

function updateCheckboxState() {
  const $checkbox = $('#bono_declarar_dt');
  const $container = $('#declarar-dt-container');
  const shouldEnable = shouldEnableCheckbox();

  if (shouldEnable) {
    $checkbox.prop('disabled', false);
    if (lastEnabledState) {
      $checkbox.prop('checked', true);
    }
    updateTooltip($container, false);
  }
  else {
    if (!$checkbox.prop('disabled')) {
      lastEnabledState = $checkbox.prop('checked');
    }
    $checkbox.prop('disabled', true).prop('checked', false);
    updateTooltip($container, true);
  }
}

onmount('#declarar-dt-bono', function () {
  const bonoRebaja = $('#bono_rebaja');
  const bonoTipoRemuneracion = $('#bono_tipo_remuneracion');
  const bonoTermino = $('#checkbox_termino');

  bonoRebaja?.on('select2:select', updateCheckboxState);
  bonoTipoRemuneracion?.on('select2:select', updateCheckboxState);
  bonoTermino?.change(updateCheckboxState);

  updateCheckboxState();
});
