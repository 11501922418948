/* eslint-disable buk/no-static-import */
/** @file métodos para configuraciones para el módulo de talento
*/

/**
 * añade una clase de error a un campo Select2 y muestra un mensaje de error
 * @param {jQuery} select2 - elemento Select2 al que se aplicará el error
 * @param {string} [msg=''] - mensaje de error opcional a mostrar
 */
export function addInputSelect2Error(select2, msg = '') {
  select2.siblings('.select2-container').addClass('border-danger-700');
  const wrapper = select2.parent('div');
  if (wrapper.hasClass('has-error')) return;
  wrapper.addClass('has-error');
  if (msg !== '') wrapper.append(`<span class="help-block">${msg}</span>`);
}

/**
 * borra la clase de error de un campo Select2 y elimina el mensaje de error
 * @param {jQuery} select2 - elemento Select2 al que se le quitará el error
 */
export function removeInputSelect2Error(select2) {
  select2.siblings('.select2-container').removeClass('border-danger-700');
  select2.parent('div').removeClass('has-error');
  select2.parent('div').find('span[class="help-block"]').remove();
}

export const removeAlert = (div) => {
  div.empty();
};

export const showErrors = (errors) => {
  let list = '';
  errors.forEach(function (error) {
    list += `<li class="text-danger"><strong>${error}</strong></li>`;
  });
  return list;
};

/**
 * crea y muestra una alerta de error
 * @param {string[]} errors - lista de mensajes de error a mostrar
 * @param {jQuery} div - elemento donde se insertará la alerta
 */

export const createAlertError = (errors, div) => {
  removeAlert(div);
  div.append(`
      <div class="row">
        <div class="col-md-12">
          <div class="alert bg-danger error-explanation">
            <ul data-error-list="true">
              ${showErrors(errors)}
            </ul>
          </div>
        </div>
      </div>
    `);
};
