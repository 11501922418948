/** @file Comportamientos de la vista de términos de Registros DT **/

import onmount, { $ } from 'onmount';
import Intercooler from '../../../../../../../../../../app/assets/javascripts/lib/intercooler';

/**
 * Realiza los preparativos necesarios para poder hacer la consulta asíncrona de intercooler
 */
function intercoolerBeforeSend() {
  $('#upload_contract_ends_button-button').prop('disabled', true);
  $('#intercooler-contract-end-upload-content').empty();
  $('#intercooler-contract-end-loader').removeClass('hide');

  const table = $('#datatable-massive-contract-jobs-ends')[0];
  const checkboxes = table.querySelectorAll(`input:checked`);
  const jobIds =  Array.from(checkboxes).map((item) => item.value);
  const form = document.getElementById('validate_contract_end_upload_form');
  form.querySelectorAll('input[name="job_id[]"]').forEach((input) => input.remove());

  jobIds.forEach((id) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'job_id[]';
    input.value = id;
    form.appendChild(input);
  });
}

onmount('#index-datatable-contract-end-massive-contracts', async function () {
  const { evaluateDropdownOptions } = await import('../../helpers/datatable');
  const { setFilters } = await import('../../helpers/filters');

  /**
   * Evalúa las opciones del dropdown de términos
   */
  evaluateDropdownOptions($(this));

  /*
   * Implementación de los filtros en términos
  */
  setFilters('#datatable-massive-contract-jobs-ends', [
    '#contract_ends_filters_empresa_id',
    '#contract_ends_filters_division_id',
    '#contract_ends_filters_start_date',
    '#contract_ends_filters_end_date',
    '#contract_ends_filters_role_id',
    '#contract_ends_filters_status',
  ], 'contract_ends_filters');

  /**
   * Una vez que el modal de validación de carga de téminos está montado,
   * se la agrega el comportamiento necesario para intercooler
   */
  onmount('#contract_end_upload_modal', async () => {
    const target = document.getElementById('contract_end_upload_modal');

    const observer = new MutationObserver(() => {
      if (!target.classList.contains('show')) { return; }

      intercoolerBeforeSend();

      const intercoolerElement = document.getElementById('contract_end_modal_intercooler');

      Intercooler.triggerRequest($(intercoolerElement));
    });

    observer.observe(target, {
      attributes: true,
      attributeFilter: ['class'],
    });
  });

  /**
   * Se define como el checkbox habilitará el botón para cargar los términos
   */
  $(document).on('change', '[data-form-enable]', function () {
    const targetSelector = $(this).data('form-enable');
    const target = $(targetSelector);

    if (this.checked) {
      target.prop('disabled', false);
    }
    else {
      target.prop('disabled', true);
    }
  });
});
