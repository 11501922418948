/** @file
 *
 * Contiene lógica para el envío del formulario con todos los datos necesarios para
 * ejecutar una acción grupal.
 *
 * Requisitos:
 * - Un elemento con los atributos 'data-fiji-multi-select-form' con el identificador del formulario que
 * será usado para enviar la acción, y 'data-multi-select-source' con el identificador del elemento con los datos
 * de la tabla. También debe tener los atributos para mostrar el mensaje de confirmación.
 *
 * Funcionamiento:
 * 1. Cada vez que se hace click a una acción grupal, se combina el estado actual de la tabla con la información del "Seleccionar todo".
 * 2. Se muestra el mensaje de confirmación de la acción.
 * 3. Si el usuario acepta, se envía el formulario con los datos necesarios para ejecutar la acción.
 *
 */
import onmount from 'onmount';
import ModalBuilder from '../../../../../../../app/assets/javascripts/lib/modal-builder';

/**
 * Método para extraer los datos del formulario que será enviado al controlador de
 * la acción.
 *
 * @param {String} sourceId selector del elemento que contiene los datos a extraer.
 *
 */
function extractFormData(sourceId) {
  const source = $(sourceId);
  const storeInput = source.find('[data-dt-saved-selects]');
  const storeInputValue = storeInput.val();
  const recordIds = storeInputValue === 'all' ? 'all' : storeInputValue.split(',');
  const elementsCurrentQuery = storeInput.data('elementsCurrentQuery');

  return { 'record_ids': recordIds, 'elements_current_query': elementsCurrentQuery };
}

/**
 * Método para ejecutar el envío del formulario a la acción.
 *
 * @param {String} formId identificador del formulario que será enviada a la acción.
 * @param {String} formBodySource identificador del elemento que contiene el estado actual de la tabla.
 *
 */
const sendForm = (formId, formBodySource) => {
  const form = $(formId);
  const formBody = extractFormData(formBodySource);

  Object.entries(formBody).forEach(([name, value]) => {
    form.append($('<input type="hidden">').attr({ name, value }));
  });

  form[0].requestSubmit();
};

onmount('[data-fiji-multi-select-form]', async function () {
  const { showConfirmationDialog } = await import(
    '../../../../../core_app/app/assets/javascripts/behaviors/confirm-box');
  const actionLink = $(this);

  actionLink.on('click', async (e) => {
    e.preventDefault();
    if (e.target.classList.contains('disabled')) return false;

    const href = actionLink.data('href');
    const formBodySource = this.getAttribute('data-multi-select-source');
    if (!href) {
      const { value: confirmed } = await showConfirmationDialog(this, false);
      if (confirmed) {
        const formId = this.getAttribute('data-fiji-multi-select-form');
        sendForm(formId, formBodySource);
      }

      return;
    }

    const title = actionLink.data('modalTitle');
    const size = actionLink.data('modalSize');
    const method = actionLink.data('modalMethod');

    const params = extractFormData(formBodySource);
    const modalBuilder = new ModalBuilder();
    modalBuilder.setSize(size).show().loadFromRemote(href, params, title, method);
  });
});
