/** @file
 * Archivo que contiene lógica para abrir y cerrar nodos en el organigrama
 */

/**
 * Muestra los nodos hijos de un nodo.
 * Debe llamarse solo cuando los hijos del nodo NO están visibles
 */
const customShowChildren = function customShowChildren($node, _$chart, firstOpenedLevel) {
  $node.closest('.hierarchy').removeClass('isChildrenCollapsed').addClass('isOpen');
  // Se agrega porque la librería hace un toggle al ícono
  const thisLevel = Number($node[0].getAttribute('data-level'));
  if($node.children('.verticalEdge').hasClass('oci-chevron-up') && firstOpenedLevel === thisLevel) {
    $node.children('.verticalEdge').removeClass('oci-chevron-up').addClass('oci-chevron-down');
  }
  var $levels = $node.siblings('.nodes');
  var isVerticalDesc = $levels.is('.vertical');
  var $animatedNodes = isVerticalDesc
    ? $levels.removeClass('hidden').find('.node').filter(_$chart.isVisibleNode.bind(_$chart))
    : $levels.removeClass('hidden').children('.hierarchy').find('.node:first')
      .filter(_$chart.isVisibleNode.bind(_$chart));
  if (!isVerticalDesc) {
    $animatedNodes.closest('.hierarchy').removeClass('isCollapsedDescendant');
    $levels.find('.hierarchy.isOpen > div').filter((_index, element) => {
      customShowChildren($(element), _$chart, firstOpenedLevel);
    });
  }
  _$chart.repaint($animatedNodes.get(0));
  $animatedNodes.addClass('sliding').removeClass('slide-up').eq(0).one('transitionend',
    { 'node': $node, 'animatedNodes': $animatedNodes },
    _$chart.showChildrenEnd.bind(_$chart));
};

/**
   * Oculta los nodos hijos de un nodo.
   * Debe llamarse solo cuando los hijos del nodo están visibles
   */
const customHideChildren = function customHideChildren($node, _$chart) {
  $node.closest('.hierarchy').addClass('isChildrenCollapsed');
  $node.closest('.hierarchy.isOpen').removeClass('isOpen');
  var $lowerLevel = $node.siblings('.nodes');
  _$chart.stopAjax($lowerLevel);
  var $animatedNodes = $lowerLevel.find('.node').filter(function (_index, element) {
    return Number(element.getAttribute('data-level')) === (Number($node[0].getAttribute('data-level')) + 1);
  });

  var $lowerNextLevel = $lowerLevel.find('.node').filter(function (_index, element) {
    return Number(element.getAttribute('data-level')) > (Number($node[0].getAttribute('data-level')) + 1);
  });
  $lowerNextLevel.addClass('slide-up');
  $animatedNodes.addClass('sliding slide-up').eq(0).one(
    'transitionend',
    { 'animatedNodes': $animatedNodes, 'lowerLevel': $lowerLevel, 'node': $node },
    _$chart.hideChildrenEnd.bind(_$chart)
  );
};

export { customHideChildren, customShowChildren };
