/** @file Comportamiento para el widget de grupos de cards */
import onmount from 'onmount';

const MODES = { FIXED: 'fixed', ORDERABLE: 'orderable', GROUPABLE: 'groupable' };

/**
 * Actualiza las posiciones de las cards activas según el modo del widget
 * @param {jQuery} $widget - El contenedor del widget
 */
function updatePositions($widget) {
  const widget = $widget[0];
  const mode = widget.dataset.mode;

  const $activeCards = widget.querySelectorAll('.card-item-wrapper:has(.card-checkbox input:checked)');
  const $inactiveCards = widget.querySelectorAll('.card-item-wrapper:not(:has(.card-checkbox input:checked))');

  $inactiveCards.forEach(card => {
    card.querySelector('.position').value = 0;
  });

  if (mode === MODES.GROUPABLE) {
    $activeCards.forEach(card => {
      const group = card.closest('.group');
      card.querySelector('.position').value = group.dataset.position;
    });
    return;
  }

  if (mode === MODES.ORDERABLE) {
    $activeCards.forEach((card, index) => {
      card.querySelector('.position').value = index + 1;
    });
  }
}

/**
 * Mueve una card a una nueva posición dentro de un contenedor
 * @param {jQuery} $card - La card a mover
 * @param {jQuery} $toElement - El contenedor destino
 * @param {number} position - La nueva posición
 * @param {jQuery} $widget - El contenedor del widget
 */
function moveCard($card, $toElement, position, $widget) {
  const card = $card[0];
  const toElement = $toElement[0];
  toElement.appendChild(card);
  card.querySelector('.position').value = position;
  updatePositions($widget);
}

/**
 * Muestra u oculta el contenido de una card
 * @param {jQuery} $wrapper - El contenedor de la card
 * @param {boolean} checked - Si la card está seleccionada
 */
function toggleCardBody($wrapper, checked) {
  const $body = $wrapper.find('.card-item-body');
  checked ? $body.show('normal') : $body.hide('normal');
}

/**
 * Maneja el estado arrastrable de una card según su estado de selección y modo del widget
 * @param {jQuery} $wrapper - El contenedor de la card
 * @param {boolean} checked - Si la card está seleccionada
 */
function handleCardDraggable($wrapper, checked) {
  const $dragIndicator = $wrapper.find('.drag-indicator');

  $wrapper.toggleClass('drag', checked).toggleClass('no-drag', !checked);
  checked ? $dragIndicator.show('normal') : $dragIndicator.hide('normal');
}

/**
 * Maneja el evento de cambio de un checkbox
 * @param {Event} event - El evento de cambio
 */
function handleCheckboxChange(event) {
  const $wrapper = $(event.target).closest('.card-item-wrapper');
  const $widget = $wrapper.closest('[data-widget="card-group"]');
  const mode = $widget.data('mode');
  const checked = event.target.checked;

  toggleCardBody($wrapper, checked);
  if (mode === MODES.FIXED) return;

  const $enablerCheckbox = $widget.find('[data-toggle-sortable] input[type="checkbox"]');
  const isEnablerChecked = $enablerCheckbox.length === 0 || $enablerCheckbox.is(':checked');
  handleCardDraggable($wrapper, checked && isEnablerChecked);

  if (mode === MODES.GROUPABLE) {
    checked
      ? $widget.find('.group').removeClass('hide')
      : isEnablerChecked && moveCard($wrapper, $widget.find('#group_0'), 0, $widget);
  }
  else if (mode === MODES.ORDERABLE) {
    const activeCardsCount = $widget.find('#group_active .card-item-wrapper').length;
    const targetGroup = checked ? '#group_active' : '#group_inactive';
    const position = checked ? 0 : activeCardsCount + 1;

    moveCard($wrapper, $widget.find(targetGroup), position, $widget);
  }

  updatePositions($widget);
}

/**
 * Inicializa la funcionalidad de ordenamiento
 * @param {jQuery} $widget - El contenedor del widget
 */
function initSortable($widget) {
  const mode = $widget.data('mode');
  if (mode === MODES.FIXED) return;

  const selector = mode === MODES.GROUPABLE ? '#card-group-sortable' : '.sortable-container';
  const $sortable = $widget.find(selector);
  if (!$sortable.length) return;

  $sortable.sortable({
    items: '.drag',
    handle: '.drag-indicator',
    connectWith: '.sortable[data-group="cards"]',
    update: () => updatePositions($widget),
  }).disableSelection();
}

/**
 * Maneja el evento de cambio del checkbox de ordenamiento
 * @param {Event} event - El evento de cambio
 */
function handleSortableToggle(event) {
  const $widget = $(event.target).closest('[data-widget="card-group"]');
  const mode = $widget.data('mode');
  if (mode === MODES.FIXED) return;

  const isChecked = $(event.target).is(':checked');
  const $groups = $widget.find('.group');
  const $groupLabels = $widget.find('.group-label');

  $widget.find('.card-item-wrapper').each(function () {
    const $card = $(this);
    const $cardCheckbox = $card.find('.card-checkbox input[type="checkbox"]');
    const isCardChecked = $cardCheckbox.is(':checked');

    if (mode === MODES.GROUPABLE) {
      $groupLabels.toggleClass('hide', !isChecked);

      isChecked
        ? $groups.removeClass('hide')
        : $widget.find('.card-item-wrapper').each(function () {
          moveCard($(this), $widget.find('#group_0'), 0, $widget);
        });
    }

    handleCardDraggable($card, isChecked && isCardChecked);
  });

  isChecked ? updatePositions($widget) : $widget.find('.position').val(0);
}

// Inicializa el componente cuando se monta
onmount('[data-widget="card-group"]', function () {
  const $widget = $(this);

  // Delegación de eventos simple con namespace
  $widget.on('change.cardGroup', '.card-checkbox input, [data-toggle-sortable] input', function (event) {
    const isSortableToggle = $(event.target).closest('[data-toggle-sortable]').length > 0;
    isSortableToggle ? handleSortableToggle(event) : handleCheckboxChange(event);
  });

  // Inicializa el ordenamiento para esta instancia
  initSortable($widget);

  // Limpieza cuando se desmonta
  return function () {
    const mode = $widget.data('mode');
    $widget.off('.cardGroup');

    if (mode !== MODES.FIXED) {
      const selector = mode === MODES.GROUPABLE ? '#card-group-sortable' : '.sortable-container';
      const $sortable = $widget.find(selector);
      if ($sortable.length) {
        $sortable.sortable('destroy');
      }
    }
  };
});
