/** @file Comportamientos del formulario de creación / edición del servicio accounting_structure */
import onmount from 'onmount';
import * as notify from '../lib/buk/notice.js';
import { toCode } from '../lib/string';

onmount('#formula-button', function () {
  $(document).on('after.success.ic', function (_event, elt, data) {
    const formulaField = $(elt).data('formula-field-value');
    const parsedData = JSON.parse(data);
    if (!formulaField) return;
    const monacoEditorFormula = document.getElementById(`formula_${formulaField}`);
    if (!monacoEditorFormula) return;
    const textArea = monacoEditorFormula.querySelector('editor-formulas-accounting').firstChild;
    if (textArea && textArea.nodeType === Node.ELEMENT_NODE && textArea.tagName === 'TEXTAREA') {
      const formulaVal = textArea.value;
      const field = `#input-formula-${formulaField}`;
      $(field).attr('value', formulaVal);
    }
    if (parsedData.status === 'error') {
      notify.clear();
      $(`#error_${formulaField}`)
        .html(notify.error(parsedData.message));
      return;
    }
    else {
      $(`#formulador_accounting_modal-${formulaField}`).modal('hide');
      notify.clear();
      buildNotify('Datos añadidos correctamente');
    }
  });
});

$(document).on('turbolinks:load', function () {

  // escribe el código cuando se modifica el nombre
  $('#accounting_structure_nombre').keyup(handleNameChange);

  // actualiza los modal de atributos en base el tipo de columna
  onmount('select[data-id]', function () {
    const ColumnType = $(this);
    ColumnType.on('change', function () {
      var id = $(this).data('id');
      var isMonto = $(this).val() === 'monto';
      $('#container_modal_ammount_' + id).toggle(isMonto);
      $('#container_modal_default_' + id).toggle(!isMonto);
    });
    //se usa para aplicar el evento cuando se entra por primera vez
    ColumnType.trigger('change');
  });

  // actualiza vista de separador en base al tipo de extension
  onmount('#accounting_structure_extension_archivo', function () {
    const ext = $(this);
    ext.on('change', function () {
      const separator = (this.value === 'csv' || this.value === 'txt');
      $('.accounting_structure_separador').toggle(separator);
    });
    //se usa para aplicar el evento cuando se entra por primera vez
    ext.trigger('change');
  });

});

/**
 * Asigna el valor del campo "Código" al campo "Nombre" convertido a código
 * cada vez que se modifica el campo "Nombre".
 */
function handleNameChange() {
  $('#accounting_structure_codigo').val(toCode($('#accounting_structure_nombre').val()));
}

/**
 * Muestra una notificación en la parte superior de la pantalla.
 * La notificación se borra automáticamente después de 3 segundos.
 * @param {string} notifyText - El texto que se mostrará en la notificación.
 */
function buildNotify(notifyText) {
  notify.notice(notifyText);
  setTimeout(() => {
    notify.clear();
  }, 3000);
}
