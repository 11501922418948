/** @file Comportamientos de la vista de contratos y anexos de Registros DT **/

import onmount, { $ } from 'onmount';

onmount('#index-datatable-massive-contracts', async function () {
  const { evaluateDropdownOptions } = await import('../../helpers/datatable');
  const { setFilters } = await import('../../helpers/filters');

  /**
   * Evalúa las opciones del dropdown de contratos para activar/desactivar las acciones.
   */
  evaluateDropdownOptions($(this));

  /*
  * Implementación de los filtros en contratos
  */
  setFilters('#datatable-massive-contracts-jobs', [
    '#contracts_filters_empresa_id',
    '#contracts_filters_division_id',
    '#contracts_filters_start_date',
    '#contracts_filters_end_date',
    '#contracts_filters_role_id',
    '#contracts_filters_status',
    '#contracts_filters_job_status',
  ], 'contracts_filters');
});
