/** @file Maneja el comportamiento de los links de acciones de la tabla de documentos */
import onmount from 'onmount';

onmount('#employees_table_modal', function () {
  const $generateFilesButton = $('#generate_files_button');
  const $documentTemplateSelect = $('#multiple_document_document_template_id');
  const $hiddenDocumentsGeneration = $('#multiple_document_hidden_documents_generation');
  const $startWorkflow = $('#multiple_document_start_workflow');
  const $warningMessage = $('#warning_message_for_checkbox_visibility');
  var selectedEmployeesRows = $('.generate_documents').DataTable().rows('.selected').nodes().toArray();
  var selectedEmployees = [];

  const configurableTemplates = getconfigurableTemplates(
    $documentTemplateSelect
  );
  var selectedTemplateId = getSelectedTemplateId($documentTemplateSelect);
  let checkedDocumentVisible = $hiddenDocumentsGeneration?.prop('checked');
  let selectedTemplateAmplitudeData = getSelectedTemplateAmplitudeData($documentTemplateSelect);

  $documentTemplateSelect.on('change', function () {
    selectedTemplateId = getSelectedTemplateId($documentTemplateSelect);
  });

  $generateFilesButton.on('click', async function (e) {
    const { getSelectedIds } = await import('../components/multi-select-common');
    selectedEmployeesRows = $('.generate_documents').DataTable().rows('.selected').nodes().toArray();
    selectedEmployees = getSelectedIds();
    // Si la funcion getSelectedIds() no devuelve ningun valor, se llama a getSelectedEmployees() por tener la FF documentos_migracion_tabla_empleados inactiva
    if (selectedEmployees.length === 0) {
      selectedEmployees = getSelectedEmployees();
    }
    checkedDocumentVisible = $hiddenDocumentsGeneration?.prop('checked');
    selectedTemplateAmplitudeData = getSelectedTemplateAmplitudeData($documentTemplateSelect);

    e.preventDefault();
    if (isConfigurable(selectedTemplateId, configurableTemplates)) {
      subscribeConfirmClickButtonEvent('signed');
      subscribeGoBackClickButtonEvent('signed');
      filterTemplates();
      filterCompanies();
      workflowCheckboxLayout();
      showErrorEmptyLegalAgents();
      displayModal('employees_table_modal', false);
      displayModal('select_signers_modal', true);
      evaluatePeriodSelectors('signed');
      const documentSpecificationsElement = document.getElementById('document-specifications');
      if (documentSpecificationsElement) {
        doRequest('/document_signatures/signature_requirements', 'post',
          {
            id: selectedTemplateId,
            employee_ids: selectedEmployees,
          }).then((response) => {
          document.getElementById('document-specifications').innerHTML = response;
          showErrorEmptyLegalAgents();
        });
      }
    }
    else {
      doRequest('/document_signatures/multiple_open_periods', 'get', {
        employee_ids: selectedEmployees,
      }).then((response) => {
        if (response.ff_selector_de_periodos_documentos_mp_enabled && response.has_multiple_open_periods) {
          subscribeConfirmClickButtonEvent('normal');
          subscribeGoBackClickButtonEvent('normal');
          displayModal('employees_table_modal', false);
          displayModal('select_periods_modal', true);
          evaluatePeriodSelectors('normal');
        }
        else {
          const confirmButton = $("#normal_confirm_button");
          const payload = buildPayload('normal');
          doNormalRequest(confirmButton, payload);
        }
      });
    }
    //Amplitude document_create_button_clicked (Create_Document)
    $(e.target).trigger('amplitudeTracking', {
      message: 'document_create_button_clicked',
      data: {
        source: 'Documentos',
        document_batch: selectedEmployees.length,
        document_creator_role: 'Admin',
        document_template_type: selectedTemplateAmplitudeData['template_type'],
        document_visible: checkedDocumentVisible,
        document_reviewer: selectedTemplateAmplitudeData['template_reviewer'],
        signature_required: selectedTemplateAmplitudeData['template_need_signature'],
        signature_order: selectedTemplateAmplitudeData['template_signature_order'],
      },
    });
  });

  function doNormalRequest(confirmButton, payload) {
    delete payload.multiple_document.legal_agent_ids;
    doRequest(confirmButton.attr('url'), 'post', payload);
  }

  function subscribeConfirmClickButtonEvent(distinctionId) {
    const confirmButton = $(`#${distinctionId}_confirm_button`);
    confirmButton.on('click', function (e) {
      const payload = buildPayload(distinctionId);
      if (distinctionId === 'normal') { doNormalRequest(confirmButton, payload); }
      else if (showErrorSameLegalAgents() === true) { return; }
      else {
        e.preventDefault();
        confirmButton.prop('disabled', true);
        doRequest(confirmButton.attr('url'), 'post', payload);
      }

      const hasMultipleOpenPeriods = Object.keys(payload.multiple_document)
        .some(key => key.endsWith('document_period_start_date'));
      //Amplitude End_Create_Document
      $(e.target).trigger('amplitudeTracking', {
        message: 'End_Create_Document',
        data: {
          document_batch: selectedEmployees.length,
          document_creator_role: 'Admin',
          document_template_type: selectedTemplateAmplitudeData['template_type'],
          document_visible: checkedDocumentVisible,
          document_reviewer: selectedTemplateAmplitudeData['template_reviewer'],
          signature_required: selectedTemplateAmplitudeData['template_need_signature'],
          signature_order: selectedTemplateAmplitudeData['template_signature_order'],
          has_multiple_open_periods: hasMultipleOpenPeriods,
          automatic_signature_flow: $startWorkflow?.prop('checked'),
        },
      });
    });
  }

  function subscribeGoBackClickButtonEvent(distinctionId) {
    const goBackButton = $(`#${distinctionId}_back_button`);
    goBackButton.on('click', function (e) {
      e.preventDefault();
      resetSelections();
      displayModal('employees_table_modal', true);
      displayModal('select_signers_modal', false);
      displayModal('select_periods_modal', false);
    });
  }

  function evaluatePeriodSelectors(distinctionId) {
    const documentPeriodsElement = document.getElementById(`${distinctionId}_document_periods_selection`);
    if (documentPeriodsElement) {
      const baseStartDateContainerId = 'document_period_start_date_container';
      const periodsSelectors = document.querySelectorAll(`[id*="_${distinctionId}_${baseStartDateContainerId}"]`);
      const selectedPeriods = getSelectedPeriods(documentPeriodsElement);
      selectedPeriods.forEach(selectedPeriod => {
        const periodElement = Array.from(periodsSelectors).find(
          (element) => element.id === `${selectedPeriod}_${distinctionId}_${baseStartDateContainerId}`
        );
        if (periodElement) { periodElement.classList.remove('d-none'); }
      });
    }
  }

  function resetSelections() {
    $('div[type="company"]').removeClass('d-none');
    $('div[type="template"]').removeClass('d-none');
  }

  function getSelectedEmployees() {
    const employeeIds = selectedEmployeesRows.map(n => n.getElementsByTagName('input')[0].getAttribute('value'));
    return employeeIds;
  }

  function getSelectedCompanies() {
    const companyIds = selectedEmployeesRows.map(
      n => n.getElementsByTagName('input')[0].getAttribute('data-company-id'));
    return companyIds;
  }

  function getSelectedPeriods(documentPeriodsElement) {
    const tenantPeriodicity = documentPeriodsElement.dataset.tenantPeriodicity;
    if (tenantPeriodicity) {
      return [tenantPeriodicity];
    }
    const columnsLength = selectedEmployeesRows[0].getElementsByTagName('td').length;
    const periodsTranslations = JSON.parse(documentPeriodsElement.dataset.translation);
    const selectedPeriodsFromEmployees = selectedEmployeesRows.map(employeeRow => {
      const periodName = employeeRow.getElementsByTagName('td')[columnsLength - 1].textContent;
      return periodsTranslations[periodName];
    });
    const selectedPeriods = new Set(selectedPeriodsFromEmployees);
    return selectedPeriods;
  }

  function getLegalAgentIds() {
    const legalAgentDiv = $('div[type="template"]')
      .not('.d-none')
      .find('div[type="company"]')
      .not('.d-none')
      .find('#multiple_document_legal_agent, #multiple_document_legal_agent');
    const legalAgentIds = $('option:selected', legalAgentDiv)
      .toArray()
      .map((option) => option.value);
    return [...new Set(legalAgentIds)];
  }

  function filterCompanies() {
    var companyIds = getSelectedCompanies()
      .map((id) => '#' + id)
      .join(',');
    $('div[type="company"]')
      .not(companyIds)
      .addClass('d-none');
  }

  function filterTemplates() {
    const $templatesToFilter = $('div[type="template"]').not(
      `#${selectedTemplateId}`
    );
    $templatesToFilter.addClass('d-none');
  }

  function getSelectedTemplateId(select) {
    return parseInt($('option:selected', select)[0].value);
  }

  function getSelectedTemplateAmplitudeData(select) {
    return JSON.parse($('option:selected', select).attr('data_amplitude'));
  }

  function getconfigurableTemplates(select) {
    return JSON.parse(select.attr('data-configurable'));
  }

  function isConfigurable(templateId, configurableList) {
    return configurableList.includes(templateId);
  }

  function companiesWithoutSufficientLegalAgents(companiesIds) {
    const neededSignatures = parseInt(
      $('[type="template"]:not([class^="d-none"]):first').attr('data-needed-legal-agent-signatures')
    );
    return companiesIds.some(id => parseInt($(`[type="company"]#${id}`).attr('data-legal-agents')) < neededSignatures);
  }

  function cleanErrorLeganAgent() {
    $('#plural_empty_legal_agent_error').addClass('hidden');
    $('#single_empty_legal_agent_error').addClass('hidden');
    $('#same_legal_agents_assigned_error').addClass('hidden');
    $('#signed_confirm_button').removeClass('disabled');
  }

  function showErrorEmptyLegalAgents() {
    cleanErrorLeganAgent();
    const companiesIds = getSelectedCompanies();
    const missingLegalAgents = companiesWithoutSufficientLegalAgents(companiesIds);
    if (missingLegalAgents) {
      const errorId = companiesIds.length > 1 ? '#plural_empty_legal_agent_error' : '#single_empty_legal_agent_error';
      $(errorId).removeClass('hidden');
      $('#signed_confirm_button').addClass('disabled');
    }
  }

  function workflowCheckboxLayout() {
    const checkedHiddenDocuments = $hiddenDocumentsGeneration?.prop('checked');
    $startWorkflow.attr('disabled', !checkedHiddenDocuments);
    if(checkedHiddenDocuments === false) {
      $warningMessage.removeClass('hidden');
      $startWorkflow.prop('checked', false);
    }
    else {
      $warningMessage.addClass('hidden');
    }
  }

  function displayModal(modalName, show) {
    const element = document.getElementById(modalName);
    if (show === true) {
      element.classList.remove('d-none');
    }
    if (show === false) {
      element.classList.add('d-none');
    }
  }

  function buildPayload(distinctionId) {
    const selectedPeriodStartDates = documentPeriodStartDates(distinctionId);
    return ({
      multiple_document:
      { employee_ids: selectedEmployees,
        document_template_id: selectedTemplateId,
        legal_agent_ids: getLegalAgentIds(),
        start_workflow: $startWorkflow.prop('checked'),
        hidden_documents_generation: $hiddenDocumentsGeneration?.prop('checked'),
        ...selectedPeriodStartDates,
      },
    });
  }

  function documentPeriodStartDates(distinctionId) {
    const periodsInputs = $(`input[id*="${distinctionId}_document_period_start_date"]`);
    const selectedPeriodStartDates = Array.from(periodsInputs).reduce((acc, input) => (
      { ...acc, [input.id]: input.value }
    ), {});
    return selectedPeriodStartDates;
  }

  function doRequest(url, method, data) {
    return $.ajax({
      url: url,
      method: method,
      data: data,
    });
  }

  function showErrorSameLegalAgents() {
    const legalAgents = $('div[type="template"]')
      .not('.d-none')
      .find('div[type="company"]')
      .not('.d-none')
      .find('#multiple_document_legal_agent, #multiple_document_legal_agent');
    if (legalAgents.length > 1) {
      if (legalAgents[0].value === legalAgents[1].value) {
        $('#same_legal_agents_assigned_error').removeClass('hidden');
        return true;
      }
    }
    return false;
  }
});
