/** @file
 * funciones para el ajustar el estilo visual de dropdown de agregar/quitar columnas a la hora de hacer scroll
*/
import onmount from 'onmount';

const eventListenersMap = new Map();

const dropdownShadowClass = 'column-visibility__footer--shadow';

onmount('.column-visibility-dropdown', mountDropdown, unmountDropdown);

/**
 * Agrega un eventlistener al dropdown de agregar/quitar columnas para
 * modificar sus estilos en base a la posicion del scroll.
 * Esta funcion se ejecuta cuando el componente se renderiza en pantalla.
 */
function mountDropdown() {
  const tableId = $(this).closest('.fiji-table-container')?.id;
  const dropdownScroll = this.querySelector('.column-visibility__content');
  const dropdownFooter = this.querySelector('.column-visibility__footer');

  if(!dropdownScroll || !dropdownFooter) return;

  const scrollHandler = handleDropdownScroll(dropdownScroll, dropdownFooter);

  dropdownScroll.addEventListener('scroll', scrollHandler);

  eventListenersMap.set(tableId, scrollHandler);
}

/**
 * Remueve el eventlistener asociado al componente dropdown una vez
 * este deja de existir en el DOM
 */
function unmountDropdown() {
  const tableId = $(this).closest('.fiji-table-container')?.id;
  const dropdownScroll = this.querySelector('.column-visibility__content');

  const dropdownListener = eventListenersMap.get(tableId);

  if (dropdownScroll && dropdownListener) {
    dropdownScroll.removeEventListener('scroll', dropdownListener);

    eventListenersMap.delete(dropdownScroll);
  }
}

/**
 * Sincroniza las clases CSS con la posicion del scroll del dropdown
 * de agregar/quitar columnas
 */
function handleDropdownScroll(dropdownScroll, dropdownFooter) {
  return () => {
    if (dropdownScroll.scrollHeight - dropdownScroll.scrollTop === dropdownScroll.clientHeight) {
      dropdownFooter.classList.remove(dropdownShadowClass);
    }
    else {
      dropdownFooter.classList.add(dropdownShadowClass);
    }
  };
}
